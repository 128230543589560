import { notification } from 'antd';

export const openNotificationWithIcon = (type, message, duration = 4.5) => {
  /*
      type can be: 
      "success",
      "error",
      "info",
      "warning",
    */
  notification[type]({
    message,
    duration,
  });
};

export const cachHandler = (err) =>
  openNotificationWithIcon('error', err.message);
