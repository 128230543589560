import { API } from 'app/API/manager';
import tourPrevAPI from 'app/API/tour.prev.api';
import * as types from '../action_types';

export const prepateTourData = (id, share_key = '') => {
  return {
    type: types.TOUR_FULL_DATA,
    payload: API.way.tour
      .getOne('id', { id, share_key, scope: 'web' })
      .then((resp) => {
        const data = resp.data.response
          ? { ...resp.data.response, isShapingVisible: false }
          : null;
        return data;
      }),
  };
};

export const prepareTourGeoData = (id) => {
  return {
    type: types.FETCH_PREVIEW_POLYLINE,
    payload: API.way.tour
      .getOne('aproxPoly', { with_start: 1, id })
      .then((resp) => {
        return resp.data.response;
      }),
  };
};

export const tourPointDataAction = (data) => {
  return {
    type: types.TOUR_FULL_POINT_DATA,
    payload: data,
  };
};
export const tourFullDataId = (data) => {
  return {
    type: types.TOUR_FULL_DATA_ID,
    payload: data,
  };
};
export const resaveTour = () => {
  return {
    type: types.TOUR_FULL_RESAVE,
    payload: {},
  };
};
export const tourFullMapPosition = (data) => {
  return {
    type: types.TOUR_FULL_DATA_MAP_POSITION,
    payload: data,
  };
};
export const tourOpenedMultiPartAction = (data) => {
  return {
    type: types.TOUR_FULL_OPENED_MULTI_PART,
    payload: data,
  };
};
export const tourSetActiveMultiPartAction = (data) => {
  return {
    type: types.TOUR_FULL_SET_ACTIVE_MULTI_PART,
    payload: data,
  };
};
export const tourSetActiveMultiPartPointAction = (data) => {
  return {
    type: types.TOUR_FULL_ACTIVE_MULTI_PART_POINT,
    payload: data,
  };
};

export const setLatestSearchResult = (data) => {
  return {
    type: types.TOUR_FULL_DATA_LAST_SEARCH_RESULT,
    payload: data,
  };
};

export const setActiveDayUpdate = (newDayData) => {
  return {
    type: types.TOUR_FULL_INFO_UPDATE_DAY,
    payload: { newDayData },
  };
};

export const setMapBoundsStrAndZoom = (position) => {
  return {
    type: types.MAP_BOUNDS_STR_ZOOM,
    payload: position,
  };
};

export const setMapNextClick = () => {
  return {
    type: types.MAP_DETECT_CLICK_EVENT,
  };
};

export const setMapCoord = (data) => {
  return {
    type: types.MAP_DETECT_COORD,
    payload: data,
  };
};

export const setMapFilter = (data) => {
  return {
    type: types.MAP_SET_FILTER,
    payload: data,
  };
};

export const setCorrectionStatus = (isCorrectionEnabled) => {
  return {
    type: types.SET_CORRECTION_WRONG_ROUTE_STATUS,
    payload: isCorrectionEnabled,
  };
};
export const setAutoCorrectStatus = (isAutocorrectionEnabled) => {
  return {
    type: types.SET_AUTO_CORRECTION_TO_ROUTE_STATUS,
    payload: isAutocorrectionEnabled,
  };
};
export const setCorrectViaStatus = (isCorrectViaAvailable) => {
  return {
    type: types.SET_VIA_CORRECTION_STATUS,
    payload: isCorrectViaAvailable,
  };
};
export const setCorrectDragStatus = (isCorrectDragAvailable) => {
  return {
    type: types.SET_DRAG_CORRECTION_STATUS,
    payload: isCorrectDragAvailable,
  };
};
export const setOptimizeTourSettings = (payload) => {
  return {
    type: types.SET_OPTIMIZE_TOUR_SETTINGS,
    payload,
  };
};

export const setSelectPointId = (data) => {
  return {
    type: types.TOUR_FULL_SELECT_POINT,
    payload: data,
  };
};

export const optimizeAndSaveTour = (data) => {
  return {
    type: types.TOUR_FULL_OPTIMIZE_SAVE,
    payload: data,
  };
};

export const setGPSDataLoadingStatus = (data) => {
  return {
    type: types.TOUR_FULL_GPS_DATA_LODING_STATUS,
    payload: data,
  };
};

export const disableReloadCommonPageAction = () => {
  return {
    type: types.TOUR_FULL_DISABLE_RELOAD_COMMON_PAGE,
    payload: false,
  };
};

export const tourChangePartId = (parts, partId) => {
  return {
    type: types.TOUR_FULL_CHANGE_PART_ID,
    payload: { parts, part_id: partId },
  };
};

export const markJustImportedTour = (wasJustImported) => {
  return {
    type: types.TOUR_FULL_DATA_IMPORTED,
    payload: wasJustImported,
  };
};

export const routeSuggestion = (data) => {
  return {
    type: types.TOUR_FULL_ROUTE_SUGGESTION,
    payload: data,
  };
};

export const setPointType = (type) => ({
  type: types.TOUR_FULL_SET_POINT_TYPE,
  payload: type,
});

export const setActiveMode = (data) => {
  return {
    type: types.TOUR_FULL_ACTIVE_MODE,
    payload: data,
  };
};

export const setTourAmount = (isClear = false) => {
  return {
    type: types.USER_TOUR_AMOUNT,
    payload: tourPrevAPI.amount().then((resp) => {
      const data = {
        amount: resp.response,
        isClear,
      };
      return data;
    }),
  };
};

export const setTourOwner = (owner) => {
  return {
    type: types.TOUR_FULL_DATA_ADD_OWNER,
    payload: owner,
  };
};

export const setFreeHand = (data) => {
  return {
    type: types.TOUR_FULL_SET_FREE_HAND,
    payload: data,
  };
};

export const setShapingPoints = (data) => {
  return {
    type: types.TOUR_FULL_DATA_SWITCH_SHAPING_POINTS,
    payload: data,
  };
};

export const setPlaceVisibleData = (data) => {
  return {
    type: types.TOUR_FULL_DATA_SWITCH_PLACE_DATA,
    payload: data,
  };
};

export const setMultyTour = (data) => {
  return {
    type: types.TOUR_FULL_DATA_MAKE_MULTIDAYS_TOUR,
    payload: data,
  };
};

export const addNewDay = (day) => {
  return {
    type: types.TOUR_FULL_DATA_ADD_NEW_DAY,
    payload: day,
  };
};

export const setRequiredFiled = (data) => {
  return {
    type: types.PUBLIC_TOUR_TYPE_REQUIRED_FILED,
    payload: data,
  };
};

export const deleteTourDay = (key) => {
  return {
    type: types.TOUR_FULL_DATA_DELETE_DAY,
    payload: key,
  };
};

export const setFullScreenModeStatus = (fullScreen) => {
  return {
    type: types.SET_FULL_SCREEN_MODE_STATUS,
    payload: fullScreen,
  };
};

export const setMainPartTitle = (title) => {
  return {
    type: types.TOUR_FULL_UPDATE_MAIN_PART_TITLE,
    payload: title,
  };
};

export const setIsClearTour = (data) => {
  return {
    type: types.TOUR_FULL_IS_CLEAR_TOUR,
    payload: data,
  };
};

export const setTwisterData = (data) => {
  return {
    type: types.TOUR_FULL_SET_TWISTER_DATA,
    payload: data,
  };
};
