import * as types from '../action_types';

export const setTourFullData = (data) => {
  return {
    type: types.TOUR_FULL_DATA_FULFILLED,
    payload: data,
  };
};

export const cleanTourPart = (data) => {
  return {
    type: types.TOUR_CLEAR_TOUR_PART,
    payload: data,
  };
};

export const upTourPartByPos = (partPos, partPoints, isDataWithEmptyPoints) => {
  return {
    type: types.TOUR_FULL_DATA_UPDATE_DAY,
    payload: { partPos, partPoints, isDataWithEmptyPoints },
  };
};

export const upPartPoints = (partPos, partPointsData) => {
  return {
    type: types.TOUR_FULL_PART_POINTS_DATA_UPDATE,
    payload: { partPos, partPointsData },
  };
};

export const upTourPrevPartByPos = (prevPartPos, prevPartPoints) => {
  return {
    type: types.TOUR_FULL_DATA_SAVE_PREV_PART_POINT,
    payload: { prevPartPos, prevPartPoints },
  };
};

export const upTourPartByPosWithTimestamp = (data) => {
  return {
    type: types.TOUR_FULL_DATA_UPDATE_DAY_WITH_TIMESTAMP,
    payload: data,
  };
};

export const changeTourMode = (isEditMode) => {
  return {
    type: types.TOUR_FULL_IS_EDIT_MODE,
    payload: { isEditMode },
  };
};

export const setArrowTourType = (arrowType, timestamp) => {
  return {
    type: types.TOUR_FULL_SET_ARROW_TYPE,
    payload: { arrow_type: arrowType, timestamp },
  };
};

export const setAccessibilityTourType = (newTypes) => {
  return {
    type: types.TOUR_FULL_SET_ACCESSILITY_TYPE,
    payload: { newTypes },
  };
};

export const setPostCountedData = (data) => {
  return {
    type: types.TOUR_FULL_SET_POST_COUNTED_DATA,
    payload: data,
  };
};

export const setMainTourPartDistanceAndDuration = (data) => {
  return {
    type: types.TOUR_FULL_SET_MAIN_PART_DISTANCE_DURATION,
    payload: data,
  };
};

export const setDistanceDurationByPartId = (data) => {
  return {
    type: types.TOUR_FULL_SET_PART_DISTANCE_DURATION_BY_ID,
    payload: data,
  };
};
