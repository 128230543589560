import * as types from '../actions/action_types';
import userAPI from '../API/user.service.api';

export const optimizeSettingsMiddleware = (store) => (next) => (action) => {
  const { type, payload } = action;
  if (
    type === types.SET_CORRECTION_WRONG_ROUTE_STATUS ||
    type === types.SET_AUTO_CORRECTION_TO_ROUTE_STATUS ||
    type === types.SET_VIA_CORRECTION_STATUS ||
    type === types.SET_DRAG_CORRECTION_STATUS ||
    type === types.TOUR_FULL_DATA_SWITCH_SHAPING_POINTS ||
    type === types.TOUR_FULL_DATA_AVOID_UNPAVED ||
    type === types.TOUR_FULL_DATA_AVOID_HIGHWAY ||
    type === types.TOUR_FULL_DATA_AVOID_FERRY ||
    type === types.TOUR_FULL_DATA_SWITCH_TTO_VISIBLE
  ) {
    const { authentication = null } = store.getState();
    if (authentication) {
      const { user = {} } = authentication;
      if (user.id)
        userAPI.setHistorySelectedData(user.id, type, { status: +payload });
    }
  }
  next(action);
};
