/*eslint-disable*/
import {
  Icon,
  map,
  tileLayer,
  control,
  layerGroup,
  Polyline,
  LatLngBounds,
  latLng,
  path,
  LatLng,
  Marker,
  LayerGroup,
  PolylineDecorator,
  Symbol,
  divIcon
} from 'leaflet';

import { purge } from 'app/libs/Utils/leafLetUtils';
// import { Point } from 'app/libs/map/drive/Point';
import { Type } from 'app/constants/TourPoints';
import { updateContextMenuItems, addShapingMarkerListeners } from 'app/libs/Utils/leafLetUtils'; 
import { Pather } from 'app/libs/Utils/pather';
import {isMobileOnly} from 'react-device-detect';
import 'leaflet/dist/leaflet.css';
import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import 'leaflet.marker.slideto';
import 'leaflet-textpath';
import 'leaflet-touch-helper';
import { isOnLine } from '../Utils/enviropment';

import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';
// import 'mapbox-gl';
// import 'mapbox-gl/dist/mapbox-gl.css';
// import 'mapbox-gl-leaflet';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@maplibre/maplibre-gl-leaflet';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import aproximate from 'app/libs/Utils/aproximateDouglasPeucker';
// import pointDrag from "static/images/icons/drag.png";
import pointDrag from 'static/images/icons/shaping_point.png';
import { getMarker } from '../markers/getTourPoint';

async function initRouteServece() {
  const serv = await import('./routeService');
  return serv.RouteService;
}

async function initWrongRouteServece() {
  const serv = await import('./wrongRoute.service');
  return serv.WrongRouteService;
}

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconUrl: require('static/images/icons/start_point.svg'),
  shadowUrl: '',
  iconSize: [25, 34],
});

let __interface;

export function ConstructorSingletoneMap(params){   

    if(__interface)
        return __interface;
    if(this && this.constructor === ConstructorSingletoneMap){
                let __map, 
                    __relationPoints = {},
                    __layerFilterPoiGroup = null,
                    __layerPlaceGroup = null, 
                    __layerGroup = null, 
                    __relationPoly = {},
                    __temporaryPoly = {},
                    __polyGroup = null,
                    __listeners = [],
                    __singleMapPopUp = null,
                    __selectedPoint = null,
                    __layerExtraGroup = null,
                    __routeService = null,
                    __wrongRouteService = null,
                    __endPoint = null,
                    __movingMapDelay = null,
                    __prevPolylayerGroup = null,
                    __alternativePolylayerGroup = null,
                    __filtePolyLayerGroup = null,
                    __filterPointlayerGroup = null,
                    __filterPoints = {},
                    __partPointsData = {},
                    __shappedRoads = {},
                    __pather = null

                const initMap = params => {
                    const { mapContainer= 'map', loc:{lat= 50.56011, lng= 15.05722}, zoom= 4, contextMenu } = params;
                    let config = { 
                        zoomControl: false,
                        center: [lat, lng],
                        minZoom: 2,
                        zoom: zoom,
                        layers: [
                            tileLayer('https://tiles.tourstart.org/{z}/{x}/{y}.png', {
                            // tileLayer('http://vectormap.tourstart.org/styles/basic-preview/{z}/{x}/{y}.png', {
                            // tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
                                attribution: 'Tourstart'
                            }),
                        ],
                        fullscreenControl: true,
                    }
                    if( contextMenu && contextMenu.contextmenu )
                        config = { ...config, ...contextMenu }
                    
                    if( !__routeService )
                        initRouteServece().then( RouteService => __routeService = new RouteService() );

                    if( !__wrongRouteService )
                    initWrongRouteServece().then( WrongRouteServece => __wrongRouteService = new WrongRouteServece() );
                    
                    // const map = L.map(mapContainer, config);

                    // const gl = L.maplibreGL({
                    //     container: mapContainer,
                    //     style: `http://185.150.196.78:63080/styles/osm-bright-gl-style/style.json`,
                    //     center: [lat, lng],
                    //     zoom: zoom,
                    //     minZoom: 2,
                    // }).addTo(map);

                    // // new maplibregl.Map({
                    //     // container: mapRef.current,
                    //     // style: `http://185.150.196.78:63080/styles/osm-bright-gl-style/style.json`,
                    //     // center: [lat, lng],
                    //     // zoom: zoom,
                    //     // minZoom: 2,
                    // // })

                    // return map;
                    return map(mapContainer, config);
                }

                const setMapSettings = () => {
                    const bounds = new LatLngBounds(latLng(-90, -200), latLng(90, 200));
                    __map.setMaxBounds(bounds);

                    new control.zoom({position:'bottomright'}).addTo(__map);
                }

                const getPolyLayerGroup = () => {
                    return __polyGroup || (__polyGroup = new layerGroup());
                }

                const getLayerGroup = () => {
                    return __layerGroup || (__layerGroup = new layerGroup());
                }

                const getFilterPoiLayerGroup = () => {
                    return __layerFilterPoiGroup || (__layerFilterPoiGroup = new layerGroup());
                }

                const getPlaceLayerGroup = () => {
                    return __layerPlaceGroup || (__layerPlaceGroup = new layerGroup());
                }

                const getExtraLayerGroup = () => {
                    return __layerExtraGroup || (__layerExtraGroup = new layerGroup());
                }

                const getPrevPolyLayerGroup = () => {
                    return __prevPolylayerGroup || (__prevPolylayerGroup = new layerGroup());
                }

                const getAlternativePolyLayerGroup = () => {
                    return __alternativePolylayerGroup || (__alternativePolylayerGroup = new layerGroup());
                }

                const getFiltePolyLayerGroup = () => {
                    return __filtePolyLayerGroup || (__filtePolyLayerGroup = new layerGroup());
                }
                const getFilterPointLayerGroup = () => {
                    return __filterPointlayerGroup || (__filterPointlayerGroup = new layerGroup());
                }
                const __getMarkerById = id => getLayerGroup().getLayer(__relationPoints[id]);

                const __unSelectPrevMarker = () => {
                    const prevMarker = __getMarkerById(__selectedPoint.options.customId);
                    if( prevMarker ){
                        let hisIcon = prevMarker.getIcon();
                        hisIcon.options.className = hisIcon.options.className.replace(' hover', '');
                        prevMarker.setIcon(hisIcon);
                    }
                }

                const __getSelectedMarkerById = customId => {
                    let marker = null;
                    if(!__selectedPoint || __selectedPoint.options.customId !== customId){
                        marker = __getMarkerById(customId);
                        // if( marker && marker.options.type !== Type.DRAG ){
                        if( marker ){
                            let icon = marker.getIcon();
                            icon.options.className = icon.options.className+' hover';
                            marker.setIcon(icon);
                        }
                    }                    
                    return marker;
                }

                const __makeLightPoly = key => {
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        poly.setStyle({
                            opacity: 0.6
                        });
                    }
                }

                const __makeFullPoly = key => {
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        poly.setStyle({
                            opacity: 1
                        });
                    }
                }

                const __makePolyWider = key => {
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        poly.setStyle({
                            weight:8,
                            color: '#aa2493'
                            // color: '#580c9a'
                        });
                    }
                }

                const __revertPolyStyle = key => {
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        poly.setStyle( getPolyStyle( poly.getLatLngs() ) );
                    }
                }

                const __removeTemporaryPolys = () => {
                    getAlternativePolyLayerGroup().clearLayers();
                    for( let key in __temporaryPoly )
                        __removeTemporaryPoly( key );
                }

                const __removeTemporaryPoly = key => {
                    if( __temporaryPoly[ key ] ){
                        getPolyLayerGroup().removeLayer( __temporaryPoly[ key ] );
                        delete __temporaryPoly[ key ];
                    }
                }

                const __removePrevPolylines = key => {
                    if( key !== 'poly'){
                        __dropPolyByKey( key );
                        __dropPolyByKey( 'poly' );
                    }else{
                        if( __relationPoly[ 'poly' ] ){
                            __dropPolyByKey( 'poly' );
                        }else{
                            for( let key in __relationPoly ){
                                if( typeof key.indexOf( 'poly' ) !== -1 )
                                    __dropPolyByKey( key );
                            }
                        }
                    }   
                }

                const __dropPolyByKey = key => {
                    const pointId = key.split('_')[1];
                    if(pointId)
                        __removeUnpavedPolys(pointId);
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        if( poly )
                            poly.off();
                        getPolyLayerGroup().removeLayer( __relationPoly[ key ] );
                        delete __relationPoly[ key ];
                    }
                }



                /* Start developing extra Open Clear Rule for creating tou on the map */

                /* End developing extra Open Clear Rule for creating tou on the map */


                const __getUnpavedPolyStyle = latLngs => {
                    return {
                        // opacity: this.getMap().getZoom() > 10 ? 0.6 : 1,
                        opacity: 0.9,
                        weight:6,
                        color: '#ff0000',
                    }
                }

                const __getAlternativePolyStyle = latLngs => {
                    return {
                        //opacity: 0.6,
                        opacity: 0.9,
                        weight: 5,
                        color: '#4397d3',
                    }
                }

                const getPolyStyle = latLngs => {
                    const dashed = {
                        color:'#662D91',
                        weight:2,
                        opacity: 0.9,
                        dashArray: "5,5"
                    }
                    const solid = 
                    {
                        // opacity: this.getMap().getZoom() > 10 ? 0.6 : 1,
                        opacity: 0.9,
                        weight:6,
                        color: '#652C95'
                    }
                    return (latLngs && latLngs[0] === 'dashed') ? dashed : solid;
                }

                const getSolidPolyStyle = () => {
                    return {
                        // opacity: this.getMap().getZoom() > 10 ? 0.6 : 1,
                        opacity: 0.9,
                        weight:6,
                        color: '#652C95'
                    }
                }

               const  __setPolyDirect = (poly) => {

                poly.setText('               ►               ', {
                    repeat: true, 
                    center: true, 
                    attributes: {
                        'fill': 'white', 
                        'fill-opacity': '0.60', 
                        'dy': 4, 
                        'fill-rule': 'evenodd', 
                        'pointer-events': 'none',
                        'length-adjust': "spacingAndGlyphs",
                        'text-length': "200%"
                    }
                });
                
                return poly;
               }

                const __getPolyline = ( latLngs, partId ) => {
                    const style = {...getPolyStyle( latLngs ), partId};
                    if(latLngs && latLngs[0] === 'dashed')
                        latLngs.shift();
                        return new Polyline( latLngs, style);
                }

                const __getAlternativePoly = ( latLngs, customId ) => {
                    const style = {...__getAlternativePolyStyle( latLngs ), customId};
                    return new Polyline( latLngs, style );
                }

                const __getUnpavedPoly = ( latLngs, pointId ) => {
                    const style = {...__getUnpavedPolyStyle( latLngs ), pointId};

                    return new Polyline( latLngs, style);
                } 

                const __getFilterPolyline = (latLngs, partId) => {
                    const style = {...getSolidPolyStyle()};
                    return new Polyline( latLngs, style);
                }
                
                const __addPolyHendlers = ( polyline, pointId ) => {
                    if( !pointId){
                        console.error(`Point id is absent - ${pointId}`);
                        return polyline;
                    }
                    let __timeoutDragMarker = false;
                    polyline.on('mouseover', resp => {
                        if(__timeoutDragMarker) {
                            clearTimeout(__timeoutDragMarker);
                            __timeoutDragMarker = false;
                        }
                        __routeService.showDragMarker( pointId, resp.latlng, polyline.getLatLngs(), __map );
                    }).on('mouseout', resp => {
                        __timeoutDragMarker = setTimeout(()=>{
                            __routeService.hideDragMarker();
                        }, 500);
                    }).on('mousemove', resp => {
                        if(__timeoutDragMarker) {
                            clearTimeout(__timeoutDragMarker);
                            __timeoutDragMarker = false;
                        }
                        __routeService.moveDragMarker( resp.latlng, polyline.getLatLngs(), __map );
                    });

                    return __setPolyDirect(polyline);
                }
                const __addPolyPartsHendlers = ( polyline, partId ) => {
                    if( !partId){
                        console.error(`Part is absent - ${partId}`);
                        return polyline;
                    }
                    polyline
                    .on( 'click', __routeService.commonPageStaticClickHandler )
                    .on('mouseover', __routeService.commonPageStaticOverHandler)
                    .on('mouseout', __routeService.commonPageStaticOutHandler);

                    return __setPolyDirect(polyline);
                }
                
                if(!__map){
                    __map = initMap(params);
                    setMapSettings();
                }

                // if(!__map){
                //     __map = L.map('map').setView([56.046842, 9.537821], 10);
                //     setMapSettings();
                //     var gl = L.mapboxGL({
                //         accessToken: 'pk.eyJ1Ijoia3VzaG5pcmR2IiwiYSI6ImNrbHMxbXR5ODFzMGsydm4zb2Z6ODk2bXgifQ.CbofqAzJ0kENrMAmOIbHhQ',
                //         style: 'http://vectormap.tourstart.org/styles/basic-preview/style.json'
                //     }).addTo(__map);
                //     __map.on('styledata', ()=>{
                //         gl.getMapboxMap().addSource('basic-preview', {
                //             type: 'vector',
                //             tiles: ["http://vectormap.tourstart.org/data/v3/{z}/{x}/{y}.pbf"],
                //             maxZoom: 16
                //             }
                //         );
                //     });
                // }
 

                this.getMap = () => __map;

                this.disableMapInteraction = () => {
                    __map.dragging.disable();
                    __map.touchZoom.disable();
                    __map.doubleClickZoom.disable();
                    __map.scrollWheelZoom.disable();
                    __map.boxZoom.disable();
                    __map.keyboard.disable();
                    if (__map.tap) __map.tap.disable();
                }

                this.enableMapInteraction = () => {
                    __map.dragging.enable();
                    __map.touchZoom.enable();
                    __map.doubleClickZoom.enable();
                    __map.scrollWheelZoom.enable();
                    __map.boxZoom.enable();
                    __map.keyboard.enable();
                    if (__map.tap) __map.tap.enable();
                }

                this.partPolySelect = ({ partId }) => {
                    for ( let key in __relationPoly){
                        __revertPolyStyle( key );
                        if( partId && key === `poly_${partId}` )
                                __makePolyWider( key );
                    }
                }

                const __getCorrectPos = (pointId, pointMargeArr, keppCurrentCoord) => {
                    if(typeof pointMargeArr === 'string'){
                        publish(way.MAP_EXCHANGE_WITH_NEXT_POINT, {pointId, direction: pointMargeArr, isFixed: true});
                    }else if(pointMargeArr.length){
                        if(pointMargeArr[0] === 'autocorrection'){
                            const {lat:correctLat, lng:correctLng, dist:wrongDist} =  pointMargeArr[1];
                            publish(way.MAP_AUTOCORRECT_POINT_COORD, {id:pointId, correctLat, correctLng, wrongDist, keppCurrentCoord});
                        }else{
                            __fixPointPosition(pointId, pointMargeArr, keppCurrentCoord);
                        }
                    }else{
                        return false;
                    }
                    return true;
                }

                const __getPolyByPointId = id => {
                    if(!id)
                        return null;
                    const leafLetId = __relationPoly['poly_'+id];
                    if(leafLetId){
                        const layer = getPolyLayerGroup().getLayer( leafLetId );
                        return layer;
                    }else{
                        return null;
                    }
                }

                this.correctTourPointsPosition = ({
                    prevId,
                    pointId,
                    nextId,
                    isLoading=false,
                    keppCurrentCoord,
                    isFixed=false,
                    isWithoutRevers=false,
                }) => {
                    if(!pointId)
                        return;
                    let isDetectCorrectPos = false;
                    if(!isLoading && !isFixed){
                        const pointIdPoly = __getPolyByPointId(pointId);
                        const currentPoly = pointIdPoly ? pointIdPoly.getLatLngs() : [];
                        const polyObj = __wrongRouteService.getPolyObj(currentPoly);

                        const prevMargeArr = __wrongRouteService.getWrongRoute({
                            compareObj: {...polyObj}, 
                            pointPoly: __getPolyByPointId(prevId), 
                            isWithoutRevers,
                            startCurrentPolyCoord: currentPoly[0],
                        });
                        isDetectCorrectPos = __getCorrectPos(pointId, prevMargeArr, keppCurrentCoord);

                        if(!isDetectCorrectPos){
                            const nextMargeArr = __wrongRouteService.getWrongRoute({
                                compareObj: {...polyObj}, 
                                pointPoly: __getPolyByPointId(nextId), 
                                isWithoutRevers, 
                                isNext: true,
                                startCurrentPolyCoord: currentPoly[0],
                            });
                            isDetectCorrectPos = __getCorrectPos(pointId, nextMargeArr, keppCurrentCoord);
                        }

                        if(!isDetectCorrectPos){
                            const point = { id: pointId, isRemoveCorrectCoord: true, isFixed: true };
                            // this.updateWrongRouteMarkerValue(pointId, false);
                            publish(way.MAP_SET_CORREC_POINT_COORD, point);
                        }
                    }
                    
                }

                this.updatePartPointData = ( { pointId, unpavedParts, shapingPointsList, avoid, isShapingVisible } ) => { 
                    __removeUnpavedPolys(pointId);
                    __showUnpavedPolys(unpavedParts, pointId, avoid);

                    var icon = new Icon({
                        iconUrl: pointDrag,
                        iconSize: [12, 12],
                        iconAnchor: [6,6]
                    });
                    if(shapingPointsList.length > 0 && isShapingVisible){
                        shapingPointsList.forEach( loc => {
                                const point = addShapingMarkerListeners(
                                    new Marker(loc, {
                                        icon,
                                        iconAnchor: [5,5],
                                        title: 'Shaping point',
                                    }),
                                    params.rightMenusText,
                                    pointId
                                );
                                getPolyLayerGroup().addLayer( point ).addTo( __map );
                                if(!__partPointsData[pointId])
                                    __partPointsData[pointId] = [];
                                __partPointsData[ pointId ].push(getPolyLayerGroup().getLayerId( point ));
                        });
                    }
                }
                this.changeTourPoly = ( { latLngs, isEdit=true, pointId, partId, detectedPoints=[], prevId, nextId, isFixed, isLoading, keppCurrentCoord, isWithoutRevers } ) => {
                    __removeTemporaryPolys();
                    this.hideTourPath();

                    const len = latLngs.length;
                    if(!pointId && !partId && !len) {
                        return;
                    }

                    let key = pointId ? 'poly_'+pointId : 'poly';
                    let polyline;
                    if( partId ){
                        key = partId ? 'poly_'+partId : 'poly';
                        __removePrevPolylines( key );
                        if( !len ) 
                            return;
                        polyline = __getPolyline( latLngs, partId );
                        
                        polyline = __addPolyPartsHendlers( polyline, partId );
                    }else{
                        __removePrevPolylines( key );
                        
                        if( !len ) return;
                        polyline = __getPolyline( latLngs );
                        
                        if( isEdit && len > 2 ){
                            polyline = __addPolyHendlers( polyline, pointId );
                        } else {
                            polyline = __setPolyDirect(polyline);
                        }
                    }
                    if(__relationPoints[pointId] || partId || key === 'poly'){
                        getPolyLayerGroup().addLayer( polyline ).addTo( __map );
                        __relationPoly[ key ] = getPolyLayerGroup().getLayerId( polyline );
                        path.touchHelper( polyline, {extraWeight:10} ).addTo( __map );
                    }else{
                        console.log('Point is not exist', pointId, __relationPoints);
                    }
                }

                this.addFilterPoint = (point) => {
                    getFilterPointLayerGroup().addLayer( point ).addTo( __map );
                    __filterPoints[point.options.customId] = getFilterPointLayerGroup().getLayerId(point);
                }

                this.removeFilterPoint = (customId) => {
                    getFilterPointLayerGroup().removeLayer(__filterPoints[customId]);
                    delete __filterPoints[customId];
                }

                this.clearFilterPoints = () => {
                    if(__filterPointlayerGroup){
                        __filterPointlayerGroup.clearLayers();
                        __filterPoints = {};
                    }
                }

                this.showFilterRoute = coords => {
                    const alternativePoly = __getFilterPolyline(coords);
                    getFiltePolyLayerGroup().addLayer( alternativePoly ).addTo( __map );
                }

                this.hideFilterMainRoute = () => {
                    if(__filtePolyLayerGroup){
                        __filtePolyLayerGroup.clearLayers();
                    }
                }
                this.hideFilterRoute = () => {
                    if(__alternativePolylayerGroup)
                    __alternativePolylayerGroup.clearLayers();
                }
                
                this.showFilterAlternativeRoutes = ({list, create=false}) => {
                    list.forEach(item => {
                        const customId = item.id;
                        const hendler = create ? __addPolyCreateHendlers : __addPolyFilterHendlers;
                        const alternativePoly = hendler(__getAlternativePoly(item.geometry, customId));
                        getAlternativePolyLayerGroup().addLayer( alternativePoly ).addTo( __map );
                    });
                }

            const __addPolyFilterHendlers = polyline => {
                polyline
                .on('click', () => false)
                .on('mouseover', __routeService.commomPageStaticFilterPolyOver)
                .on('mouseout',__routeService.commomPageStaticFilterPolyOut)
                return polyline;
            }

            const __addPolyCreateHendlers = polyline => {
                polyline
                .on('click', () => false)
                .on('mouseover', __routeService.commomPageStaticCreatePolyOver)
                .on('mouseout',__routeService.commomPageStaticCreatePolyOut)
                return polyline;
            }

            const showAlternativeRoutes = list => {
                list.forEach(coords => {
                    const alternativePoly = __getAlternativePoly(coords);
                    getAlternativePolyLayerGroup().addLayer( alternativePoly ).addTo( __map );
                });
            }

            const showDetectedPoints = list => {
                list.forEach(pt => {
                    const point = getMarker( {customId: pt.customId, point: pt, isLastVia: false}, 'drive', {});
                    getAlternativePolyLayerGroup().addLayer( point ).addTo( __map );
                    __relationPoints[point.options.customId] = getAlternativePolyLayerGroup().getLayerId(point);
                });
            }

            const __showUnpavedPolys = (unpavedParts, pointId, avoid) =>{
                if(unpavedParts && unpavedParts.length && avoid.unpaved && pointId !== 'staticId'){
                    unpavedParts.forEach( latLngs => {
                        const polyline = __getUnpavedPoly( latLngs, pointId );
                        getPolyLayerGroup().addLayer( polyline ).addTo( __map );
                        if(!__partPointsData[pointId])
                            __partPointsData[pointId] = [];
                        __partPointsData[ pointId ].push(getPolyLayerGroup().getLayerId( polyline ));
                    });
                }
            }

            const __removeUnpavedPolys = pointId => {
                // console.log('__removeUnpavedPolys', __partPointsData);
                if(__partPointsData[pointId] && __partPointsData[pointId].length > 0){
                    __partPointsData[pointId].forEach( layer => {
                        if(layer)
                            getPolyLayerGroup().removeLayer( layer );
                    });
                    __partPointsData[pointId] = [];
                }
            }

            const __fixPointPosition = (id, arr, keppCurrentCoord=0) => {
                const {lat, lng, dist:wrongDist} = arr[0] || {};
                if(typeof lat === 'number' && typeof lng === 'number'){
                    if(params.isCorrectionEnabled && !keppCurrentCoord){
                        this.updateWrongRouteMarkerValue(id, true);
                    }
                    const point = {id, correctLat:lat, correctLng:lng, keppCurrentCoord, isFixed: true, wrongDist}
                    publish(way.MAP_SET_CORREC_POINT_COORD, point);

                    return true;
                }
                return false;
            }
                    
                

                this.addTemporaryPoly = ( { latLngs, isEdit=true, pointId, unpavedParts, avoid } ) => {
                    const key = pointId ? 'poly_'+pointId : 'poly';
                    const tmpPolyKey = pointId;
                    __makeLightPoly( key );
                    __removeTemporaryPoly( tmpPolyKey );
                    if( !latLngs.length ) 
                        return;                    
                    let polyline = __getPolyline( latLngs );
                    polyline = __setPolyDirect(polyline);
                    getPolyLayerGroup().addLayer( polyline ).addTo( __map );
                    __temporaryPoly[ tmpPolyKey ] = getPolyLayerGroup().getLayerId( polyline );

                    __removeUnpavedPolys(pointId);
                    __showUnpavedPolys(unpavedParts, pointId, avoid); 
                }

                this.setLitePolylines = isForse => {
                    if(this.getMap().getZoom() < 13 || isForse)
                        Object.keys(__relationPoly).forEach(__makeLightPoly);
                }
                this.setFullPolylines = isForse => {
                    if(this.getMap().getZoom() > 8 || isForse)
                        Object.keys(__relationPoly).forEach(__makeFullPoly);
                }

                this.updatePolyListeners = ( { isEdit=true, pointId } ) => {
                    const key = pointId ? 'poly_'+pointId : 'poly';
                    if( __relationPoly[ key ] ){
                        let poly = getPolyLayerGroup().getLayer( __relationPoly[ key ] );
                        if( poly ){
                            poly.off();
                            if( isEdit ){
                                poly = __addPolyHendlers( poly, pointId );
                            }
                        }
                    }
                }

                this.addMapListener = (listenerName, callback) => {
                    if(__listeners.indexOf(listenerName) === -1)
                            __listeners.push(listenerName);
                    __map.on(listenerName, callback);
                };
                this.removeMapListener = (listenerName) => {
                    __map.off(listenerName);
                };
                this.updateWrongRouteMarkerValue = (id, isEnable) => {
                    const pointMarker = __getMarkerById(id);
                    if(pointMarker){
                        let hisIcon = pointMarker.getIcon();
                        if(isEnable){
                            hisIcon.options.className += ' wrong-route-symbol';
                        }else{
                            hisIcon.options.className = hisIcon.options.className.replace(' wrong-route-symbol', '');
                        }
                        pointMarker.setIcon(hisIcon);
                    }
                }
                this.removeAllMapListeners = ()=>{
                    __listeners.map( n =>__map.off(n) );
                }

                const isEmptyMap = () => Object.keys(__relationPoints).length === 0;

                this.updateHomeAddress = ( point, isRemove=false ) => { 
                    if(__getMarkerById(point.options.customId)){
                        this.removeLayerById(point.options);
                    }
                    if(!isRemove){
                        this.addPoint(point, {isMoveMapToPoint: false});
                        point.setZIndexOffset(100);
                    }
                }

                this.addPlacePoi = (place) => {
                    if(!isOnLine()){
                        return;
                    }
                    getPlaceLayerGroup().addLayer( place ).addTo( __map );
                }

                this.clearPlacePoi = () => {
                    getPlaceLayerGroup().clearLayers();
                }

                this.addFilterPoi = ( poi ) => {
                    if(!isOnLine()){
                        return;
                    }
                    getFilterPoiLayerGroup().addLayer( poi ).addTo( __map );

                }
                this.clearFiltersPoi = () => {
                    getFilterPoiLayerGroup().clearLayers();
                }
                this.addPoint = ( point, obj={} ) => {
                    const {isMoveMapToPoint = true, roadName} = obj;
                    if(!isOnLine()){
                        return;
                    }
                    // if(roadName){
                    //     if(__shappedRoads[roadName]){
                    //         // remove a corresponding shaping point
                    //     }
                    //     __shappedRoads[roadName] = point.options.customId;
                    // }
                    getLayerGroup().addLayer( point ).addTo( __map );
                    __relationPoints[point.options.customId] = getLayerGroup().getLayerId(point);
                    if(isMoveMapToPoint)
                        this.showMapOutPoint( __getMarkerById( point.options.customId ) );
                }
                
                this.createPoint = ( { pt:point, isLast, roadName='' } ) => {
                    if(!isOnLine()){
                        return;
                    }
                    // if(roadName){
                    //     if(__shappedRoads[roadName]){
                    //         // remove a corresponding shaping point
                    //     }
                    //     __shappedRoads[roadName] = point.options.customId;
                    // }

                    __temporaryPoly = {} //fixed bug with drag poly after 50 points
                    getLayerGroup().addLayer( point ).addTo( __map );
                    __relationPoints[point.options.customId] = getLayerGroup().getLayerId(point);
                    if( isLast ){
                        // if(mapZoom)
                        //     this.setZoom({zoom: mapZoom});
                        this.centering( true, point.options.draggable );
                    }
                    // else if(correctLat && correctLng){
                    //     this.updateWrongRouteMarkerValue(point.options.customId, true);
                    // }
                }

                this.selectPoint = ( { customId } ) => {
                    if(!isOnLine()){
                        console.log('Need to load active points icon for ofline eviropment');
                        return;
                    }
                    if( customId === undefined )
                        return;
                    if(__selectedPoint){
                        __unSelectPrevMarker();
                    }
                    __selectedPoint = __getSelectedMarkerById(customId);
                    if(__movingMapDelay)
                        clearTimeout(__movingMapDelay);
                    __movingMapDelay = setTimeout(()=>{
                        this.showMapOutPoint( __selectedPoint );
                    }, 500);
                }

                this.changePointOffSet = ({customId}) => {
                    if(customId === undefined)
                        return;
                    if(customId !== 0)
                        __endPoint = customId; 
                    const id = customId === 0 ? __endPoint : customId;
                    const zIndex = customId === 0 ? -1000 : 1000;
                    let marker = getLayerGroup().getLayer(__relationPoints[id]);
                    if(marker)
                        marker.setZIndexOffset(zIndex);
                }

                this.updatePoint = ({customId, point}, newIcon) => {
                    if(!isOnLine()){
                        return;
                    }

                    let marker = getLayerGroup().getLayer(__relationPoints[customId]);
                    if( marker ){
                        marker.setIcon(newIcon);
                        const {lat, lng, isAutocorrection, type, roadName} = point;
                        if( type && marker.options.type !== type ){
                            marker.options.type = type;
                            if( marker.options && marker.options.draggable ){
                                marker = updateContextMenuItems( marker, type, params.rightMenusText );
                            }
                        }
                        if(lat && lng){
                            marker.slideTo(	[lat, lng], {
                                duration: 500,
                            });
                            marker.setLatLng(new LatLng(lat, lng));
                            if(roadName){
                                if(__shappedRoads[roadName]){
                                    // remove a corresponding shaping point
                                }
                                __shappedRoads[roadName] = customId;
                            }
                        }
                    }
                }

                this.setEditablePermition = ( isAllow, initMarkerListener ) => {
                    getLayerGroup().eachLayer( layer => {
                        if(layer.dragging){
                            if(isAllow)
                                layer.dragging.enable();
                            else{
                                layer.dragging.disable();
                            }
                            layer = initMarkerListener(layer);
                        }
                    });
                }

                this.flyTo = ({latLng, zoom}) => {
                    __map.setView( latLng, zoom );
                    // __map.flyTo( latLng, zoom );
                }

                this.fitBounds = bounds => {
                    __map.fitBounds(bounds);
                };

                this.flyToNextWrongPosition = ({latLng}) => {
                    const zoom = __map.getZoom();
                    __map.setView( latLng, zoom );
                    // const {lat, lng} = latLng;
                    // __map.flyTo( [lat, lng], zoom );
                }

                this.setView = (obj) => {
                    __map.setView(obj);
                }

                this.setZoom = ({zoom}) => {
                    console.log("HERE WE GO",zoom);
                    let loc = __map.getCenter();
                    getLayerGroup().eachLayer( layer => {
                        if(layer.options.type === Type.START){
                            loc = layer.getLatLng();
                        }
                    });
                    __map.setView( loc , zoom );
                }
                
                this.showMapOutPoint = point => {
                    if( typeof point === 'number'){
                        point = __getSelectedMarkerById( point );
                    }
                    if( point && !__map.getBounds().contains( point.getLatLng() ) ){
                        __map.panTo( point.getLatLng() );
                        // if( __map.getZoom() > 10 ){
                        //     __map.panTo( point.getLatLng() );
                        // }else{
                        //     __map.flyTo( point.getLatLng() );
                        // }
                    }
                }

                this.removeLayerById = ({customId}) => {
                    if(!isOnLine()){
                        return;
                    }
                    getLayerGroup().removeLayer(__relationPoints[customId]);
                    delete __relationPoints[customId];

                    __removeUnpavedPolys(customId);
                    const roadName = getKeyByValue(__shappedRoads, customId);
                    delete __shappedRoads[roadName];
                }

                const getKeyByValue = (object, value) => Object.keys(object).find(key => object[key] === value);

                this.clearCurrentLayer = () => {
                    if(!isOnLine()){
                        return;
                    }
                    getLayerGroup().clearLayers();
                    __relationPoints = {};

                    getExtraLayerGroup().clearLayers();
                    getFilterPoiLayerGroup().clearLayers();
                }

                this.clearPolyLayers = () => {
                    getPolyLayerGroup().clearLayers();
                    __partPointsData = [];
                    __relationPoly = {};
                }
                
                this.destroy = () => {
                    this.removeAllMapListeners();
                    this.clearCurrentLayer();
                    this.clearPolyLayers();
                    purge(__map);
                    // __map.off();
                    // __map.remove();
                    // __map = null;
                }

                this.setContextMenuDisabled = ( index, value ) => {
                    __map.contextmenu.setDisabled( index, value);
                }

                this.centering = (isForse, isEditMode=false, isOnlyCentering = false) => {
                    if(isEditMode)
                        return;

                    let bounds = new LatLngBounds();
                    let isPointsInsideScreen = true;
                    getLayerGroup().eachLayer( layer => {
                        const isServiceIcon = layer && layer.options && (layer.options.customId === Type.WORK || layer.options.customId === Type.HOME);
                        if (layer && layer._latlng && !isServiceIcon){
                            bounds.extend(layer._latlng);
                            if( !this.getMap().getBounds().contains(layer._latlng) || isForse ){
                                isPointsInsideScreen = false;
                            }
                        }
                    });

                    if( bounds.isValid() && !isPointsInsideScreen ){
                        const maxZoom = isOnlyCentering ? this.getMap().getZoom() : this.getMap().getBoundsZoom(bounds);
                        if(isMobileOnly || window.innerWidth < 768){
                            this.getMap().fitBounds(bounds, {paddingTopLeft: [20,0],paddingBottomRight:[0,200], maxZoom}); //offset map center mobile
                        }else{
                            this.getMap().fitBounds(bounds, {paddingTopLeft: [420,90],paddingBottomRight:[20,20], maxZoom}); //offset map center
                        }
                    }
                }

                this.showPopUp = popUp => {
                    // this.hidePopUp();
                    __singleMapPopUp = popUp;
                    __singleMapPopUp.addTo(__map);
                }

                this.hidePopUp = event => {
                    if( __singleMapPopUp )
                        __singleMapPopUp.off().remove();
                }

                this.disableFullScreenMode = () => {
                    if( this.getMap()._isFullscreen )
                        this.getMap().fullscreenControl.toggleFullScreen();
                }
                this.toggleFullScreenMode = () => {
                        this.getMap().fullscreenControl.toggleFullScreen();
                }

                this.showExtraTourData = (list=[]) => {
                    getExtraLayerGroup().clearLayers();
                    if(list.length > 0)
                        list.forEach(layer => {
                            getExtraLayerGroup().addLayer( layer ).addTo( __map );
                        });
                }

                this.showTourPath = layers => {
                    if(layers && layers.length > 0){
                        layers.forEach(layer=>{
                            getPrevPolyLayerGroup().addLayer( layer ).addTo( __map );

                        });
                    }
                }
                this.hideTourPath = () => {
                    getPrevPolyLayerGroup().clearLayers();
                }


                this.showFreeHandLayer = () => {
                    __pather = new Pather();
                    __map.addLayer(__pather);
                    this.disableMapInteraction();
                }

                this.hideFreeHandLayer = () => {
                    __map.removeLayer(__pather)
                    __pather = null;
                    this.enableMapInteraction();
                }
        
        return this;
        
    }

        __interface = new ConstructorSingletoneMap(params);
    
    
    return __interface;
}
