export const retry = (fn) => {
  return new Promise((resolve) => {
    fn()
      .then(resolve)
      .catch((error) => {
        if (error.toString().indexOf('ChunkLoadError') > -1) {
          console.log('[ChunkLoadError] Reloading due to error');
          window.location.reload(true);
        }
      });
  });
};
