import * as types from '../action_types';

import { placeAPI } from '../../API/place.service.api';
import { filterFoldersPlaces } from 'app/components/Utility/utility';

export const PlaceFetchData = () => {
  return {
    type: types.FETCH_PLACE_ALL,
    payload: placeAPI.getPublicPlaces().then((res) => res.response),
  };
};

export const MyPlaceFetchData = () => {
  return {
    type: types.FETCH_PLACE_ALL,
    payload: placeAPI
      .getMyPlacesList()
      .then((res) => filterFoldersPlaces(res.response)),
  };
};

export const PlaceFetchListData = () => {
  return {
    type: types.FETCH_PLACE_LIST,
    payload: placeAPI.getPublicPlaces().then((res) => res.response),
  };
};

export const MyPlaceFetchListData = () => {
  return {
    type: types.FETCH_PLACE_LIST,
    payload: placeAPI.getMyPlacesList().then((res) => res.response),
  };
};

export const PlaceNearestFetchData = (poly, type) => {
  return {
    type: types.FETCH_PLACE_ALL,
    payload: placeAPI.getNearestPlaces(poly, type).then((res) => res.response),
  };
};

export const PlaceSetEditMode = (data) => {
  return {
    type: types.PLACE_SET_EDIT_MODE,
    payload: data,
  };
};

export const PlacesSetEmptyData = (data) => {
  return {
    type: types.FETCH_PLACE_FULL_DATA_FULFILLED,
    payload: data,
  };
};

export const PlacesSetError = (error) => {
  return {
    type: types.FETCH_PLACE_FULL_DATA_REJECTED,
    payload: error,
  };
};

export const PlaceDataAddOwner = (data) => {
  return {
    type: types.PLACE_FULL_DATA_ADD_OWNER,
    payload: data,
  };
};

export const PlaceSetBookmark = (data) => {
  return {
    type: types.PLACE_SET_BOOKMARK,
    payload: data,
  };
};

export const PlaceSetAccessibilityType = (data) => {
  return {
    type: types.PLACE_SET_ACCESSILITY_TYPE,
    payload: data,
  };
};

export const PlacePointGet = (data) => {
  return {
    type: types.PLACE_POINT_GET,
    payload: data,
  };
};

export const PlaceSetCategory = (data) => {
  return {
    type: types.PLACE_SET_CATEGORY,
    payload: data,
  };
};

export const PlaceSetIconType = (data) => {
  return {
    type: types.PLACE_SET_ICON_TYPE,
    payload: data,
  };
};

export const PlaceSetIconColor = (data) => {
  return {
    type: types.PLACE_SET_ICON_COLOR,
    payload: data,
  };
};

export const PlaceUpdateImage = (data) => {
  return {
    type: types.PLACE_UPDATE_IMAGE,
    payload: data,
  };
};

export const PlaceSetTitle = (data) => {
  return {
    type: types.PLACE_SET_TITLE,
    payload: data,
  };
};

export const PlaceSetDescription = (data) => {
  return {
    type: types.PLACE_SET_DESCRIPTION,
    payload: data,
  };
};

export const PlaceSetInitialState = () => {
  return {
    type: types.PLACE_INITIAL_STATE,
  };
};

export const PlaceUserAmount = () => {
  return {
    type: types.USER_PLACE_AMOUNT,
    payload: placeAPI.amount().then((res) => res.response),
  };
};

export const markJustImportedPlace = (wasJustImported) => {
  return {
    type: types.PLACE_FULL_DATA_IMPORTED,
    payload: wasJustImported,
  };
};

export const setAccessibilityPlaceType = (newTypes) => {
  return {
    type: types.PLACE_FULL_SET_ACCESSILITY_TYPE,
    payload: { newTypes },
  };
};

export const addPlaceIdInCreateTour = (id) => {
  return {
    type: types.PLACE_ADD_ID_IN_CREATE_TOUR,
    payload: id,
  };
};

export const removePlaceIdInCreateTour = (id) => {
  return {
    type: types.PLACE_REMOVE_ID_IN_CREATE_TOUR,
    payload: id,
  };
};
