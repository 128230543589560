import axios from 'axios';

const getURL = () => `${process.env.API_URL}/api`;

let sourceAB = axios.CancelToken.source();
let sourceAA = axios.CancelToken.source();

const updateSourceAB = () => {
  if (sourceAB) sourceAB.cancel('Loading data is not actual yet');
  sourceAB = axios.CancelToken.source();
  return sourceAB.token;
};

const updateSourceAA = () => {
  if (sourceAA) sourceAA.cancel('Loading data is not actual yet');
  sourceAA = axios.CancelToken.source();
  return sourceAA.token;
};

const handleResponse = (response) => {
  const { data } = response;
  if (response.status !== 200) {
    const error = (data && data.message) || response.statusText;
    return error;
  }
  return data;
};

function getDescription(id) {
  const requestOptions = {
    method: 'GET',
  };
  return axios(
    `${getURL()}/tour.getDescription/?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getMyToursShortInfo() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/tour.getMyToursShortInfo`, requestOptions).then(
    handleResponse
  );
}

function getToursShortInfoByUserId(ownerId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/tour.getToursShortInfoByUserId?owner_id=${ownerId}`,
    requestOptions
  ).then(handleResponse);
}

function getToursInfoByUserId(ownerId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/tour.getToursInfoByUserId?owner_id=${ownerId}`,
    requestOptions
  ).then(handleResponse);
}

function getMetaTags(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/tour.getTourMetaTags?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function downloadGpx(
  objectTitle,
  objectId,
  name,
  scope = 'tour',
  type = 'gpx',
  extension = 0,
  reverse = false,
  tourAs = 'route'
) {
  const title = objectTitle.toLowerCase().split(' ').join('_');
  const params = {
    object_id: objectId,
    scope,
    type,
    name,
  };
  if (scope === 'tour') {
    params.reverse = reverse;
    params.extension = extension;
    params.tourAs = tourAs;
  }

  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    responseType: 'blob', // important
    params,
  };

  axios(`${getURL()}/gps.download`, requestOptions).then((response) => {
    if (
      type === 'bbox' ||
      type === 'gpx_bmw_conn' ||
      type === 'gpx_honda_99' ||
      type === 'triumph'
    ) {
      type = 'gpx';
    }
    if (type === 'navigonfresh') {
      type = 'freshroute';
    }
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(
        new Blob([response.data]),
        `${title}.${type}`
      );
    } else {
      const url = (window.URL || window.webkitURL).createObjectURL(
        new Blob([response.data])
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.${type}`);
      document.body.appendChild(link);
      link.click();
    }
  });
}

function getUploadPictureURL() {
  return `${getURL()}/tour.uploadPicture`;
}
function getUrlUploadGps() {
  return `${getURL()}/tour.uploadGPS`;
}

async function saveTour(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data,
  };
  return axios(`${getURL()}/tour.saveTourFromAPI`, requestOptions).then(
    handleResponse
  );
}
function saveTourAfterOptimization(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data,
  };
  return axios(
    `${getURL()}/tour.saveTourAfterOptimization`,
    requestOptions
  ).then(handleResponse);
}
function saveTourAfterTabDnd(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data,
  };
  return axios(`${getURL()}/tour.saveTourAfterTabDnd`, requestOptions).then(
    handleResponse
  );
}
function removeTour(id, user = {}) {
  const userId = user && user.id ? user.id : 'guest';
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/tour.removeTour?tour=${id}&userId=${userId}`,
    requestOptions
  ).then(handleResponse);
}

function amount() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/tour.amount`, requestOptions).then(handleResponse);
}

function attachToursToUser(tourIds) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/tour.attachToursToUser?tourIds=${tourIds}`,
    requestOptions
  ).then(handleResponse);
}

function getMyTourLazy(page) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/list.tour?limit=20&mine_all=1&page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function listRRTourTypes() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/list.listRRTourTypes`, requestOptions).then(
    handleResponse
  );
}

function sortByLoc(loc, type = 'public') {
  const requestOptions = {
    method: 'GET',
    // withCredentials: true,
  };
  return axios(
    `${process.env.NODE_BACKEND}/nearest?loc=${loc}&type=${type}`,
    requestOptions
  ).then(handleResponse);
}

function getByIds(ids) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/list.getByIds?ids=${ids}`, requestOptions).then(
    handleResponse
  );
}

async function twistingAA(
  coefficient,
  direction,
  isRandom,
  lat = 51.2481632,
  lng = 28.6509504
) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: {
      coefficient,
      isRandom,
      lat,
      lng,
      direction,
    },
    cancelToken: updateSourceAA(),
  };
  return axios(`${getURL()}/twisting.twistingAA`, requestOptions).then(
    handleResponse
  );
}

async function twistingAB(
  coefficient,
  direction,
  isRandom,
  startPoint,
  endPoint,
  latLng
) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: {
      coefficient,
      isRandom,
      startPoint,
      endPoint,
      direction,
      latLng,
    },
    cancelToken: updateSourceAB(),
  };
  return axios(`${getURL()}/twisting.twistingAB`, requestOptions).then(
    handleResponse
  );
}

const tourPrevAPI = {
  getDescription,
  getMyToursShortInfo,
  getToursShortInfoByUserId,
  downloadGpx,
  getUploadPictureURL,
  amount,
  saveTour,
  removeTour,
  attachToursToUser,
  getUrlUploadGps,
  getToursInfoByUserId,
  getMetaTags,
  getMyTourLazy,
  saveTourAfterOptimization,
  saveTourAfterTabDnd,
  listRRTourTypes,
  sortByLoc,
  getByIds,
  twistingAA,
  twistingAB,
};

export default tourPrevAPI;
