import * as types from '../actions/action_types';

const initialState = {
  isLogin: false,
  modalOpen: false,
  proModalOpen: false,
  legalIssuesModalOpen: false,
  unregister: false,
  isChackedEmail: false,
  isCheckedLegalIssues: false,
  isModalOpenFAQ: false,
  faqParentId: 0,
};

const loginData = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DATA_IS_LOGIN:
      return { ...state, isLogin: action.payload };
    case types.LOGIN_DATA_MODAL_OPEN:
      return { ...state, modalOpen: action.payload };
    case types.LOGIN_DATA_PRO_MODAL_OPEN:
      return { ...state, proModalOpen: action.payload };
    case types.LOGIN_DATA_LEGAL_ISSUES_OPEN:
      return { ...state, legalIssuesModalOpen: action.payload };
    case types.SET_IS_UNREGISTER_USER_DATA:
      return { ...state, unregister: action.payload, isChackedEmail: true };
    case types.SET_IS_USER_CONFIRM_LEGAL_ISSUES:
      return { ...state, isCheckedLegalIssues: action.payload };
    case types.IS_FAQ_MODAL_OPEN:
      return { ...state, isModalOpenFAQ: action.payload };
    case types.SET_FAQ_PARENT_ID:
      return { ...state, faqParentId: action.payload };
    default:
      return state;
  }
};
export default loginData;
