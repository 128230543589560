/*eslint-disable */
import d3 from 'd3';

import { FeatureGroup } from 'leaflet';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';

import mapUtils from './mapUtils';

import { Point } from 'app/libs/map/drive/Point';

import { getCustomId } from 'app/libs/core/Unique';

const MODES = {
  VIEW: 1,
  CREATE: 2,
  EDIT: 4,
  DELETE: 8,
  APPEND: 16,
  EDIT_APPEND: 4 | 16,
  ALL: 1 | 2 | 4 | 8 | 16,
};

const DATA_ATTRIBUTE =
  typeof Symbol === 'undefined' ? '_pather' : Symbol.for('pather');

export const Pather = FeatureGroup.extend({
  initialize: function initialize(options) {
    this.options = Object.assign(this.defaultOptions(), options || {});
    this.creating = false;
    this.polylines = [];
    this.eventHandlers = [];
  },

  createPath: async function createPath(latLngs) {
    if (latLngs.length <= 1) {
      return false;
    }

    this.clearAll();

    const polyline = new Pather.Polyline(this.map, latLngs, this.options, {});

    const latLongs = this.filter(polyline.getLatLngs());

    await this.map.removeLayer(polyline.getPoly());

    publish(way.DRIVE_ADD_POINT_BY_FINGER_DRAW, { points: latLongs });
  },

  arePointsNear: function arePointsNear(checkPoint, centerPoint, m) {
    if (
      checkPoint?.lat &&
      checkPoint?.lng &&
      centerPoint?.lat &&
      centerPoint?.lng
    ) {
      const R = 6371e3;
      const φ1 = (checkPoint.lat * Math.PI) / 180;
      const φ2 = (centerPoint.lat * Math.PI) / 180;
      const Δφ = ((centerPoint.lat - checkPoint.lat) * Math.PI) / 180;
      const Δλ = ((centerPoint.lng - checkPoint.lng) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const d = R * c;
      return d < m;
    } else {
      return false;
    }
  },

  filter: function filter(latLngs) {
    for (let i = 0; i < latLngs.length - 1; i++) {
      if (this.arePointsNear(latLngs[i], latLngs[i + 1], 500)) {
        latLngs = latLngs.splice(i + 1, 1);
      }
    }
    return latLngs;
  },

  removePath: function removePath(model) {
    if (model instanceof L.Pather.Polyline) {
      var indexOf = this.polylines.indexOf(model);
      this.polylines.splice(indexOf, 1);

      model.softRemove();

      this.fire('deleted', {
        polyline: model,
        latLngs: [],
      });

      return true;
    }

    return false;
  },

  getPaths: function getPolylines() {
    return this.polylines;
  },

  onAdd: function onAdd(map) {
    var element = (this.element = this.options.element || map.getContainer());
    this.draggingState = map.dragging._enabled;
    this.map = map;
    this.fromPoint = { x: 0, y: 0 };
    this.svg = d3
      .select(element)
      .append('svg')
      .attr('pointer-events', 'none')
      .attr('class', this.getOption('moduleClass'))
      .attr('width', this.getOption('width'))
      .attr('height', this.getOption('height'))
      .attr('fill', this.getOption('strokeColor'));

    map.dragging.disable();

    this.attachEvents(map);
    this.setMode(this.options.mode);
  },

  onRemove: function onRemove() {
    this.svg.remove();

    if (this.options.removePolylines) {
      var length = this.polylines.length;

      while (length--) {
        this.removePath(this.polylines[length]);
      }
    }

    this.map.off('mousedown', this.eventHandlers.mouseDown);
    this.map.off('mousemove', this.eventHandlers.mouseMove);
    this.map.off('mouseup', this.eventHandlers.mouseUp);
    this.map
      .getContainer()
      .removeEventListener('mouseleave', this.eventHandlers.mouseLeave);

    this.element.classList.remove('mode-create');
    this.element.classList.remove('mode-delete');
    this.element.classList.remove('mode-edit');
    this.element.classList.remove('mode-append');

    var tileLayer = this.map.getContainer().querySelector('.leaflet-tile-pane'),
      originalState = this.draggingState ? 'enable' : 'disable';
    tileLayer.style.pointerEvents = 'all';
    this.map.dragging[originalState]();
  },

  edgeBeingChanged: function edgeBeingChanged() {
    const edges = this.polylines.filter(function filter(polyline) {
      return polyline.manipulating;
    });

    return edges.length === 0 ? null : edges[0];
  },

  isPolylineCreatable: function isPolylineCreatable() {
    return !!(this.options.mode & MODES.CREATE);
  },

  events: {
    mouseDown: function mouseDown(event) {
      event = event.originalEvent || this.getEvent(event);

      const point = this.map.mouseEventToContainerPoint(event),
        latLng = this.map.containerPointToLatLng(point);

      if (this.isPolylineCreatable() && !this.edgeBeingChanged()) {
        this.creating = true;
        this.fromPoint = this.map.latLngToContainerPoint(latLng);
        this.latLngs = [];
      }
    },

    mouseMove: function mouseMove(event) {
      event = event.originalEvent || this.getEvent(event);
      const point = this.map.mouseEventToContainerPoint(event);

      if (this.edgeBeingChanged()) {
        this.edgeBeingChanged().moveTo(
          this.map.containerPointToLayerPoint(point)
        );
        return;
      }

      const lineFunction = d3.svg
        .line()
        .x(function x(d) {
          return d.x;
        })
        .y(function y(d) {
          return d.y;
        })
        .interpolate('linear');

      if (this.creating) {
        const lineData = [this.fromPoint, new L.Point(point.x, point.y, false)];
        this.latLngs.push(point);

        this.svg
          .append('path')
          .classed(this.getOption('lineClass'), true)
          .attr('d', lineFunction(lineData))
          .attr('stroke', this.getOption('strokeColour'))
          .attr('stroke-width', this.getOption('strokeWidth'))
          .attr('fill', this.getOption('strokeColour'));

        this.fromPoint = { x: point.x, y: point.y };
      }
    },

    mouseLeave: function mouseLeave() {
      this.clearAll();
      this.creating = false;
    },

    mouseUp: function mouseup() {
      if (this.creating) {
        this.creating = false;
        this.createPath(this.convertPointsToLatLngs(this.latLngs));
        this.latLngs = [];
        return;
      }

      if (this.edgeBeingChanged()) {
        this.edgeBeingChanged().attachElbows();
        this.edgeBeingChanged().finished();
        this.edgeBeingChanged().manipulating = false;
      }
    },
  },

  attachEvents: function attachEvents(map) {
    this.eventHandlers = {
      mouseDown: this.events.mouseDown.bind(this),
      mouseMove: this.events.mouseMove.bind(this),
      mouseUp: this.events.mouseUp.bind(this),
      mouseLeave: this.events.mouseLeave.bind(this),
    };

    this.map.on('mousedown', this.eventHandlers.mouseDown);
    this.map.on('mousemove', this.eventHandlers.mouseMove);
    this.map.on('mouseup', this.eventHandlers.mouseUp);
    this.map
      .getContainer()
      .addEventListener('mouseleave', this.eventHandlers.mouseLeave);

    // Attach the mobile events that delegate to the desktop events.
    this.map
      .getContainer()
      .addEventListener('touchstart', this.fire.bind(map, 'mousedown'));
    this.map
      .getContainer()
      .addEventListener('touchmove', this.fire.bind(map, 'mousemove'));
    this.map
      .getContainer()
      .addEventListener('touchend', this.fire.bind(map, 'mouseup'));
  },

  convertPointsToLatLngs: function convertPointsToLatLngs(points) {
    return points.map(
      function map(point) {
        return this.map.containerPointToLatLng(point);
      }.bind(this)
    );
  },

  clearAll: function clearAll() {
    this.svg.text('');
  },

  getOption: function getOption(property) {
    return this.options[property] || this.defaultOptions()[property];
  },

  defaultOptions: function defaultOptions() {
    return {
      moduleClass: 'patherMain',
      lineClass: 'drawing-line',
      detectTouch: true,
      elbowClass: 'elbow',
      removePolylines: true,
      strokeColour: '#652C95',
      strokeWidth: 4,
      width: '100%',
      height: '100%',
      smoothFactor: 10,
      pathColour: 'transparent',
      pathOpacity: 1,
      pathWidth: 6,
      mode: MODES.CREATE,
    };
  },

  setMode: function setMode(mode) {
    this.setClassName(mode);
    this.options.mode = mode;

    var tileLayer = this.map.getContainer().querySelector('.leaflet-tile-pane');

    var shouldDisableDrag = function shouldDisableDrag() {
      if (
        this.detectTouch &&
        ('ontouchstart' in $window || 'onmsgesturechange' in $window)
      ) {
        return (
          this.options.mode & MODES.CREATE || this.options.mode & MODES.EDIT
        );
      }

      return this.options.mode & MODES.CREATE;
    }.bind(this);

    if (shouldDisableDrag()) {
      var originalState = this.draggingState ? 'disable' : 'enable';
      tileLayer.style.pointerEvents = 'none';
      return void this.map.dragging[originalState]();
    }

    tileLayer.style.pointerEvents = 'all';
    this.map.dragging.enable();
  },

  setClassName: function setClassName(mode) {
    var conditionallyAppendClassName = function conditionallyAppendClassName(
      modeName
    ) {
      var className = ['mode', modeName].join('-');

      if (MODES[modeName.toUpperCase()] & mode) {
        return void this.element.classList.add(className);
      }

      this.element.classList.remove(className);
    }.bind(this);

    conditionallyAppendClassName('create');
    conditionallyAppendClassName('delete');
    conditionallyAppendClassName('edit');
    conditionallyAppendClassName('append');
  },

  getMode: function getMode() {
    return this.options.mode;
  },

  setOptions: function setOptions(options) {
    this.options = Object.assign(this.options, options || {});
  },
});

export function pather(options) {
  return new Pather(options);
}

Pather.Polyline = function Polyline(map, latLngs, options, methods) {
  this.options = {
    color: options.pathColour,
    opacity: options.pathOpacity,
    weight: options.pathWidth,
    smoothFactor: options.smoothFactor || 1,
    elbowClass: options.elbowClass,
  };

  this.polyline = new L.Polyline(latLngs, this.options);
  this.map = map;
  this.polyline.addTo(this.map);
  this.methods = methods;
  this.edges = [];
  this.manipulating = false;

  // this.attachPolylineEvents(this.polyline);
  // this.select();
};

Pather.Polyline.prototype = {
  select: function select() {
    this.attachElbows();
  },

  deselect: function deselect() {
    this.manipulating = false;
  },

  getPoly: function getPoly() {
    return this.polyline;
  },

  attachElbows: function attachElbows() {
    this.detachElbows();

    // this.polyline._parts[0].forEach(
    //   function forEach(point) {
    //     var divIcon = new L.DivIcon({ className: this.options.elbowClass }),
    //       latLng = this.map.layerPointToLatLng(point),
    //       edge = new L.Marker(latLng, { icon: divIcon }).addTo(this.map);

    //     edge[DATA_ATTRIBUTE] = { point: point };
    //     this.attachElbowEvents(edge);
    //     this.edges.push(edge);
    //   }.bind(this)
    // );
  },

  detachElbows: function detachElbows() {
    this.edges.forEach(
      function forEach(edge) {
        this.map.removeLayer(edge);
      }.bind(this)
    );

    this.edges.length = 0;
  },

  attachPolylineEvents: function attachPathEvent(polyline) {
    polyline.on(
      'click',
      function click(event) {
        event.originalEvent.stopPropagation();
        event.originalEvent.preventDefault();

        if (this.methods.mode() & MODES.APPEND) {
          // Appending takes precedence over deletion!
          var latLng = this.map.mouseEventToLatLng(event.originalEvent);
          this.insertElbow(latLng);
        } else if (this.methods.mode() & MODES.DELETE) {
          this.methods.remove(this);
        }
      }.bind(this)
    );
  },

  attachElbowEvents: function attachElbowEvents(marker) {
    marker.on(
      'mousedown',
      function mousedown(event) {
        event = event.originalEvent || event;

        if (this.methods.mode() & MODES.EDIT) {
          if (event.stopPropagation) {
            event.stopPropagation();
            event.preventDefault();
          }

          this.manipulating = marker;
        }
      }.bind(this)
    );

    marker.on('mouseup', function mouseup(event) {
      event = event.originalEvent || event;

      if (event.stopPropagation) {
        event.stopPropagation();
        event.preventDefault();
      }

      this.manipulating = false;
    });

    // Attach the mobile events to delegate to the desktop equivalent events.
    marker._icon.addEventListener(
      'touchstart',
      marker.fire.bind(marker, 'mousedown')
    );
    marker._icon.addEventListener(
      'touchend',
      marker.fire.bind(marker, 'mouseup')
    );
  },

  insertElbow: function insertElbow(latLng) {
    var newPoint = this.map.latLngToLayerPoint(latLng),
      leastDistance = Infinity,
      insertAt = -1,
      points = this.polyline._parts[0];

    points.forEach(
      function forEach(currentPoint, index) {
        var nextPoint = points[index + 1] || points[0],
          distance = L.LineUtil.pointToSegmentDistance(
            newPoint,
            currentPoint,
            nextPoint
          );

        if (distance < leastDistance) {
          leastDistance = distance;
          insertAt = index;
        }
      }.bind(this)
    );

    points.splice(insertAt + 1, 0, newPoint);

    var parts = points.map(
      function map(point) {
        var latLng = this.map.layerPointToLatLng(point);
        return { _latlng: latLng };
      }.bind(this)
    );

    this.redraw(parts);
    // this.attachElbows();
  },

  moveTo: function moveTo(point) {
    var latLng = this.map.layerPointToLatLng(point);
    this.manipulating.setLatLng(latLng);
    this.redraw(this.edges);
  },

  finished: function finished() {
    this.methods.fire('edited', {
      polyline: this,
      latLngs: this.getLatLngs(),
    });
  },

  redraw: function redraw(edges) {
    var latLngs = [],
      options = {};

    edges.forEach(function forEach(edge) {
      latLngs.push(edge._latlng);
    });

    Object.keys(this.options).forEach(
      function forEach(key) {
        options[key] = this.options[key];
      }.bind(this)
    );

    options.smoothFactor = 0;

    this.softRemove(false);
    this.polyline = new L.Polyline(latLngs, options).addTo(this.map);
    this.attachPolylineEvents(this.polyline);
  },

  softRemove: function softRemove(edgesToo) {
    edgesToo = typeof edgesToo === 'undefined' ? true : edgesToo;

    this.map.removeLayer(this.polyline);

    if (edgesToo) {
      this.edges.forEach(
        function forEach(edge) {
          this.map.removeLayer(edge);
        }.bind(this)
      );
    }
  },

  getLatLngs: function getLatLngs() {
    return this.polyline._parts[0].map(
      function map(part) {
        return this.map.layerPointToLatLng(part);
      }.bind(this)
    );
  },
};
