import { counter as types } from '../actions/action_types';

export function counter(state = {}, { type, payload }) {
  switch (type) {
    case types.setMyTours:
      return {
        ...state,
        myTours: payload,
      };

    case types.setMyPlaces:
      return {
        ...state,
        myPlaces: payload,
      };

    case types.increase: {
      const { key, count } = payload;
      return {
        ...state,
        [key]: state[key] + count,
      };
    }

    case types.decrease: {
      const { key, count } = payload;
      return {
        ...state,
        [key]: state[key] - count,
      };
    }

    default:
      return state;
  }
}
