export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  UPDATE_USER_DATA: 'UPDATE_USER_DATA',
};

export const userFriendStatus = {
  OFFER_FRIENDSHIP: 0,
  REMOVE_FRIENDSHIP: 1,
  ACCEPT_FRIENDSHIP: 2,
  FRIENDSHIP_REQUEST_SENT: 3,
  FRIENDSHIP_LOADER: 4,
};

export const userHistory = Object.freeze({
  SEARCH_MAIN: 'historysearchmain',
  DOWNLOAD_GPS: 'downloadgps',
  HISTORY_GPS: 'historygps',
  HOME_USER_ADDRESS: 'home_user_address',
  WORK_USER_ADDRESS: 'work_user_address',
  USER_LANGUAGE: 'user_language',
  HOME_USER_ADDRESS_STATUS: 'home_user_address_status',
  WORK_USER_ADDRESS_STATUS: 'work_user_address_status',
  OPTIMIZE_TOUR_SETTINGS: 'optimize_tour_settings',
});

export const userRole = Object.freeze({
  GM: 0,
  CM: 1,
  BM: 2,
  MEMBER: 3,
  GUEST: 4,
});

export const GUEST_FE_USER_HISTORY_IDENTIFIER = 'getGuestHistoryIdentifier';
export const USER_LANGUAGE_BROWSER_COOKIE_NAME = 'Language';

export const USER_MAP_POSITION = 'user_map_position';

export const LengthType = {
  KM: 'kilometers',
  M: 'miles',
};

export const UserMainPageType = {
  INDEX: 0,
  PROFILE: 1,
  PUBLIC_TOURS: 2,
  MY_TOURS_LIST_FROM: 3,
  MY_TOURS_MAP_VIEW: 4,
};
