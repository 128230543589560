import pointToilet from 'static/images/icons/filters/Toilet.svg';
import pointView from 'static/images/icons/filters/ViewPoint.svg';
import pointRestaurant from 'static/images/icons/filters/Restaurant.svg';
import pointMeeting from 'static/images/icons/filters/MeetingPoint.svg';
import pointCafe from 'static/images/icons/filters/Cafeteria.svg';
import pointCampsite from 'static/images/icons/filters/Campsite.svg';
import pointFastFood from 'static/images/icons/filters/FastFood.svg';
import pointHotel from 'static/images/icons/filters/Hotel.svg';
import pointIceCream from 'static/images/icons/filters/IceCream.svg';
import pointMuseum from 'static/images/icons/filters/Museum.svg';
import pointPicnicSite from 'static/images/icons/filters/Picnic.svg';
import pointPOI from 'static/images/icons/filters/Place.svg';
import pointMotorcycleDealer from 'static/images/icons/filters/MotorcycleDealer.svg';

import pointToiletActive from 'static/images/icons/filters/Toilet_2.svg';
import pointViewActive from 'static/images/icons/filters/ViewPoint_2.svg';
import pointRestaurantActive from 'static/images/icons/filters/Restaurant_2.svg';
import pointMeetingActive from 'static/images/icons/filters/MeetingPoint_2.svg';
import pointCafeActive from 'static/images/icons/filters/Cafeteria_2.svg';
import pointCampsiteActive from 'static/images/icons/filters/Campsite_2.svg';
import pointFastFoodActive from 'static/images/icons/filters/FastFood_2.svg';
import pointHotelActive from 'static/images/icons/filters/Hotel_2.svg';
import pointIceCreamActive from 'static/images/icons/filters/IceCream_2.svg';
import pointMuseumActive from 'static/images/icons/filters/Museum_2.svg';
import pointPicnicSiteActive from 'static/images/icons/filters/Picnic_2.svg';
import pointPOIActive from 'static/images/icons/filters/Place_2.svg';
import pointMotorcycleDealerActive from 'static/images/icons/filters/MotorcycleDealer_2.svg';

import pointCustomBlue from 'static/images/icons/marker_colors/poi_blue26x40.png';
import pointCustomGreen from 'static/images/icons/marker_colors/poi_green26x40.png';
import pointCustomPurple from 'static/images/icons/marker_colors/poi_purple26x40.png';
import pointCustomRed from 'static/images/icons/marker_colors/poi_red26x40.png';
import pointCustomYellow from 'static/images/icons/marker_colors/poi_yellow26x40.png';

export const PlaceTypes = {
  1: 'Ice Cream',
  2: 'View',
  3: 'Restaurant',
  4: 'Meeting',
  5: 'Cafe',
  6: 'Campsite',
  7: 'Fast Food',
  8: 'Hotel',
  9: 'Toilet',
  10: 'Museum',
  11: 'Picnic Site',
  12: 'Place Of Interest',
  13: 'Motorcycle Dealer',
};

export const PlaceTypesNames = {
  1: 'icecream',
  2: 'view',
  3: 'restaurant',
  4: 'meeting',
  5: 'cafe',
  6: 'campsite',
  7: 'fastfood',
  8: 'hotel',
  9: 'toilet',
  10: 'museum',
  11: 'picnicsite',
  12: 'placeofinterest',
  13: 'motorcycledealer',
};

export const PlaceIconType = {
  1: pointIceCream,
  2: pointView,
  3: pointRestaurant,
  4: pointMeeting,
  5: pointCafe,
  6: pointCampsite,
  7: pointFastFood,
  8: pointHotel,
  9: pointToilet,
  10: pointMuseum,
  11: pointPicnicSite,
  12: pointPOI,
  13: pointMotorcycleDealer,
};

export const PlaceIconTypeActive = {
  1: pointIceCreamActive,
  2: pointViewActive,
  3: pointRestaurantActive,
  4: pointMeetingActive,
  5: pointCafeActive,
  6: pointCampsiteActive,
  7: pointFastFoodActive,
  8: pointHotelActive,
  9: pointToiletActive,
  10: pointMuseumActive,
  11: pointPicnicSiteActive,
  12: pointPOIActive,
  13: pointMotorcycleDealerActive,
};

export const PlaceIconName = {
  icecream: pointIceCream,
  view: pointView,
  restaurant: pointRestaurant,
  meeting: pointMeeting,
  cafe: pointCafe,
  campsite: pointCampsite,
  fastfood: pointFastFood,
  hotel: pointHotel,
  toilet: pointToilet,
  museum: pointMuseum,
  picnicsite: pointPicnicSite,
  placeofinterest: pointPOI,
  motorcycledealer: pointMotorcycleDealer,
};

export const PlaceIconNameActive = {
  icecream: pointIceCreamActive,
  view: pointViewActive,
  restaurant: pointRestaurantActive,
  meeting: pointMeetingActive,
  cafe: pointCafeActive,
  campsite: pointCampsiteActive,
  fastfood: pointFastFoodActive,
  hotel: pointHotelActive,
  toilet: pointToiletActive,
  museum: pointMuseumActive,
  picnicsite: pointPicnicSiteActive,
  placeofinterest: pointPOIActive,
  motorcycledealer: pointMotorcycleDealerActive,
};

// export const PlaceCustomIcons = {
//   1: pointCustomBlue,
//   2: pointCustomGreen,
//   3: pointCustomPurple,
//   4: pointCustomRed,
//   5: pointCustomYellow,
// };

// export const PlaceIconColor = Object.freeze({ //depracated
//   1: 'blue',
//   2: 'green',
//   3: 'purple',
//   4: 'red',
//   5: 'yellow',
// });

export const WlaIconColor = Object.freeze({
  blue: pointCustomBlue,
  green: pointCustomGreen,
  purple: pointCustomPurple,
  red: pointCustomRed,
  yellow: pointCustomYellow,
});

export const CreateTourPlaceArea = Object.freeze({
  Nearest: 1,
  All: 2,
});

export const CreateTourPlaceType = Object.freeze({
  My: 1,
  Public: 2,
});
