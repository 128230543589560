import * as types from '../actions/action_types';

const initialState = {
  fetching: false,
  fetched: false,
  data: {},
  error: null,
};

const fetchPreviewData = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return action.payload.isListSwitched ? state : initialState;
    case types.FETCH_PREVIEW_DATA_CLEANE:
      return initialState;
    case types.FETCH_PREVIEW_DATA_HIPERSET:
      return { ...state, data: action.payload };
    case types.FETCH_PREVIEW_DATA_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_PREVIEW_DATA_REJECTED:
      return { ...state, fetching: false, error: action.payload };
    case types.FETCH_PREVIEW_DATA_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: action.payload };
    default:
      return state;
  }
};

export default fetchPreviewData;
