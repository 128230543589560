import axios from 'axios';
import { URL } from 'app/constants/mapConstants';
import store from 'app/store';

import pageCache from './cache.service';

const getURL = () => `${process.env.API_URL}/api`;

/*  Actual source */
let source = axios.CancelToken.source();

const updateSource = () => {
  if (source) source.cancel('Loading data is not actual yet');
  source = axios.CancelToken.source();
  return source.token;
};
/** -- */

const sourceCancel = () => {
  if (source) source.cancel('Loading data is not actual yet');
};

const prepareData = (resp, key) => {
  const { data = {} } = resp;
  const { code, entities } = data;
  if (code !== 200) throw new Error('Loading data is not actual yet');
  pageCache.set(key, entities);
  return entities || [];
};

const handleErr = (err) => {
  console.log(err);
};

function getFoldersTours() {
  const { folders } = store.getState();
  return folders.map(({ tours }) => tours).flat();
}

function filterFoldersTours(tours) {
  const folderTours = getFoldersTours();
  return tours.filter(({ id }) => !folderTours.includes(+id));
}

async function getEntities([key, link]) {
  const url = `${getURL()}/list.${link}`;
  const params = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource(),
  };

  let entities = pageCache.get(key);
  if (!entities) {
    const resp = await axios.get(url, params).catch(handleErr);
    entities = prepareData(resp || {}, key);
  }

  if (key === URL.TOUR.MY[0]) {
    entities = filterFoldersTours(entities);
  }
  return entities;
}

const getPublicTours = () => getEntities(URL.TOUR.PUBLIC);
const getNafTours = () => getEntities(URL.TOUR.NAF);
const getMargueritrutenTours = () => getEntities(URL.TOUR.MARGUERITRUTEN);
const getMyTours = () => getEntities(URL.TOUR.MY);
const getMyFriendsTours = () => getEntities(URL.TOUR.FRIENDS);
const getAllClubs = () => getEntities(URL.CLUB.ALL);
const getNafClubs = () => getEntities(URL.CLUB.NAF);
const getMyClubs = () => getEntities(URL.CLUB.MY);
const getAllBikerbeds = () => getEntities(URL.BIKERBED.ALL);
const getMyBikerbeds = () => getEntities(URL.BIKERBED.MY);
const getBestContent = () => getEntities(URL.CONTENT.BEST);

const clearMyTours = () => pageCache.destroy(URL.TOUR.MY[0]);
const clearMyFriendToursCacheData = () =>
  pageCache.destroy(URL.TOUR.FRIENDS[0]);
const clearClubs = () => {
  pageCache.destroy(URL.CLUB.MY[0]);
  pageCache.destroy(URL.CLUB.ALL[0]);
};
const clearBikerbeds = () => {
  pageCache.destroy(URL.BIKERBED.MY[0]);
  pageCache.destroy(URL.BIKERBED.ALL[0]);
};

const pageDataAPI = {
  getPublicTours,
  getNafTours,
  getMargueritrutenTours,
  getMyTours,
  getAllClubs,
  getNafClubs,
  getMyClubs,
  getAllBikerbeds,
  getMyBikerbeds,
  getBestContent,
  sourceCancel,
  clearMyTours,
  getMyFriendsTours,
  clearMyFriendToursCacheData,
  clearClubs,
  clearBikerbeds,
};

export default pageDataAPI;
