const axios = require('axios');

const getURL = () => `${process.env.API_URL}/api`;

const handleResponse = (response) => {
  return new Promise((resolve, reject) => {
    const { data } = response;
    if (response.status !== 200) {
      const error = (data && data.message) || response.statusText;
      return reject(error);
    }
    return resolve(data);
  });
};

function getMyPlacesList() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/place.getMyPlacesList`, requestOptions).then(
    handleResponse
  );
}

function getPublicPlaces() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/place.getPublicPlaces`, requestOptions).then(
    handleResponse
  );
}

function getPlaceFullDataById(id, share_key) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/place.getPlaceById?id=${id}&share_key=${share_key}`,
    requestOptions
  ).then(handleResponse);
}

function savePlace(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data,
  };
  return axios(`${getURL()}/place.savePlace`, requestOptions).then(
    handleResponse
  );
}

function removePlace(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/place.removePlace?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getUploadPictureURL() {
  return `${getURL()}/place.uploadPicture`;
}

function amount() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/place.amount`, requestOptions).then(handleResponse);
}

function preview(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/place.preview?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getNearestPlaces(poly, type) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: { poly, type },
  };
  return axios(`${getURL()}/place.getNearestPlaces`, requestOptions).then(
    handleResponse
  );
}

export const placeAPI = {
  getUploadPictureURL,
  savePlace,
  removePlace,
  getPlaceFullDataById,
  getMyPlacesList,
  amount,
  getPublicPlaces,
  preview,
  getNearestPlaces,
};
