import pointStart from 'static/images/icons/start_point.svg';
import pointVia from 'static/images/icons/via_point.svg';
import pointEnd from 'static/images/icons/end_point.svg';
import pointDrag from 'static/images/icons/drag_grey.svg';

import pointHome from 'static/img/svg/homeMapIcon.svg';
import pointHomeStart from 'static/img/svg/Home_-_start_point.svg';
import pointHomeStartActive from 'static/images/icons/home_start_highlighted.svg';
import pointHomeEndActive from 'static/images/icons/home_end_highlighted.svg';

import pointWork from 'static/img/svg/workMapIcon.svg';
import pointWorkStart from 'static/images/icons/work_start_point.svg';
import pointWorkEnd from 'static/images/icons/work_end_point.svg';
import pointWorkStartActive from 'static/images/icons/work_start_highlighted_point.svg';
import pointWorkEndActive from 'static/images/icons/work_end_highlighted_point.svg';

// import pointFood from 'static/images/icons/filters/restaurant.png';
// import pointHotel from 'static/images/icons/filters/lodging-2.png';
// import pointFuel from 'static/images/icons/filters/fillingstation.png';
// import pointParking from 'static/images/icons/filters/parkinggarage.png';
// import pointHospital from 'static/images/icons/filters/firstaid.png';

import pointStartActive from 'static/images/icons/start_point_selected.svg';
import pointViaActive from 'static/images/icons/via_point_selected.svg';
import pointEndActive from 'static/images/icons/end_point_selected.svg';
import pointDragActive from 'static/images/icons/drag_purple.svg';

// import pointPicnic from 'static/images/icons/filters/shelter_picnic.png';
// import pointToilet from 'static/images/icons/filters/toilets.png';
// import pointCamping from 'static/images/icons/filters/camping.png';
// import pointView from 'static/images/icons/filters/view.png';
// import pointTechMuseum from 'static/images/icons/filters/museum_industry.png';
// import pointMuseum from 'static/images/icons/filters/art-museum-2.png';
// import pointIcecream from 'static/images/icons/filters/icecream.png';

// import pointCustomBlue from 'static/images/icons/marker_colors/poi_blue26x40.png';
// import pointCustomGreen from 'static/images/icons/marker_colors/poi_green26x40.png';
// import pointCustomPurple from 'static/images/icons/marker_colors/poi_purple26x40.png';
// import pointCustomRed from 'static/images/icons/marker_colors/poi_red26x40.png';
// import pointCustomYellow from 'static/images/icons/marker_colors/poi_yellow26x40.png';

export const Type = Object.freeze({
  START: 'start',
  VIA: 'via',
  DRAG: 'drag',
  END: 'end',
  HOME: 'home',
  WORK: 'work',
  CLUB: 'club',
  BIKERBED: 'bikerbed',
});

export const Icon = Object.freeze({
  start: pointStart,
  end: pointEnd,
  via: pointVia,
  drag: pointDrag,
  work: pointWork,
  home: pointHome,
  home_start: pointHomeStart,
  home_end: pointHome,
  home_via: pointVia,
  work_start: pointWorkStart,
  work_end: pointWorkEnd,
  work_via: pointVia,
  // food: pointFood,
  // hotel: pointHotel,
  // fuel: pointFuel,
  // parking: pointParking,
  // hospital: pointHospital,
  // custom_blue: pointCustomBlue,
  // custom_green: pointCustomGreen,
  // custom_purple: pointCustomPurple,
  // custom_red: pointCustomRed,
  // custom_yellow: pointCustomYellow,
  // picnicplace: pointPicnic,
  // toilet: pointToilet,
  // camping: pointCamping,
  // viewpoint: pointView,
  // technicalmuseum: pointTechMuseum,
  // museum: pointMuseum,
  // icecreamandfastfood: pointIcecream,
});

export const ActiveIcon = Object.freeze({
  start: pointStartActive,
  end: pointEndActive,
  via: pointViaActive,
  drag: pointDragActive,
  work: pointWork,
  home: pointHome,
  home_start: pointHomeStartActive,
  home_end: pointHomeEndActive,
  home_via: pointViaActive,
  work_start: pointWorkStartActive,
  work_end: pointWorkEndActive,
  work_via: pointViaActive,
  // food: pointFood,
  // hotel: pointHotel,
  // fuel: pointFuel,
  // parking: pointParking,
  // hospital: pointHospital,
  // custom_blue: pointCustomBlue,
  // custom_green: pointCustomGreen,
  // custom_purple: pointCustomPurple,
  // custom_red: pointCustomRed,
  // custom_yellow: pointCustomYellow,
  // picnicplace: pointPicnic,
  // toilet: pointToilet,
  // camping: pointCamping,
  // viewpoint: pointView,
  // technicalmuseum: pointTechMuseum,
  // museum: pointMuseum,
  // icecreamandfastfood: pointIcecream,
});

export const MandatoryPointsList = [Type.START, Type.VIA, Type.END];
