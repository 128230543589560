import { userEnterState } from '../constants/enter.user.constants';
export function userEnter(state = {name: "start"}, action) {
  switch (action.type) {
    case 'SET_STATE_USER_ENTER':
        return { ...action.payload }
    // case userEnterState.DISPLAY:
    //   return {
    //     isLoggedIn: true,
    //     user: action.user
    //   };
    // case userEnterState.CHECKING_EMAIL:
    //   return {
    //     isLoggedIn: true,
    //     user: action.user
    //   };
    // case userEnterState.PASSWD:
    //   return {};
    // case userEnterState.LOADING:
    //   return {};
    default:
      return state
  }
}