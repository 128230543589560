export const accessType = Object.freeze({
  ALL_PUBLIC_TYPE: 0, // Public old type available for all users of Tourstart
  ALL_USERS_TYPE: 1, // The same new public type (it is feature)
  MY_FRIENDS_AND_ME_TYPE: 2, // Private type where my tour is available for me and for all my friends
  MY_BIKE_CLUB_TYPE: 3, // Public type where tour has relation to Bike club and available for all Tourstart's users
  MY_BIKER_BED_TYPE: 4, // The same public type only tour has relaton to BikerBed
  DEFAULT_ONLY_FOR_ME_TYPE: 5, // Private type where tour is available only for me, also I can share it with any users of Tourstart (the same like tours with other types)
  SELECT_FRIENDS_TYPE: 6, // Private type where my tour is available for me and my chose friends
  NAF_TOUR: 9, // HAF Tours
  MARGUERITRUTEN_TOUR: 10, // MARGUERITRUTEN Tours
});

export const isTourPrivate = (type) =>
  type === accessType.MY_FRIENDS_AND_ME_TYPE ||
  type === accessType.DEFAULT_ONLY_FOR_ME_TYPE ||
  type === accessType.SELECT_FRIENDS_TYPE;

export const isTourPublic = (type) =>
  type === accessType.ALL_PUBLIC_TYPE ||
  type === accessType.ALL_USERS_TYPE ||
  type === accessType.MY_BIKE_CLUB_TYPE ||
  type === accessType.MY_BIKER_BED_TYPE ||
  type === accessType.NAF_TOUR ||
  type === accessType.MARGUERITRUTEN_TOUR;

export const isTourRR = (type) => type === accessType.RR_TYPE;

export const getPublic = () => {
  const data = [
    accessType.ALL_PUBLIC_TYPE,
    accessType.ALL_USERS_TYPE,
    accessType.MY_BIKE_CLUB_TYPE,
    accessType.MY_BIKER_BED_TYPE,
  ];
  return data;
};

export const getPrivate = () => {
  const data = [
    accessType.DEFAULT_ONLY_FOR_ME_TYPE,
    accessType.MY_FRIENDS_AND_ME_TYPE,
    accessType.SELECT_FRIENDS_TYPE,
  ];
  return data;
};
