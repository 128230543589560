import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';
import emptyMarker from '../../markers/emptyMarker';

import mapUtils from '../../Utils/mapUtils';

export class Point extends emptyMarker {
  static Type = {
    START: 'start',
    VIA: 'via',
    DRAG: 'drag',
    END: 'end',
    CURRENT_POSITION: 'current_position',
    POI: 'poi',
    SHAPING: 'Shaping point',
    PLACE: 'place',
  };

  static Anchor = {
    START: [13, 34],
    VIA: [8, 20],
    DRUG: [6, 8],
    END: [13, 34],
    CURRENT_POSITION: [13, 34],
    POI: [13, 34],
    SHAPING: 'Shaping point',
  };
}

function stickToRoad(callback) {
  mapUtils.stickToRoad(
    {
      nearest: { lat: this.lat, lng: this.lng },
    },
    (e) => {
      if (typeof e.mapped_coordinate !== 'undefined') {
        this.lat = [...e.mapped_coordinate[0]];
        this.lng = [...e.mapped_coordinate[1]];
        callback(this);
      } else {
        console.error(e);
        // self.getMarker();
      }
    }
  );
}

function refreshAddress(updatePointsData) {
  this.title = 'Point address is loading...';
  updatePointsData();
  mapUtils.getTourstartGeocoder({ lat: this.lat, lng: this.lng }, (e) => {
    const address = mapUtils.formateTourstartAddress(e.data);
    this.address = address;
    this.title = address;
    updatePointsData();
  });
}

export const addPointMethods = (point) => {
  return { ...point, refreshAddress, stickToRoad };
};

const parsePoly = (e, { isEdit }) => {
  const latLngs = mapUtils.decodePath(e.routeGeometry);
  publish(way.DRIVE_MAP_CHANGE_POLY, { latLngs, isEdit });
};

export const showPolyline = (pointList, pointStack, isEdit = true) => {
  const pathArr = [];

  pointStack.forEach((id) => {
    const { lat, lng } = pointList[id];
    if (typeof lat !== 'undefined' && typeof lng !== 'undefined')
      pathArr.push(`${lng},${lat}`);
  });
  if (pathArr && pathArr.length > 1) {
    const options = {};
    options.overview = 'full';
    options.path = pathArr.join(';');
    mapUtils.getOSMDirectionsService(options, parsePoly, { isEdit });
  } else {
    publish(way.DRIVE_MAP_CHANGE_POLY, { latLngs: [] });
  }
};

const prepareOptions = (newPoint, newPointPos, pointList, pointStack) => {
  const { lat, lng } = newPoint;
  let pathViaStr = '';
  pointStack.forEach((id, it) => {
    const { lat: latP, lng: lngP } = pointList[id];

    pathViaStr += `${lngP},${latP};`;
    if (it === newPointPos) {
      pathViaStr += `${lng},${lat};`;
    }
  });
  const options = {};
  options.overview = 'simplified';
  options.path = pathViaStr.substring(0, pathViaStr.length - 1);

  return options;
};

const getImaginePosition = (newPoint, clotherPoint, pointList, pointStack) => {
  const { point, pos } = clotherPoint;
  const prevPoint = pointList[pointStack[pos - 1]];
  const afterPoint = pointList[pointStack[pos + 1]];
  const { lat, lng } = newPoint;
  let beforeDelta = 1000000;
  if (prevPoint) {
    const beforeDist = mapUtils.getDistance(
      prevPoint.lat,
      prevPoint.lng,
      point.lat,
      point.lng,
      'km'
    );
    const beforeToNewDist = mapUtils.getDistance(
      prevPoint.lat,
      prevPoint.lng,
      lat,
      lng,
      'km'
    );
    beforeDelta = beforeToNewDist / beforeDist;
  }

  let afterDelta = 1000000;
  if (afterPoint) {
    const afterDist = mapUtils.getDistance(
      afterPoint.lat,
      afterPoint.lng,
      point.lat,
      point.lng,
      'km'
    );
    const afterToNewDist = mapUtils.getDistance(
      afterPoint.lat,
      afterPoint.lng,
      lat,
      lng,
      'km'
    );
    afterDelta = afterToNewDist / afterDist;
  }

  return beforeDelta > afterDelta ? pos : pos - 1;
};

const onGetDirection = (e, { arrDistRoutes, point }) => {
  arrDistRoutes.push({
    routeDistance: e.routeSummary.totalDistance,
    osrmData: e,
    point,
  });
  if (arrDistRoutes.length > 1) {
    arrDistRoutes.sort((a, b) => a.routeDistance - b.routeDistance);
    const { lat, lng, number, type, draggable = true } = arrDistRoutes[0].point;
    publish(way.DRIVE_MAP_CLICK, { lat, lng, number, type, draggable });
    const latLngs = mapUtils.decodePath(
      arrDistRoutes[0].osrmData.routeGeometry
    );
    publish(way.DRIVE_MAP_CHANGE_POLY, { latLngs });
  }
};

export const detectPointPosition = (newPoint, pointList, pointStack) => {
  let pathEndStr = '';
  const { lat, lng } = newPoint;
  const distsToEachPoint = [];

  pointStack.forEach((id, i) => {
    const point = pointList[id];
    const dist = mapUtils.getDistance(lat, lng, point.lat, point.lng, 'km');
    distsToEachPoint.push({ dist, point, pos: i });

    pathEndStr += `${point.lng},${point.lat};`;
  });

  const options = {};
  options.overview = 'simplified';
  pathEndStr += `${lng},${lat}`;
  options.path = pathEndStr;

  const arrDistRoutes = [];
  mapUtils.getOSMDirectionsService(options, onGetDirection, {
    arrDistRoutes,
    point: { ...newPoint, type: Point.Type.END },
  });

  distsToEachPoint.sort((a, b) => a.dist - b.dist);
  const { point, pos } = distsToEachPoint[0];

  const prevPoint = pointList[pointStack[pos - 1]];
  let beforeDelta = 1000000;
  if (prevPoint) {
    const beforeDist = mapUtils.getDistance(
      prevPoint.lat,
      prevPoint.lng,
      point.lat,
      point.lng,
      'km'
    );
    const beforeToNewDist = mapUtils.getDistance(
      prevPoint.lat,
      prevPoint.lng,
      lat,
      lng,
      'km'
    );
    beforeDelta = beforeToNewDist / beforeDist;
  }

  const afterPoint = pointList[pointStack[pos + 1]];
  let afterDelta = 1000000;
  if (afterPoint) {
    const afterDist = mapUtils.getDistance(
      afterPoint.lat,
      afterPoint.lng,
      point.lat,
      point.lng,
      'km'
    );
    const afterToNewDist = mapUtils.getDistance(
      afterPoint.lat,
      afterPoint.lng,
      lat,
      lng,
      'km'
    );
    afterDelta = afterToNewDist / afterDist;
  }

  const newPointPos = beforeDelta > afterDelta ? pos : pos - 1;
  let pathViaStr = '';
  pointStack.forEach((id, it) => {
    const { lat: latP, lng: lngP } = pointList[id];

    pathViaStr += `${lngP},${latP};`;
    if (it === newPointPos) {
      pathViaStr += `${lng},${lat};`;
    }
  });
  options.path = pathViaStr.substring(0, pathViaStr.length - 1);
  mapUtils.getOSMDirectionsService(options, onGetDirection, {
    arrDistRoutes,
    point: { ...newPoint, type: Point.Type.VIA, number: newPointPos + 1 },
  });
};

// export const getPolyKey = (from, to) => {
//   const key = generatePolyKey(from, to);
//   if (hashPolyTable[key]) {
//     return hashPolyTable[key];
//   }
//   const options = {};
//   options.destination = this.next().latLng();
//   point = this.latLng();
//   options.origin = this.prev().latLng();

//   mapUtils.getOSMDirectionsService(options, onGetDirection);

//   hashPolyTable[key] = 'a';

//   return false;
// };

export const detectFastestPointPosition = (newPoint, pointList, pointStack) => {
  const { lat, lng } = newPoint;
  const distsToEachPoint = [];

  pointStack.forEach((id, i) => {
    const point = pointList[id];
    const dist = mapUtils.getDistance(lat, lng, point.lat, point.lng, 'km');
    distsToEachPoint.push({ dist, point, pos: i });
  });
  distsToEachPoint.sort((a, b) => a.dist - b.dist);

  const newPointPosFirst = getImaginePosition(
    newPoint,
    distsToEachPoint[0],
    pointList,
    pointStack
  );
  const newPointPosSecond = getImaginePosition(
    newPoint,
    distsToEachPoint[1],
    pointList,
    pointStack
  );

  const optionsFirst = prepareOptions(
    newPoint,
    newPointPosFirst,
    pointList,
    pointStack
  );
  const optionsSecond = prepareOptions(
    newPoint,
    newPointPosSecond,
    pointList,
    pointStack
  );

  const arrDistRoutes = [];
  mapUtils.getOSMDirectionsService(optionsFirst, onGetDirection, {
    arrDistRoutes,
    point: { ...newPoint, type: Point.Type.VIA, number: newPointPosFirst + 1 },
  });
  mapUtils.getOSMDirectionsService(optionsSecond, onGetDirection, {
    arrDistRoutes,
    point: { ...newPoint, type: Point.Type.VIA, number: newPointPosSecond + 1 },
  });
};
