import pageDataAPI from 'app/API/pageData.api';
import * as types from '../action_types';

export const getAllClubs = () => {
  return {
    type: types.FETCH_CLUBS_ALL,
    payload: pageDataAPI.getAllClubs(),
  };
};

export const getNafClubs = () => {
  return {
    type: types.FETCH_CLUBS_ALL,
    payload: pageDataAPI.getNafClubs(),
  };
};

export const getMyClubs = () => {
  return {
    type: types.FETCH_CLUBS_ALL,
    payload: pageDataAPI.getMyClubs(),
  };
};

export const getAllBeds = () => {
  return {
    type: types.FETCH_BIKERBEDS_ALL,
    payload: pageDataAPI.getAllBikerbeds(),
  };
};

export const getMyBeds = () => {
  return {
    type: types.FETCH_BIKERBEDS_ALL,
    payload: pageDataAPI.getMyBikerbeds(),
  };
};

export const setEditModeClub = (data) => {
  return {
    type: types.CLUB_FULL_IS_EDIT_MODE,
    payload: data,
  };
};

export const setClubFullData = (data) => {
  return {
    type: types.FETCH_CLUB_FULL_DATA,
    payload: data,
  };
};

export const setAddressClub = (data) => {
  return {
    type: types.CLUB_CREATE_SET_ADDRESS,
    payload: data,
  };
};

export const setDataClub = (data) => {
  return {
    type: types.CLUB_CREATE_SET_DATA,
    payload: data,
  };
};

export const setClubPictures = (data) => {
  return {
    type: types.CLUB_ADD_PICTURES,
    payload: data,
  };
};

export const setClubImageIds = (data) => {
  return {
    type: types.CLUB_ADD_IMAGE_IDS,
    payload: data,
  };
};

export const setClubVideo = (data) => {
  return {
    type: types.CLUB_ADD_VIDEO,
    payload: data,
  };
};

export const setDubData = (data) => {
  return {
    type: types.CLUB_SET_DUBDATA,
    payload: data,
  };
};

export const setOptions = (data) => {
  return {
    type: types.CLUB_ADD_OPTIONS,
    payload: data,
  };
};

export const setAdditionaly = (data) => {
  return {
    type: types.CLUB_ADD_ADDITIONALY,
    payload: data,
  };
};

export const setServices = (data) => {
  return {
    type: types.CLUB_ADD_SERVICES,
    payload: data,
  };
};
