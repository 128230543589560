import React, { Component } from 'react';
import axios from 'axios';

const getURL = () => `${process.env.API_URL}/api`;

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    const { message, stack } = error;
    const value = error.valueOf();
    const data = {
      value,
      message,
      stack,
      info,
    };

    axios({
      url: `${getURL()}/services.saveWebUIError`,
      method: 'POST',
      withCredentials: true,
      params: {
        data,
      },
    });

    console.log(error);
    console.info(info);
    console.trace();
  }

  render() {
    if (this.state.hasError) {
      if (this.props.errorView) {
        return this.props.errorView;
      }
      return <div>Something went wrong.</div>;
    }

    return this.props.children;
  }
}
