import * as types from '../actions/action_types';

const initialState = {
  mouseCursorIconType: '',
};

const cssEffect = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return initialState;
    case types.MOUSE_CURSOR_ICON_TYPE:
      return { ...state, mouseCursorIconType: action.payload };
    default:
      return state;
  }
};

export default cssEffect;
