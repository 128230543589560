import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  showLoading,
  hideLoading,
  resetLoading,
} from 'react-redux-loading-bar';
import { ErrorBoundary } from './errorBoundary';

class Async extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Component: void 0,//eslint-disable-line
    };
  }

  componentDidMount() {
    this.props.showLoading();
    this.props
      .provider()
      .then((res) => {
        this.props.hideLoading();
        this.setState({ Component: res.default });
      })
      .catch((error) => {
        if (error.toString().indexOf('ChunkLoadError') > -1) {
          console.log('[ChunkLoadError] Reloading due to error');
          window.location.reload(true);
        }
      });
  }

  render() {
    const { Component } = this.state;
    return (
      <ErrorBoundary>
        {Component ? <Component {...this.props.props} /> : null}
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    loadingBar: store.loadingBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
      resetLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Async);
