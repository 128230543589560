/*eslint-disable*/
import React, { Component, createRef, Suspense, lazy, Fragment } from 'react';
import { Route, Router, Redirect, Switch } from 'react-router';
import { history } from './store';
import { ConnectedRouter } from 'connected-react-router';
import MapComponent from './components/Desctop/Map/Conteiner/map.component';
import Async from './components/Utility/asyncFuncs';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingBar from 'react-redux-loading-bar';
import './components/Desctop/FAQ/faq.css';
import './components/Desctop/Drive/drive.css';
import ProgressBar from './components/Utility/progressBar';
import { isMobileOnly, isSmartTV } from 'react-device-detect';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { translation } from './react-intl';
import DefaultFunctionality from './components/Desctop/Default/index';
import { getTwoToneColor, setTwoToneColor } from '@ant-design/icons';
import { retry } from './components/Utility/loadingChunkErrorHandler';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { DndProvider } from 'react-dnd';
import { clubAPI } from './API/club.api';
import { getEntitiyLink } from './components/Utility/utility';
import { loginModalOpen, loginProModalOpen } from 'actions/Login/login.action';
import RoutesPath from './routes';
import { PartnersLogo } from './components/Desctop/HeaderNavBar/parterLogo';

class IncludeHistory extends Router {
  constructor(props) {
    super(props);
  }
}

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentNavi: null,
      componentNavBar: null,
      mountedMenu: false,
    };
  }

  componentDidMount() {
    if (!this.state.componentNavi) {
      this.setState({
        componentNavi: lazy(() =>
          retry(() =>
            import(
              /* webpackChunkName: "menu" */ './components/Desctop/MainMenu/newmenu.container'
            )
          )
        ),
        componentNavBar: lazy(() =>
          retry(() =>
            import(
              /* webpackChunkName: "navBar" */ './components/Desctop/HeaderNavBar/navbar'
            )
          )
        ),
      });
    }
  }

  onMenuDidMount = () => {
    this.setState({
      mountedMenu: true,
    });
  };

  render() {
    const NavMenu = this.state.componentNavi;
    const NavBar = this.state.componentNavBar;
    const { isContentBlurUnderModalMask: isBlur, activeLang } = this.props;
    const blurClass = isBlur ? 'content-blur-effect' : '';
    return (
      <ConnectedRouter history={history}>
        <IntlProvider locale={activeLang} messages={translation[activeLang]}>
          <div
            className={blurClass}
            style={{ height: '100%', width: '100%', position: 'fixed' }}
          >
            <LoadingBar
              updateTime={100}
              maxProgress={95}
              progressIncrease={10}
              style={{
                backgroundColor: '#aa2493',
                height: '2px',
                position: 'absolute',
                zIndex: 1003,
              }}
            />
            <MapComponent />
            <PartnersLogo />
            {NavBar ? (
              <Suspense fallback={<div></div>}>
                <NavBar />
              </Suspense>
            ) : (
              ''
            )}
            <div
              style={{
                display: 'flex',
                paddingTop: isMobileOnly || window.innerWidth < 768 ? 45 : 50,
                height: '100%',
              }}
            >
              <div>
                {NavMenu ? (
                  <Suspense fallback={<div></div>}>
                    <NavMenu callback={this.onMenuDidMount} />
                  </Suspense>
                ) : (
                  ''
                )}
              </div>
              {this.state.mountedMenu ? (
                <DndProvider
                  backend={isMobileOnly ? TouchBackend : HTML5Backend}
                >
                  <div
                    id="main_layout_content"
                    style={{
                      width: '50%',
                      display: 'flex',
                      flex: 1,
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      position: 'initial',
                    }}
                  >
                    <IncludeHistory history={history}>
                      <RoutesPath />
                    </IncludeHistory>
                  </div>
                  <DefaultFunctionality />
                </DndProvider>
              ) : null}
            </div>
          </div>
        </IntlProvider>
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isContentBlurUnderModalMask: store.cssEffect.isContentBlurUnderModalMask,
    activeLang: store.languageData.active,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginModalOpen,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Routes);
