import React from 'react';
import { Route, Redirect, Switch } from 'react-router';
import Async from './components/Utility/asyncFuncs';

const RoutesPath = () => (
  <Switch>
    <Route
      exact
      path="/"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Home/home')}
        />
      )}
    />
    <Route
      path="/list"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Home/home')}
        />
      )}
    />
    <Route
      path="/createtour"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Drive/drive')}
        />
      )}
    />
    <Route
      path="/tour"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Tour/tour')}
        />
      )}
    />
    <Route
      path="/dreamtours"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Dreamtour/dreamtour')}
        />
      )}
    />
    <Route
      path="/place"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Places/places.route')}
        />
      )}
    />
    <Route
      path="/home"
      component={() => (
        <Async provider={() => import('./components/Desctop/Home/home')} />
      )}
    />
    <Route
      path="/bikerbed"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import('./components/Desctop/BikerBed/bikerBed.container');
          }}
        />
      )}
    />
    <Route
      path="/club"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import('./components/Desctop/BikerClub/bikerClub.container');
          }}
        />
      )}
    />
    <Route
      path="/member/profile/:id"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import('./components/Desctop/UserProfile/user.container');
          }}
        />
      )}
    />
    <Route
      path="/faq"
      component={() => (
        <Async provider={() => import('./components/Desctop/FAQ/faq')} />
      )}
    />
    {/* <Route path="/pressrelease" component={ () => <Async provider={ () => import('./components/Desctop/PressRelease/press.container') }/> }/>
        <Route path="/inthepress" component={ () => <Async provider={ () => import('./components/Desctop/InThePress/inThePress.container') }/> }/> */}
    <Route
      path="/legalissues"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import('./components/Desctop/LegalIssues/legalIssues');
          }}
        />
      )}
    />
    {/* <Route path="/download" component={ () => <Async provider={ () => import('./components/Desctop/Download/download.container') }/> }/>
        <Route path="/advertise" component={ () => <Async provider={ () => import('./components/Desctop/Advertise/advertise.container') }/> }/>
        <Route path="/statement" component={ () => <Async provider={ () => import('./components/Desctop/Statement/statement.container') }/> }/>
        <Route path="/sitemap" component={ () => <Async provider={ () => import('./components/Desctop/SiteMap/sitemap.container') }/> }/>
        <Route path="/tournews" component={() => <Async provider={ () => import('./components/Desctop/TourNews/tournews.container') }/> }/> */}
    <Route
      path="/about"
      component={() => (
        <Async
          provider={() => {
            return import('./components/Desctop/AboutTourstart/abouttourstart');
          }}
        />
      )}
    />
    <Route
      path="/statement"
      component={() => (
        <Async
          provider={() => import('./components/Desctop/Statement/statement')}
        />
      )}
    />
    <Route
      path="/contact"
      component={() => (
        <Async
          provider={() => import('./components/Desctop/Contact/contact')}
        />
      )}
    />
    <Route
      path="/members"
      component={() => (
        <Async
          provider={() => {
            return import('./components/Desctop/Members/members.container');
          }}
        />
      )}
    />
    <Route
      path="/pro"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import(
              './components/Desctop/TourstartPro/tourstartpro.container'
            );
          }}
        />
      )}
    />
    <Route
      path="/payment"
      component={() => (
        <Async
          provider={() => {
            return import('./components/Desctop/Payment/payment.container');
          }}
        />
      )}
    />
    <Route
      path="/signup/:code"
      component={(props) => (
        <Async
          props={props}
          provider={() => {
            return import('./components/Desctop/Signup/signup.container');
          }}
        />
      )}
    />
    {/* <Route path="/renewpass/:code" component={ props => <Async  props={props} provider={ () => import('./components/Desctop/renewPassword/renewPassword') }/> }/> //moved to query params */}
    <Route
      path="/track"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Track/track.container')}
        />
      )}
    />
    <Route
      path="/tourembed"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/TourEmbed/tourembed')}
        />
      )}
    />
    {/* <Route path="/bookmarked" component={props => <Async props={props} provider={ () => import('./components/Desctop/Bookmarked/bookmarked.container')} />} />  */}
    <Route
      path="/apple/continue"
      component={(props) => (
        <Async
          props={props}
          provider={() => import('./components/Desctop/Apple/Apple.container')}
        />
      )}
    />
    <Route path="/tour/:id" />
    <Route path="/club/:id" />
    <Route path="/bikerbed/:id" />
    <Route path="/faq/:id" />
    <Route path="/touracademy">
      <Redirect to="/createtour?scope=academy" />
    </Route>
    <Route path="*">
      <Redirect to="/" />
    </Route>
  </Switch>
);

export default RoutesPath;
