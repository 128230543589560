const axios = require('axios');

const kebabCaseToCamel = str => str.replace( /((\-\w)|(\.\w))/g, (matches) => matches[1].toUpperCase());

const usedMethods = {
  aproxPoly: 'getAproxData',
  publicTours: 'getListPublicTours',
  newPublicTours: 'getNewestPublicTours',
  bestContent: 'getBestTourList',
  wlaClubPartners: 'wlaClubPartners',
  wlaBedPartners: 'wlaBedPartners',
  myTours: 'tour/mine_all',
  club: 'club',
  bikerbed: 'bikerbed'
}

class APIManager {
  constructor({ url }){
    this.url = url
    this.way = {}
  }
  /**
   * Create and store a single entity's way
   * @param {A entity Object} entity
   */
  createEntity(entity) {
    /**
     * If there is a - in the entity.name, then change it 
     * to camelCase. E.g 
     * ```
     * myApi.createEntity({ name : 'foo-bar'} OR { name : 'foo.bar'})
     * myApi.way.fooBar.getAll(...)
     */

    const name = kebabCaseToCamel(entity.name)
    this.way[name] = this.createBasicCRUDEndpoints(entity)
  }

  createEntities(arrayOfEntity) {
    arrayOfEntity.forEach(this.createEntity.bind(this))
  }
  /**
   * Create the basic way handlers for CRUD operations
   * @param {A entity Object} entity
   */
  createBasicCRUDEndpoints( { name } ) {
    let way = {}

    const resourceURL = `${this.url}/${name}`

    way.getAll = ( method, params={} ) => axios.get( `${resourceURL}.${usedMethods[method] || method}`, { params, withCredentials: true } )

    way.getOne = ( method, params={}) =>  axios.get( `${resourceURL}.${usedMethods[method] || method}`, { params, withCredentials: true } )

    way.create = (toCreate, config={}) =>  axios.post(resourceURL, toCreate, config)

    way.update = (toUpdate, config={}) => axios.put(`${resourceURL}/${toUpdate.id}`, toUpdate, config)

    way.delete = ({ id }, config={}) => axios.delete(`${resourceURL}/${id}`, config)

    return way

  }

}

const addWays =  api => {
  const waysList = [
    {name: 'tour'},
    {name: 'list'}
  ];
  api.createEntities( waysList );
  
  return api;
}

export const API = addWays( new APIManager( { url:process.env.API_URL+"/api" } ) );