import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import Routes from './router';
import './styles/main.css';

if (process.env.NODE_ENV !== 'production') {
  console.info('Looks like we are in development mode!');
}

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);
