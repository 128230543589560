import * as types from '../actions/action_types';
import { useTimestamp } from '../components/Utility/Service/keepTimestamp.service';

const [addToProgress, removeFromProgress] = useTimestamp();

export const addTimestampMiddleware = () => (next) => (action) => {
  const { type } = action;
  if (
    type === types.TOUR_FULL_SET_ACCESSILITY_TYPE ||
    type === types.TOUR_FULL_RESAVE
  ) {
    addToProgress();
    action.payload.timestamp = removeFromProgress();
    console.log('Middleware triggered:', action);
  }
  next(action);
};
