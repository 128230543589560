import * as types from '../actions/action_types';

const initialState = {
  fetching: false,
  data: {},
  isEditMode: false,
  error: '',
  point: {},
  category: '1',
  icon: '1',
  color: '1',
  publicTypeRequiredFiled: {
    title: true,
    points: true,
    img: true,
    desc: true,
  },
  wasJustImported: false,
  placeList: [],
  placesIdInCreate: [],
};

const placesData = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_PLACE_FULL_DATA_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_PLACE_FULL_DATA_REJECTED:
      return { ...state, fetching: false, error: action.payload };
    case types.FETCH_PLACE_FULL_DATA_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: { ...action.payload },
      };
    case types.PLACE_SET_EDIT_MODE:
      return { ...state, isEditMode: action.payload };
    case types.PLACE_FULL_DATA_ADD_OWNER:
      return { ...state, data: { ...state.data, user: action.payload } };
    case types.PLACE_SET_BOOKMARK:
      return { ...state, data: { ...state.data, bookmark: action.payload } };
    case types.PLACE_SET_ACCESSILITY_TYPE:
      return { ...state, data: { ...state.data, access: action.payload } };
    case types.PLACE_POINT_GET: {
      const { lat, lng, value } = action.payload;
      return { ...state, data: { ...state.data, address: value, lat, lng } };
    }
    case types.PLACE_SET_CATEGORY:
      return { ...state, category: action.payload };
    case types.PLACE_SET_ICON_TYPE:
      return { ...state, icon: action.payload };
    case types.PLACE_SET_ICON_COLOR:
      return { ...state, color: action.payload };
    case types.PLACE_UPDATE_IMAGE: {
      const { images, imgids } = action.payload;
      return {
        ...state,
        data: { ...state.data, images, imgids },
      };
    }
    case types.PLACE_SET_TITLE:
      return { ...state, data: { ...state.data, title: action.payload } };

    case types.PLACE_SET_DESCRIPTION: {
      return { ...state, data: { ...state.data, description: action.payload } };
    }

    case types.PLACE_INITIAL_STATE:
      return initialState;

    case types.USER_PLACE_AMOUNT_FULFILLED: {
      const amountPlace = action.payload;
      if (amountPlace > 0) {
        return {
          ...state,
          data: { ...state.data, title: `Place ${amountPlace}` },
        };
      }
      return state;
    }

    case types.PLACE_TYPE_REQUIRED_FILED:
      return { ...state, publicTypeRequiredFiled: action.payload };

    case types.PLACE_FULL_DATA_IMPORTED: {
      return {
        ...state,
        wasJustImported: true,
      };
    }

    case types.PLACE_FULL_SET_ACCESSILITY_TYPE: {
      const { newTypes } = action.payload;
      const { tourType, listCheckedFriends, listCheckedClubs, tourClubId } =
        newTypes;
      return {
        ...state,
        data: {
          ...state.data,
          access: tourType,
          listCheckedFriends,
          listCheckedClubs,
          tourClubId,
        },
      };
    }

    case types.FETCH_PLACE_LIST_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_PLACE_LIST_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    case types.FETCH_PLACE_LIST_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        placeList: action.payload,
      };

    case types.PLACE_ADD_ID_IN_CREATE_TOUR: {
      const id = action.payload;
      const { placesIdInCreate } = state;

      return {
        ...state,
        placesIdInCreate: [...placesIdInCreate, id],
      };
    }

    case types.PLACE_REMOVE_ID_IN_CREATE_TOUR: {
      const id = action.payload;
      const { placesIdInCreate } = state;

      return {
        ...state,
        placesIdInCreate: [...placesIdInCreate.filter((item) => item !== id)],
      };
    }

    default:
      return state;
  }
};
export default placesData;
