import React from 'react';
import _ from 'lodash';
import { ConstructorSingletoneMap } from '../../../libs/map/MainMap';
import withRightMenuText from '../../HOGs/rightMenuText';
import store from '../../../store';
import { setMapBoundsStrAndZoom } from '../../../actions/FullTour/full_tour_view.action';
import { withDispatch } from '../../HOGs/customeDispatch';

const getBoundLoc = ({ lat, lng }) => encodeURIComponent(`${lng}|${lat}`);

async function initMapService() {
  const serv = await import('./map.service');
  return serv.MapService;
}

class Map extends React.Component {
  componentDidMount() {
    this.mapController = new ConstructorSingletoneMap({
      ...this.props,
      contextMenu: { contextmenu: true, contextmenuItems: [] },
    });
    this.getMapService(this.props);

    /** Detect the initial map bounds */
    const bounds = this.mapController.getMap().getBounds();
    const url = [
      `northWest=${getBoundLoc(bounds.getNorthWest())}`,
      `southEast=${getBoundLoc(bounds.getSouthEast())}`,
    ];
    const position = {
      boundsUrl: url.join('&'),
      zoom: this.mapController.getMap().getZoom(),
    };

    store.dispatch(setMapBoundsStrAndZoom(position));
    /** */
  }

  getMapService(props) {
    return this.mapService
      ? this.mapService
      : initMapService().then(
          // eslint-disable-next-line
          (MapService) =>
            (this.mapService = new MapService(this.mapController, { ...props }))
        );
  }

  shouldComponentUpdate(nextProps) {
    if (this.mapService && this.mapController) {
      if (
        this.props.scope !== nextProps.scope ||
        nextProps.dataScope.editable !== this.props.dataScope.editable ||
        (nextProps.mapData && !_.isEqual(nextProps.mapData, this.props.mapData))
      ) {
        this.mapService.changeMapScope(nextProps);
      }
    }
    if (
      !nextProps.prevTour ||
      nextProps.prevTour.id !== this.props.prevTour.id
    ) {
      this.mapService.showPrevTourPolyOnMap(nextProps.prevTour);
    }

    if (
      !nextProps.prevData ||
      !nextProps.prevData.id ||
      nextProps.prevData.id !== this.props.prevData.id
    ) {
      if (nextProps.prevData && nextProps.prevData.id)
        this.mapService.selectPointOnTheMap(nextProps.prevData.id);
      if (this.props.prevData && this.props.prevData.id)
        this.mapService.unSelectMapIcon(this.props.prevData.id);
    }

    return false; // Always! Please, don't touch it.
  }

  componentWillUnmount() {
    this.mapService.unSubscribeAll();
    this.mapController.destroy();
  }

  render() {
    return <div className="TourFullmap" id="map" />;
  }
}
export default withDispatch(withRightMenuText(Map));
