export class MemoMapUtils {
  constructor() {
    this.cache = {};
    this.maxLength = 50;
  }

  useStableCoord(tmpKey, coord) {
    const data =
      this.cache[tmpKey] && this.cache[tmpKey].data
        ? this.cache[tmpKey].data
        : null;
    if (data) this.cache[tmpKey] = { data };
    else this.cache[tmpKey] = { coord, data };
  }

  observeLimit() {
    if (Object.keys(this.cache).length > this.maxLength) this.cache = {};
  }

  save(key, data) {
    this.observeLimit();
    const newKey =
      this.cache[key] && this.cache[key].coord ? this.cache[key].coord : key;
    delete this.cache[key];
    this.cache[newKey] = { data };
  }

  get(key) {
    return this.cache[key] && this.cache[key].data;
  }
}
