import axios from 'axios';
import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';
import { getOSRMErrorMessage } from 'app/components/Utility/utility';

export class axiosWithAbort {
  constructor() {
    this.sourceList = {};
  }

  canselPrevRequest(key) {
    if (this.isSourceExists(key))
      this.getSource(key).cancel('Operation canceled due to new request.');
  }

  get(key, url, callback) {
    if (key) {
      this.canselPrevRequest(key);
      // save the new request for cancellation
      this.setSource(key, axios.CancelToken);
    }
    axios
      .get(url, { cancelToken: this.getSource(key).token })
      .then(callback)
      .catch((thrown) => {
        if (axios.isCancel(thrown)) {
          const arr = thrown.message.split('_');
          const id = parseInt(arr[arr.length - 1]);
          if (id) {
            publish(way.AXIOS_BUILDER_CANCELED_POLY_ID, { id });
          }
          callback({ data: 'cancel' });
        } else {
          callback({ data: null });
          getOSRMErrorMessage();
          console.error(thrown);
        }
      });
  }

  isSourceExists(key) {
    if (!key) return false;
    const [topic, id] = key.split('_');
    return this.sourceList[topic] ? !!this.sourceList[topic][id] : false;
  }

  getSource(key) {
    if (!key) return false;
    const [topic, id] = key.split('_');
    return this.sourceList[topic] ? this.sourceList[topic][id] : null;
  }

  setSource(key, token) {
    const [topic, id] = key.split('_');
    if (!this.sourceList[topic]) {
      this.sourceList[topic] = {};
    }
    this.sourceList[topic][id] = token.source();
  }

  dropSource(key) {
    const [topic, id] = key.split('_');
    if (this.isSourceExists(key)) delete this.sourceList[topic][id];
  }

  destroySources(topic) {
    if (this.sourceList[topic]) {
      for (const id in this.sourceList[topic]) {
        if ({}.hasOwnProperty.call(this.sourceList[topic], id)) {
          this.cleanAbortList(`${topic}_${id}`);
        }
      }
    }
  }

  cleanAbortList(key) {
    // if( !axios.isCancel() && this.isSourceExists( key ) ){
    if (this.isSourceExists(key)) {
      this.getSource(key).cancel(`Request was canceled ${key}`);
      this.dropSource(key);
    } else
      console.log(
        ` Source list was not clean ${this.sourceList} because key ${key} was not finish loading after canceling!`
      );
  }
}
