import * as types from '../actions/action_types';

export function folders(state = [], { type, payload }) {
  switch (type) {
    case types.FOLDERS_GET:
      return payload.sort((a, b) => b.order_id - a.order_id);

    case types.FOLDERS_ADD:
      return [payload, ...state];

    case types.FOLDERS_REMOVE:
      return state.filter(({ id }) => id !== payload.id);

    case types.FOLDERS_UPDATE:
      return state.map((folder) => {
        if (folder.id === payload.id) {
          return {
            ...payload,
          };
        }
        return folder;
      });

    case types.FOLDERS_UPDATE_ALL: {
      const updatedFoldersId = payload.map(({ id }) => id);
      return state
        .filter(({ id }) => !updatedFoldersId.includes(id))
        .concat(payload)
        .sort((a, b) => b.order_id - a.order_id);
    }

    case types.FOLDERS_RESORT: {
      const folders = state.map(({ order_id, ...rest }) => rest);
      const { prev, next } = payload;
      const [draggable] = state.filter((folder) => folder.id === prev);
      const draggableIndex = state.indexOf(draggable);
      const [nextFolder] = state.filter((folder) => folder.id === next);
      const nextIndex = state.indexOf(nextFolder);

      if (draggable.id === nextFolder.id) {
        return state;
      }

      folders.splice(nextIndex, 0, ...folders.splice(draggableIndex, 1));
      const folderLength = folders.length;
      return folders.map((folder, index) => ({
        ...folder,
        order_id: folderLength - index,
      }));
    }

    default:
      return state;
  }
}

export function foldersPlace(state = [], { type, payload }) {
  switch (type) {
    case types.FOLDERS_PLACE_GET:
      return payload.sort((a, b) => b.order_id - a.order_id);

    case types.FOLDERS_PLACE_ADD:
      return [payload, ...state];

    case types.FOLDERS_PLACE_REMOVE:
      return state.filter(({ id }) => id !== payload.id);

    case types.FOLDERS_PLACE_UPDATE:
      return state.map((folder) => {
        if (folder.id === payload.id) {
          return {
            ...payload,
          };
        }
        return folder;
      });

    case types.FOLDERS_PLACE_UPDATE_ALL: {
      const updatedFoldersId = payload.map(({ id }) => id);
      return state
        .filter(({ id }) => !updatedFoldersId.includes(id))
        .concat(payload)
        .sort((a, b) => b.order_id - a.order_id);
    }

    case types.FOLDERS_PLACE_RESORT: {
      const folders = state.map(({ order_id, ...rest }) => rest);
      const { prev, next } = payload;
      const [draggable] = state.filter((folder) => folder.id === prev);
      const draggableIndex = state.indexOf(draggable);
      const [nextFolder] = state.filter((folder) => folder.id === next);
      const nextIndex = state.indexOf(nextFolder);

      if (draggable.id === nextFolder.id) {
        return state;
      }

      folders.splice(nextIndex, 0, ...folders.splice(draggableIndex, 1));
      const folderLength = folders.length;
      return folders.map((folder, index) => ({
        ...folder,
        order_id: folderLength - index,
      }));
    }

    default:
      return state;
  }
}
