import { isWrongCoord } from 'app/components/Utility/utility';
import * as types from '../actions/action_types';
import tourPrevAPI from '../API/tour.prev.api';

export const optimizeSaveTour = () => (next) => (action) => {
  const { type, payload = {} } = action;
  // const {data={}} = payload;
  const { parts = [] } = payload;
  const optimizedPoints = {};
  if (type === types.TOUR_FULL_OPTIMIZE_SAVE && parts.length > 1) {
    parts.forEach(({ points = [] }) => {
      const len = points.length;
      let prevPoint = null;
      let nextPoint = null;
      for (let i = 0; i < len; i++) {
        const point = points[i];
        if (isWrongCoord(point)) {
          const { correctLat, correctLng } = point;
          point.lat = correctLat;
          point.lng = correctLng;
          point.keppCurrentCoord = 0;
          point.correctLat = null;
          point.correctLng = null;
          if (prevPoint) {
            const { lat, lng } = prevPoint;
            point.prevCoordStr = `${lng},${lat}`;
            optimizedPoints[prevPoint.id] = prevPoint;
          }
          nextPoint = points[i + 1];
          if (nextPoint) {
            nextPoint.prevCoordStr = `${point.lng},${point.lat}`;
            optimizedPoints[nextPoint.id] = nextPoint;
          }
          optimizedPoints[point.id] = point;
        }
        prevPoint = point;
      }
    });
    action.payload.parts = parts;
    if (Object.keys(optimizedPoints).length > 0) {
      tourPrevAPI.saveTourAfterOptimization({
        points: Object.values(optimizedPoints),
      });
    }
  }

  next(action);
};
