export const userEnterState = {
  DISPLAY: 'DISPLAY',
  CHECKING_EMAIL: 'CHECKING_EMAIL',
  PASSWD: 'PASSWD',
  LOADING: 'LOADING',
};

export const PASSWORD = 'passwd';
export const FORGOT_PASSWORD = 'forgotPasswd';
export const SUGGESTION = 'suggestion';
export const CHECKING_EMAIL = 'checkingEmail';
export const LETTER_SENT = 'letterSent';
export const REGISTER = 'register';
export const REGISTER_SOCIAL = 'registerSocial';
export const START_LOGIN = 'start';
export const ACCEPT_LEGAL_ISSUES = 'acceptLegalIssues';
export const USER_NOT_REGISTERED = 'USER_NOT_REGISTERED';
