import { getCustomId } from 'app/libs/core/Unique';

let progressMarkers = [];
const getProgressMarkers = () => progressMarkers;
const addProgressMarker = (pm) => progressMarkers.push(pm);
const removeProgressMarker = (i) => progressMarkers.splice(i, 1);
const isPrograssCompleted = () => progressMarkers.length === 0;
const clearProgressList = () => {
  progressMarkers = [];
};

const getTimestamp = () => {
  const ts = getCustomId();
  addProgressMarker(ts);
  return ts;
};

export const progressQueueService = {
  getTimestamp,
  getProgressMarkers,
  isPrograssCompleted,
  removeProgressMarker,
  clearProgressList,
};
