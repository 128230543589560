import * as types from '../action_types';
import { userConstants } from '../../constants/user.constants';

export const getCurrentLocationUser = (data) => {
  return {
    type: types.GET_CURRENT_LOCATION_USER,
    payload: data,
  };
};

export const setHomeAddressUser = (data) => {
  return {
    type: types.SET_HOME_ADDRESS_USER,
    payload: data,
  };
};

export const setWorkAddressUser = (data) => {
  return {
    type: types.SET_WORK_ADDRESS_USER,
    payload: data,
  };
};

export const setLastOpenedObject = (data) => {
  return {
    type: types.SET_LAST_OPENED_OBJECT,
    payload: data,
  };
};

export const updateAuthenticationUserData = (payload) => {
  return {
    type: userConstants.UPDATE_USER_DATA,
    payload,
  };
};

//
export const setUserDataPictures = (payload) => {
  return {
    type: types.SET_USER_PICTURES_DATA,
    payload,
  };
};

export const changeUserFilterOpenState = (payload) => {
  return {
    type: types.USER_FILTER_OPEN_STATE,
    payload,
  };
};

export const changeShowUserHome = (payload) => {
  return {
    type: types.USER_CHANGE_SHOW_HOME,
    payload,
  };
};

export const changeShowUserWork = (payload) => {
  return {
    type: types.USER_CHANGE_SHOW_WORK,
    payload,
  };
};

export const changeUserMainData = (payload) => {
  return {
    type: types.USER_CHANGE_MAIN_DATA,
    payload,
  };
};
export const setUserMediaData = (payload) => {
  return {
    type: types.USER_SET_MEDIA_DATA,
    payload,
  };
};

export const setUserPrivacy = (data, first) => {
  return {
    type: types.USER_SET_PRIVACY_SETTINGS,
    payload: { data, first },
  };
};

export const setUserNotification = (data, first) => {
  return {
    type: types.USER_SET_NOTIFICATION_OPTIONS,
    payload: { data, first },
  };
};
