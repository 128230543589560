import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import { loadingBarReducer } from 'react-redux-loading-bar';
import metaTagsData from './meta-tag.reducer';
import userReducer from './user-reducer';
// import mapReducer from './map-reducer';
import mapDataReducer from './mapdata.reducer';
import mapScopeReducer from './map.scope.reducer';
import mapPostition from './mapposition.reducer';
import fetchPreviewData from './fetch-prev-data.reducer';
import fetchPreviewPoly from './fetch-prev-polyline.reducer';
import tourstartProData from './tourstart-pro.reducer';
import loginData from './login.reducer';
import tourFullData from './full-tour-view.reducer';
import tourFullPolyline from './full-tour-polyline.reducer';
import searchBarData from './search-bar.reducer';
import mainMenuData from './main-menu.reducer';
import languageData from './language.reducer';
import clubFullDataReducer from './full-club-view.reducer';
import placesData from './places.reducer';
import driveDataReducer from './drive-data-reducer';
import tourDownload from './download-tour.reducer';
import { mainAlert } from './alert.reducer';
import { authentication } from './authentication.user';
import { userEnter } from './enter.user.reducer';
import cssEffect from './blure.content.under.modal.mask.reducer';
import { locationUrl } from './locationUrl.reducer';
import userHistoryReducer from './user-history.reducer';
import { folders, foldersPlace } from './folders.reduser';
import { counter } from './counter.reducer';

const reducers = (history) =>
  combineReducers({
    metaTagsData,
    placesData,
    languageData,
    userReducer,
    userHistoryReducer,
    // mapState: mapReducer,
    mapDataReducer,
    mapPostition,
    fetchPreviewData,
    fetchPreviewPoly,
    tourstartProData,
    loginData,
    tourFullData,
    tourFullPolyline,
    searchBarData,
    driveDataReducer,
    tourDownload,
    mainMenuData,
    mapScopeReducer,
    mainAlert,
    authentication,
    userEnter,
    loadingBar: loadingBarReducer,
    cssEffect,
    clubFullDataReducer,
    locationUrl,
    router: connectRouter(history),
    folders,
    foldersPlace,
    counter,
  });

export default reducers;
