import Cookies from 'js-cookie';
import * as types from '../actions/action_types';
import {
  userHistory,
  USER_LANGUAGE_BROWSER_COOKIE_NAME,
} from '../constants/user.constants';
import userAPI from '../API/user.service.api';
import guestIdentService from '../components/Utility/guestIdent.service';

const guestIdent = guestIdentService();

export const setUserLanguage =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { type, payload } = action;
    const { authentication = {} } = getState();
    const { isLoggedIn, user = {} } = authentication;
    const userId = isLoggedIn ? user.id : guestIdent.get();

    if (type === types.LANGUAGE_DATA_CHANGE_ACTIVE) {
      userAPI.setHistorySelectedData(
        userId,
        userHistory.USER_LANGUAGE,
        payload
      );
      Cookies.set(USER_LANGUAGE_BROWSER_COOKIE_NAME, payload, {
        expires: 365,
        path: '/',
        domain: process.env.COOKIE_HOST,
      });
    }

    next(action);
  };
