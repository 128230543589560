import * as types from "../actions/action_types";
import json from "../components/Desctop/TourPreview/previewJson";

const initialState = {
  fetching: false,
  fetched: false,
  points: [],
  poly: "",
  error: null
};

const tourFullPolyline = function(state = initialState, action) {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return initialState;
    case types.TOUR_FULL_DATA_POLYLINE_PENDING:
      return { ...initialState, fetching: true };
    case types.TOUR_FULL_DATA_POLYLINE_REJECTED:
      if (process.env.NODE_ENV !== "production") {
        var points = [
          {
            point_id: 32847221,
            lat: "56.299185",
            lng: "9.930217",
            type: "start",
            encode_path: ""
            },
            {
            point_id: 32847222,
            lat: "56.264301",
            lng: "10.033614",
            type: "via",
            },
            {
            point_id: 32847223,
            lat: "56.217628",
            lng: "10.025263",
            type: "drag",
            },
            {
            point_id: 32847224,
            lat: "56.129416",
            lng: "10.012415",
            type: "via",
            },
            {
            point_id: 32847225,
            lat: "56.111939",
            lng: "10.026235",
            type: "via",
            },
            {
            point_id: 32847226,
            lat: "56.0596",
            lng: "10.118211",
            type: "via",
            },
            {
            point_id: 32847227,
            lat: "56.041574",
            lng: "10.168885",
            type: "via",
            },
            {
            point_id: 32847228,
            lat: "56.022031",
            lng: "10.261377",
            type: "via",
            },
            {
            point_id: 32847229,
            lat: "56.001456",
            lng: "10.260339",
            type: "via",
            },
            {
            point_id: 32847230,
            lat: "55.914203",
            lng: "10.253698",
            type: "via",
            },
            {
            point_id: 32847231,
            lat: "55.891696",
            lng: "10.127613",
            type: "drag",
            },
            {
            point_id: 32847232,
            lat: "55.902554",
            lng: "10.062479",
            type: "drag",
            },
            {
            point_id: 32847233,
            lat: "55.997405",
            lng: "9.84056",
            type: "drag",
            },
            {
            point_id: 32847234,
            lat: "56.047371",
            lng: "9.850943",
            type: "drag",
            },
            {
            point_id: 32847235,
            lat: "56.120226",
            lng: "9.861801",
            type: "via",
            },
            {
            point_id: 32847236,
            lat: "56.279749",
            lng: "9.922053",
            type: "via",
            },
            {
            point_id: 32847237,
            lat: "56.299416",
            lng: "9.928031",
            type: "end",
            }
          ];
        var poly = [
          "9.93022 56.29919",
          "9.93156 56.2991",
          "9.93267 56.29886",
          "9.93377 56.29866",
          "9.94287 56.29686",
          "9.95577 56.29454",
          "9.95666 56.29429",
          "9.95787 56.29388",
          "9.9586 56.29354",
          "9.9591 56.29332",
          "9.95994 56.29316",
          "9.96298 56.29279",
          "9.96406 56.29266",
          "9.96538 56.2926",
          "9.96739 56.29257",
          "9.96816 56.29252",
          "9.96981 56.29229",
          "9.97106 56.29234",
          "9.97276 56.29212",
          "9.97372 56.2921",
          "9.97517 56.29224",
          "9.97634 56.29207",
          "9.97754 56.29176",
          "9.97885 56.29155",
          "9.98158 56.29131",
          "9.98302 56.29105",
          "9.98418 56.29126",
          "9.98517 56.29142",
          "9.98716 56.29177",
          "9.98785 56.29188",
          "9.98862 56.29197",
          "9.98943 56.29203",
          "9.99047 56.2921",
          "9.99202 56.29213",
          "9.99304 56.29215",
          "9.99435 56.29211",
          "9.9953 56.29202",
          "9.99669 56.29186",
          "9.99918 56.29156",
          "10.00018 56.29139",
          "10.00123 56.29129",
          "10.00255 56.29133",
          "10.00377 56.29123",
          "10.00487 56.29086",
          "10.00548 56.29056",
          "10.00596 56.29034",
          "10.00651 56.29003",
          "10.00708 56.2897",
          "10.00824 56.28903",
          "10.01087 56.28812",
          "10.01202 56.28747",
          "10.0127 56.28687",
          "10.01349 56.28645",
          "10.01595 56.28572",
          "10.0182 56.28512",
          "10.0194 56.28479",
          "10.02344 56.2837",
          "10.02455 56.28336",
          "10.02605 56.28288",
          "10.03378 56.28042",
          "10.03456 56.28023",
          "10.03573 56.28017",
          "10.03643 56.28015",
          "10.03739 56.28001",
          "10.03824 56.27973",
          "10.03915 56.27953",
          "10.04039 56.27938",
          "10.04107 56.27933",
          "10.04119 56.27918",
          "10.04086 56.27887",
          "10.04017 56.27825",
          "10.03954 56.27763",
          "10.03906 56.27714",
          "10.03876 56.27684",
          "10.03773 56.27578",
          "10.03735 56.2754",
          "10.03616 56.27419",
          "10.03479 56.27226",
          "10.03438 56.2713",
          "10.03434 56.27043",
          "10.03434 56.27008",
          "10.03393 56.26893",
          "10.03359 56.26809",
          "10.03347 56.26774",
          "10.0334 56.26728",
          "10.03343 56.2666",
          "10.03345 56.26622",
          "10.03348 56.26514",
          "10.03352 56.26481",
          "10.03361 56.2643",
          "10.03361 56.2643",
          "10.03377 56.26348",
          "10.03374 56.26302",
          "10.03284 56.26086",
          "10.03186 56.25896",
          "10.03148 56.25827",
          "10.03092 56.25689",
          "10.0307 56.25631",
          "10.03079 56.25569",
          "10.0318 56.25321",
          "10.03205 56.2523",
          "10.0318 56.25178",
          "10.03136 56.25141",
          "10.02959 56.25033",
          "10.02881 56.24974",
          "10.02844 56.24933",
          "10.02815 56.24898",
          "10.02808 56.24849",
          "10.02804 56.24804",
          "10.02773 56.24464",
          "10.02773 56.24378",
          "10.02781 56.24318",
          "10.02815 56.24164",
          "10.02824 56.24109",
          "10.02822 56.2403",
          "10.0282 56.23973",
          "10.02815 56.23911",
          "10.02807 56.23856",
          "10.02815 56.23811",
          "10.02884 56.23634",
          "10.02896 56.23569",
          "10.02891 56.23498",
          "10.02888 56.23453",
          "10.02882 56.23413",
          "10.02863 56.23312",
          "10.0285 56.23273",
          "10.02803 56.23131",
          "10.02792 56.23071",
          "10.02799 56.23008",
          "10.02828 56.22919",
          "10.02863 56.22827",
          "10.02934 56.2263",
          "10.02967 56.22535",
          "10.02975 56.22479",
          "10.0297 56.22446",
          "10.02941 56.22394",
          "10.02805 56.22185",
          "10.0273 56.22074",
          "10.02526 56.21763",
          "10.02526 56.21763",
          "10.0242 56.21599",
          "10.02375 56.21521",
          "10.02346 56.21445",
          "10.02291 56.21301",
          "10.02246 56.212",
          "10.02219 56.21169",
          "10.02187 56.2114",
          "10.02136 56.21096",
          "10.02096 56.21056",
          "10.02047 56.21009",
          "10.02002 56.20936",
          "10.01953 56.20847",
          "10.01882 56.20727",
          "10.01851 56.2067",
          "10.01829 56.20623",
          "10.01744 56.20422",
          "10.01708 56.20336",
          "10.01647 56.20185",
          "10.01634 56.20151",
          "10.0159 56.20059",
          "10.01423 56.19774",
          "10.01402 56.19741",
          "10.01381 56.1971",
          "10.01317 56.19655",
          "10.01151 56.19571",
          "10.011 56.19546",
          "10.0105 56.19516",
          "10.01009 56.19484",
          "10.00976 56.19453",
          "10.00945 56.19413",
          "10.00855 56.19265",
          "10.0084 56.19238",
          "10.00498 56.18676",
          "10.00433 56.18567",
          "10.00393 56.18492",
          "10.00343 56.18384",
          "10.00326 56.18345",
          "10.0031 56.18298",
          "10.00271 56.18174",
          "10.00248 56.1808",
          "10.00228 56.17961",
          "10.00219 56.17853",
          "10.00216 56.17756",
          "10.00219 56.17663",
          "10.00234 56.17535",
          "10.00268 56.17373",
          "10.003 56.17269",
          "10.00348 56.17132",
          "10.00518 56.16648",
          "10.00544 56.16564",
          "10.00565 56.16487",
          "10.00584 56.164",
          "10.00609 56.16263",
          "10.00624 56.16103",
          "10.00639 56.15881",
          "10.00647 56.15764",
          "10.00662 56.15539",
          "10.00659 56.15438",
          "10.00638 56.15418",
          "10.0066 56.15404",
          "10.00675 56.15358",
          "10.00679 56.15312",
          "10.00686 56.15195",
          "10.00664 56.15181",
          "10.00681 56.15169",
          "10.00697 56.15103",
          "10.00707 56.14984",
          "10.00709 56.14931",
          "10.00704 56.14872",
          "10.00697 56.14821",
          "10.00683 56.14771",
          "10.00664 56.14723",
          "10.00629 56.14653",
          "10.00589 56.14593",
          "10.00556 56.14551",
          "10.00254 56.14219",
          "10.00192 56.14148",
          "10.00157 56.14107",
          "10.00122 56.14054",
          "10.00036 56.13888",
          "10.00007 56.13829",
          "9.99973 56.13772",
          "9.99928 56.13688",
          "9.99943 56.13685",
          "10.00154 56.13515",
          "10.00203 56.13482",
          "10.00269 56.13455",
          "10.00334 56.1344",
          "10.00414 56.13433",
          "10.00742 56.13438",
          "10.00817 56.13426",
          "10.00886 56.1339",
          "10.00942 56.13344",
          "10.01017 56.13318",
          "10.00995 56.13271",
          "10.01063 56.13127",
          "10.01083 56.13096",
          "10.01146 56.13062",
          "10.01205 56.13036",
          "10.01244 56.1299",
          "10.01242 56.12942",
          "10.01242 56.12942",
          "10.01139 56.128",
          "10.01125 56.12767",
          "10.0118 56.12743",
          "10.01251 56.12707",
          "10.0141 56.12724",
          "10.01689 56.12352",
          "10.0163 56.12301",
          "10.016 56.12263",
          "10.01613 56.12207",
          "10.01638 56.12162",
          "10.01725 56.12053",
          "10.01762 56.12006",
          "10.01791 56.11962",
          "10.01969 56.117",
          "10.0206 56.11564",
          "10.0211 56.11545",
          "10.02255 56.11533",
          "10.02474 56.11514",
          "10.02552 56.11488",
          "10.02624 56.11194",
          "10.02624 56.11194",
          "10.02691 56.10915",
          "10.02866 56.10902",
          "10.02929 56.10899",
          "10.03082 56.10901",
          "10.03212 56.10904",
          "10.03306 56.10907",
          "10.03411 56.10907",
          "10.03525 56.10906",
          "10.03594 56.10906",
          "10.03715 56.10913",
          "10.03841 56.10925",
          "10.03975 56.10925",
          "10.04066 56.1092",
          "10.04133 56.10916",
          "10.04312 56.10904",
          "10.04382 56.109",
          "10.04444 56.10899",
          "10.04677 56.10893",
          "10.04763 56.10892",
          "10.04911 56.10891",
          "10.0501 56.10884",
          "10.0508 56.10872",
          "10.05225 56.10833",
          "10.05311 56.10799",
          "10.05359 56.10777",
          "10.05499 56.10716",
          "10.05548 56.10693",
          "10.0563 56.10646",
          "10.05697 56.10599",
          "10.05734 56.10568",
          "10.05769 56.10534",
          "10.06131 56.10095",
          "10.0617 56.10044",
          "10.06229 56.09989",
          "10.06311 56.09954",
          "10.06393 56.09937",
          "10.0647 56.09927",
          "10.06535 56.09918",
          "10.0662 56.09903",
          "10.06711 56.09866",
          "10.0679 56.09816",
          "10.06841 56.09781",
          "10.06915 56.09724",
          "10.06985 56.0967",
          "10.07025 56.09644",
          "10.07073 56.09617",
          "10.07123 56.09589",
          "10.07177 56.09539",
          "10.07238 56.09483",
          "10.07315 56.09432",
          "10.07341 56.09401",
          "10.07357 56.09363",
          "10.07373 56.0933",
          "10.07418 56.09257",
          "10.07491 56.09194",
          "10.07543 56.09132",
          "10.07732 56.08911",
          "10.07759 56.08879",
          "10.07804 56.08826",
          "10.07842 56.08782",
          "10.0787 56.08748",
          "10.08016 56.08575",
          "10.08075 56.08503",
          "10.08142 56.08423",
          "10.08227 56.08319",
          "10.08312 56.08219",
          "10.08415 56.08098",
          "10.08441 56.08067",
          "10.08582 56.07906",
          "10.08648 56.07824",
          "10.08814 56.07621",
          "10.08861 56.07568",
          "10.08916 56.07525",
          "10.09027 56.07447",
          "10.09122 56.07383",
          "10.09216 56.07327",
          "10.09447 56.07212",
          "10.09713 56.07079",
          "10.09703 56.06939",
          "10.09717 56.06868",
          "10.09734 56.0685",
          "10.09803 56.06805",
          "10.09869 56.06781",
          "10.10563 56.06663",
          "10.10588 56.06533",
          "10.10656 56.06509",
          "10.1066 56.06447",
          "10.10672 56.06404",
          "10.10665 56.06358",
          "10.10627 56.06312",
          "10.10688 56.06279",
          "10.10767 56.06266",
          "10.1083 56.06261",
          "10.10945 56.06257",
          "10.11346 56.06116",
          "10.11453 56.06073",
          "10.11539 56.06023",
          "10.11649 56.05997",
          "10.11763 56.05969",
          "10.11821 56.0596",
          "10.11821 56.0596",
          "10.12045 56.05906",
          "10.12217 56.05863",
          "10.12307 56.05837",
          "10.12522 56.05785",
          "10.12604 56.05756",
          "10.12646 56.05728",
          "10.1268 56.05662",
          "10.12778 56.0561",
          "10.13277 56.05361",
          "10.12993 56.05154",
          "10.1312 56.05111",
          "10.13233 56.05076",
          "10.13342 56.05054",
          "10.13501 56.05017",
          "10.13554 56.04997",
          "10.13633 56.04951",
          "10.13696 56.04926",
          "10.13754 56.04912",
          "10.13851 56.04894",
          "10.1395 56.04888",
          "10.1411 56.04895",
          "10.1417 56.04892",
          "10.14239 56.04882",
          "10.14496 56.04846",
          "10.14611 56.04803",
          "10.14713 56.04775",
          "10.14815 56.04748",
          "10.14878 56.04742",
          "10.15046 56.04776",
          "10.15157 56.04769",
          "10.15252 56.04745",
          "10.15379 56.04721",
          "10.15504 56.04678",
          "10.15665 56.04589",
          "10.15826 56.04508",
          "10.15985 56.04417",
          "10.16044 56.04397",
          "10.16127 56.04384",
          "10.16206 56.04373",
          "10.16296 56.04359",
          "10.16418 56.04326",
          "10.16565 56.04274",
          "10.16656 56.04241",
          "10.16888 56.04157",
          "10.16888 56.04157",
          "10.16984 56.04123",
          "10.17127 56.04073",
          "10.17258 56.04027",
          "10.17333 56.0401",
          "10.17532 56.03988",
          "10.17747 56.03963",
          "10.17837 56.03955",
          "10.17939 56.03951",
          "10.18299 56.03954",
          "10.18386 56.03952",
          "10.18455 56.03938",
          "10.18494 56.03991",
          "10.18594 56.03977",
          "10.18709 56.03983",
          "10.18776 56.0399",
          "10.1884 56.03996",
          "10.18935 56.04004",
          "10.19064 56.04006",
          "10.19167 56.0401",
          "10.19275 56.04025",
          "10.1936 56.04025",
          "10.19351 56.0393",
          "10.19505 56.03921",
          "10.1955 56.03849",
          "10.19604 56.03834",
          "10.19768 56.03799",
          "10.19905 56.03796",
          "10.19987 56.038",
          "10.20446 56.03818",
          "10.2054 56.03822",
          "10.20648 56.03827",
          "10.20792 56.03832",
          "10.2093 56.0384",
          "10.21346 56.03905",
          "10.2144 56.03918",
          "10.21752 56.03965",
          "10.21816 56.0397",
          "10.22612 56.04005",
          "10.22785 56.0401",
          "10.22853 56.04007",
          "10.2369 56.03951",
          "10.23704 56.03939",
          "10.23662 56.03863",
          "10.2366 56.03809",
          "10.23614 56.03743",
          "10.236 56.03706",
          "10.23691 56.03668",
          "10.23768 56.03657",
          "10.23844 56.03648",
          "10.23912 56.03635",
          "10.24001 56.03605",
          "10.23963 56.03568",
          "10.2393 56.03534",
          "10.23883 56.03484",
          "10.23818 56.0342",
          "10.23797 56.03383",
          "10.23788 56.03347",
          "10.2378 56.0331",
          "10.23758 56.0326",
          "10.23771 56.03221",
          "10.23881 56.03149",
          "10.24012 56.03128",
          "10.24054 56.031",
          "10.24047 56.03038",
          "10.24041 56.0298",
          "10.24086 56.02957",
          "10.24223 56.02959",
          "10.24641 56.02961",
          "10.24937 56.02962",
          "10.25267 56.02963",
          "10.25505 56.02965",
          "10.25592 56.02967",
          "10.25655 56.0297",
          "10.25783 56.02969",
          "10.25858 56.02906",
          "10.25923 56.02858",
          "10.25986 56.02797",
          "10.26029 56.02716",
          "10.26062 56.02661",
          "10.26091 56.02601",
          "10.26099 56.02552",
          "10.26102 56.0249",
          "10.26085 56.02423",
          "10.26076 56.02362",
          "10.26079 56.02309",
          "10.26096 56.0223",
          "10.26104 56.02196",
          "10.26138 56.02203",
          "10.26138 56.02203",
          "10.26104 56.02196",
          "10.26184 56.01865",
          "10.26198 56.01804",
          "10.26202 56.01754",
          "10.2619 56.01668",
          "10.26176 56.01627",
          "10.26153 56.0158",
          "10.261 56.01512",
          "10.26036 56.01451",
          "10.25955 56.01387",
          "10.25845 56.013",
          "10.25784 56.01232",
          "10.25743 56.01168",
          "10.25718 56.01104",
          "10.25709 56.01041",
          "10.25708 56.01006",
          "10.2572 56.0094",
          "10.25729 56.00905",
          "10.25748 56.00867",
          "10.25777 56.00822",
          "10.2581 56.00777",
          "10.26047 56.00488",
          "10.26092 56.00392",
          "10.26102 56.00343",
          "10.26101 56.00304",
          "10.26095 56.00269",
          "10.26084 56.00233",
          "10.26066 56.00193",
          "10.26034 56.00146",
          "10.26034 56.00146",
          "10.25968 56.00077",
          "10.2593 56.00048",
          "10.2581 55.99969",
          "10.25508 55.99769",
          "10.25447 55.99728",
          "10.25404 55.99694",
          "10.25323 55.99614",
          "10.25279 55.99571",
          "10.25238 55.99524",
          "10.25194 55.99462",
          "10.25164 55.9941",
          "10.25135 55.99355",
          "10.25118 55.9931",
          "10.25102 55.99232",
          "10.25096 55.99155",
          "10.25093 55.99013",
          "10.25092 55.98908",
          "10.25092 55.98822",
          "10.2509 55.9873",
          "10.25089 55.9864",
          "10.25087 55.98543",
          "10.25085 55.98338",
          "10.2508 55.98291",
          "10.25063 55.98228",
          "10.25043 55.98186",
          "10.25021 55.98142",
          "10.24985 55.98088",
          "10.2493 55.98023",
          "10.24855 55.97956",
          "10.24808 55.97919",
          "10.24725 55.97867",
          "10.24607 55.97804",
          "10.24159 55.9758",
          "10.24084 55.97546",
          "10.24023 55.97525",
          "10.23957 55.97506",
          "10.23877 55.97489",
          "10.23802 55.97478",
          "10.2368 55.97469",
          "10.23577 55.97468",
          "10.23365 55.97464",
          "10.23376 55.97426",
          "10.23408 55.97357",
          "10.23419 55.97271",
          "10.23422 55.97132",
          "10.23432 55.9691",
          "10.23431 55.96874",
          "10.23425 55.96838",
          "10.23412 55.968",
          "10.23394 55.96764",
          "10.23361 55.96715",
          "10.23301 55.96636",
          "10.23259 55.96568",
          "10.23247 55.96534",
          "10.23235 55.96491",
          "10.23232 55.96456",
          "10.23233 55.9642",
          "10.23242 55.96376",
          "10.23261 55.96333",
          "10.23371 55.9611",
          "10.23397 55.96072",
          "10.23575 55.95855",
          "10.23624 55.95785",
          "10.2365 55.95727",
          "10.23665 55.9568",
          "10.23671 55.95641",
          "10.23676 55.95573",
          "10.2369 55.95211",
          "10.23692 55.95137",
          "10.23689 55.95098",
          "10.23682 55.95061",
          "10.23671 55.95021",
          "10.23601 55.94867",
          "10.235 55.94648",
          "10.23478 55.9458",
          "10.23474 55.94545",
          "10.23473 55.9451",
          "10.23478 55.94472",
          "10.23564 55.94128",
          "10.236 55.93979",
          "10.23599 55.93924",
          "10.23592 55.93883",
          "10.23571 55.93794",
          "10.2356 55.93746",
          "10.23559 55.93667",
          "10.23594 55.93281",
          "10.23609 55.93245",
          "10.23727 55.93065",
          "10.23714 55.92991",
          "10.23654 55.92945",
          "10.2372 55.92909",
          "10.23806 55.9286",
          "10.23894 55.92811",
          "10.24014 55.92736",
          "10.24156 55.92642",
          "10.24256 55.9257",
          "10.24356 55.92491",
          "10.24425 55.92431",
          "10.24527 55.92336",
          "10.24608 55.9226",
          "10.24665 55.92202",
          "10.24755 55.92103",
          "10.2482 55.92023",
          "10.24889 55.91945",
          "10.24927 55.91904",
          "10.2499 55.91839",
          "10.25222 55.91579",
          "10.25264 55.91522",
          "10.25318 55.91462",
          "10.2537 55.91404",
          "10.2542 55.91356",
          "10.2537 55.9142",
          "10.2537 55.9142",
          "10.25329 55.91466",
          "10.25194 55.91429",
          "10.25098 55.91392",
          "10.24981 55.91349",
          "10.24921 55.91356",
          "10.2485 55.91365",
          "10.24721 55.9138",
          "10.24248 55.91435",
          "10.23814 55.91437",
          "10.23422 55.9145",
          "10.2343 55.91407",
          "10.23461 55.91349",
          "10.23412 55.913",
          "10.23299 55.91297",
          "10.22915 55.91022",
          "10.22787 55.90962",
          "10.22698 55.90924",
          "10.22551 55.90784",
          "10.22297 55.90619",
          "10.22238 55.90604",
          "10.22119 55.90609",
          "10.21391 55.90642",
          "10.20795 55.90148",
          "10.20755 55.90112",
          "10.20558 55.89879",
          "10.20533 55.89784",
          "10.20486 55.89753",
          "10.2039 55.89732",
          "10.20295 55.89725",
          "10.20091 55.89744",
          "10.20056 55.89698",
          "10.20029 55.89661",
          "10.19941 55.89564",
          "10.19848 55.89462",
          "10.1978 55.89428",
          "10.19522 55.8937",
          "10.19424 55.89325",
          "10.19017 55.89111",
          "10.18932 55.89077",
          "10.18856 55.89069",
          "10.18514 55.89083",
          "10.18292 55.89093",
          "10.18022 55.89105",
          "10.17639 55.8912",
          "10.17522 55.89126",
          "10.17367 55.89132",
          "10.17143 55.89145",
          "10.17042 55.89181",
          "10.16849 55.89211",
          "10.16729 55.89226",
          "10.16732 55.89183",
          "10.16695 55.89142",
          "10.16664 55.89102",
          "10.16653 55.89069",
          "10.16644 55.89063",
          "10.16582 55.89043",
          "10.16544 55.89015",
          "10.16456 55.89011",
          "10.16385 55.8901",
          "10.16318 55.89013",
          "10.15893 55.89067",
          "10.15107 55.8908",
          "10.14954 55.89082",
          "10.14858 55.89082",
          "10.14794 55.89085",
          "10.14728 55.89092",
          "10.14515 55.89135",
          "10.14427 55.89148",
          "10.14308 55.89158",
          "10.139 55.89192",
          "10.1352 55.89203",
          "10.13411 55.892",
          "10.13237 55.89192",
          "10.12761 55.8917",
          "10.12761 55.8917",
          "10.12408 55.89135",
          "10.12232 55.89137",
          "10.12129 55.89161",
          "10.117 55.8929",
          "10.11644 55.89338",
          "10.1162 55.89615",
          "10.11657 55.89732",
          "10.10673 55.89669",
          "10.10607 55.89674",
          "10.10457 55.8971",
          "10.10363 55.89749",
          "10.10297 55.8976",
          "10.09997 55.89758",
          "10.09939 55.89748",
          "10.0981 55.89701",
          "10.0944 55.89572",
          "10.09275 55.89538",
          "10.09123 55.89501",
          "10.08962 55.89497",
          "10.08834 55.89514",
          "10.08671 55.89523",
          "10.08577 55.89542",
          "10.08488 55.89569",
          "10.08084 55.89555",
          "10.08012 55.89557",
          "10.07859 55.89569",
          "10.07762 55.89573",
          "10.07607 55.89569",
          "10.07479 55.89591",
          "10.0725 55.89621",
          "10.07153 55.89634",
          "10.07094 55.89645",
          "10.0701 55.89661",
          "10.06995 55.89707",
          "10.06913 55.89731",
          "10.06937 55.89811",
          "10.06684 55.89878",
          "10.06464 55.89986",
          "10.06279 55.90154",
          "10.06237 55.90212",
          "10.06248 55.90255",
          "10.06248 55.90255",
          "10.0645 55.90416",
          "10.06487 55.90459",
          "10.06509 55.9052",
          "10.06471 55.90572",
          "10.06286 55.90653",
          "10.06215 55.90694",
          "10.06188 55.90747",
          "10.06185 55.90791",
          "10.06203 55.90855",
          "10.06205 55.90903",
          "10.06189 55.91035",
          "10.0616 55.91113",
          "10.06121 55.91172",
          "10.06081 55.91212",
          "10.06038 55.91247",
          "10.05943 55.91302",
          "10.05682 55.91412",
          "10.05629 55.9144",
          "10.0558 55.91472",
          "10.05517 55.91521",
          "10.05472 55.91566",
          "10.05439 55.9161",
          "10.0536 55.91593",
          "10.05305 55.91575",
          "10.05265 55.91623",
          "10.05141 55.91634",
          "10.0508 55.91639",
          "10.05083 55.91714",
          "10.04998 55.91719",
          "10.04916 55.91731",
          "10.04871 55.91772",
          "10.04846 55.91819",
          "10.04614 55.92037",
          "10.04567 55.92092",
          "10.04461 55.92245",
          "10.04415 55.92294",
          "10.04369 55.92331",
          "10.04232 55.92431",
          "10.04037 55.92443",
          "10.03967 55.92446",
          "10.0371 55.9245",
          "10.03431 55.92451",
          "10.03365 55.9246",
          "10.03284 55.92485",
          "10.03193 55.92503",
          "10.03091 55.92517",
          "10.02908 55.92519",
          "10.02437 55.92531",
          "10.0235 55.92532",
          "10.02279 55.92531",
          "10.02187 55.92527",
          "10.02034 55.92512",
          "10.01895 55.92508",
          "10.01785 55.92526",
          "10.01618 55.92558",
          "10.01493 55.9258",
          "10.01393 55.92624",
          "10.01325 55.92648",
          "10.01237 55.92669",
          "10.01137 55.92678",
          "10.00772 55.92705",
          "10.00563 55.92724",
          "10.00449 55.92725",
          "10.00178 55.9273",
          "10.00115 55.9273",
          "10.00028 55.92726",
          "10.00017 55.92815",
          "10.00006 55.92893",
          "9.99952 55.92969",
          "9.99864 55.9304",
          "9.99776 55.93129",
          "9.99753 55.9316",
          "9.997 55.93293",
          "9.99687 55.9333",
          "9.99653 55.93481",
          "9.9964 55.93517",
          "9.99602 55.93609",
          "9.9958 55.93688",
          "9.99555 55.9376",
          "9.99573 55.93838",
          "9.99515 55.93874",
          "9.99428 55.93896",
          "9.99113 55.93929",
          "9.9905 55.93951",
          "9.98895 55.94132",
          "9.98831 55.9419",
          "9.9874 55.94219",
          "9.98505 55.94248",
          "9.98315 55.94345",
          "9.9827 55.94387",
          "9.98201 55.94415",
          "9.9802 55.94394",
          "9.97836 55.94335",
          "9.97667 55.944",
          "9.97566 55.94494",
          "9.97452 55.94687",
          "9.97407 55.94751",
          "9.97332 55.94814",
          "9.97276 55.94834",
          "9.97105 55.94871",
          "9.97023 55.94882",
          "9.96939 55.94881",
          "9.96828 55.94864",
          "9.96734 55.94852",
          "9.96594 55.94833",
          "9.9651 55.94832",
          "9.96311 55.94846",
          "9.9623 55.94846",
          "9.96181 55.94805",
          "9.96225 55.94694",
          "9.9605 55.94764",
          "9.95955 55.94812",
          "9.95863 55.94857",
          "9.95715 55.94931",
          "9.95653 55.94962",
          "9.95606 55.94982",
          "9.95483 55.94995",
          "9.95222 55.95006",
          "9.95145 55.95009",
          "9.94839 55.95024",
          "9.94772 55.95028",
          "9.94505 55.9504",
          "9.94339 55.95059",
          "9.94108 55.95093",
          "9.94025 55.95105",
          "9.93952 55.95112",
          "9.93702 55.95121",
          "9.93537 55.95126",
          "9.93002 55.95144",
          "9.9288 55.95148",
          "9.92632 55.95156",
          "9.92258 55.95169",
          "9.92113 55.95173",
          "9.9198 55.95177",
          "9.91904 55.95177",
          "9.91815 55.9518",
          "9.9165 55.9517",
          "9.91389 55.9515",
          "9.91164 55.95056",
          "9.90996 55.94981",
          "9.90926 55.94966",
          "9.90835 55.94956",
          "9.90749 55.94953",
          "9.90556 55.94961",
          "9.90391 55.9497",
          "9.90207 55.94976",
          "9.89737 55.9496",
          "9.89785 55.95319",
          "9.898 55.95377",
          "9.89792 55.95456",
          "9.89766 55.95518",
          "9.89757 55.95574",
          "9.89787 55.95662",
          "9.89806 55.95752",
          "9.89773 55.9583",
          "9.89826 55.95907",
          "9.89943 55.95952",
          "9.90051 55.95996",
          "9.90196 55.96075",
          "9.90189 55.96155",
          "9.90193 55.96189",
          "9.90155 55.96296",
          "9.89966 55.96344",
          "9.89904 55.96421",
          "9.89707 55.96664",
          "9.89588 55.96817",
          "9.89521 55.96857",
          "9.89417 55.96951",
          "9.89314 55.97044",
          "9.89264 55.97089",
          "9.89159 55.97183",
          "9.89116 55.97223",
          "9.88946 55.97378",
          "9.88947 55.97435",
          "9.8897 55.97478",
          "9.88952 55.97515",
          "9.88888 55.97548",
          "9.88791 55.97639",
          "9.88664 55.97684",
          "9.88552 55.97689",
          "9.88481 55.97673",
          "9.88434 55.97631",
          "9.88347 55.97609",
          "9.88282 55.97607",
          "9.88171 55.9761",
          "9.8811 55.97602",
          "9.88036 55.97587",
          "9.88007 55.97543",
          "9.87967 55.97488",
          "9.87934 55.97454",
          "9.87905 55.97425",
          "9.87884 55.97392",
          "9.87869 55.97356",
          "9.87682 55.97355",
          "9.87588 55.97355",
          "9.87516 55.97358",
          "9.87421 55.97366",
          "9.87312 55.97376",
          "9.87251 55.9738",
          "9.87147 55.9739",
          "9.87054 55.97397",
          "9.86902 55.9741",
          "9.86758 55.97423",
          "9.86695 55.97431",
          "9.86627 55.9744",
          "9.86566 55.97449",
          "9.86377 55.97488",
          "9.86202 55.97538",
          "9.86123 55.97565",
          "9.85977 55.9763",
          "9.85898 55.97644",
          "9.85537 55.97667",
          "9.85508 55.97716",
          "9.85483 55.97747",
          "9.85455 55.97779",
          "9.85377 55.97852",
          "9.85344 55.97904",
          "9.8533 55.98063",
          "9.85298 55.98107",
          "9.85252 55.98138",
          "9.852 55.98198",
          "9.85112 55.98353",
          "9.85083 55.98415",
          "9.85064 55.98448",
          "9.85032 55.98486",
          "9.84961 55.98552",
          "9.84923 55.98593",
          "9.8488 55.98628",
          "9.84787 55.98699",
          "9.84719 55.98802",
          "9.84617 55.98964",
          "9.84546 55.99025",
          "9.84397 55.9913",
          "9.84365 55.99184",
          "9.84376 55.99253",
          "9.84383 55.99332",
          "9.84381 55.99367",
          "9.84372 55.99429",
          "9.84356 55.99513",
          "9.8434 55.99581",
          "9.84285 55.9978",
          "9.84276 55.99829",
          "9.8427 55.9993",
          "9.84056 55.99741",
          "9.84056 55.99741",
          "9.8427 55.9993",
          "9.84265 56.00066",
          "9.84249 56.00436",
          "9.84247 56.00497",
          "9.84236 56.00666",
          "9.84233 56.00724",
          "9.84225 56.00862",
          "9.8422 56.00982",
          "9.84214 56.01061",
          "9.84204 56.01116",
          "9.84188 56.01153",
          "9.84158 56.01231",
          "9.84133 56.01293",
          "9.84117 56.01354",
          "9.84083 56.01571",
          "9.84068 56.01614",
          "9.84015 56.01728",
          "9.83992 56.01771",
          "9.83934 56.01862",
          "9.83863 56.01965",
          "9.836 56.02261",
          "9.83521 56.02311",
          "9.83366 56.02352",
          "9.83312 56.02372",
          "9.83281 56.02405",
          "9.83435 56.02433",
          "9.83572 56.0246",
          "9.83669 56.02479",
          "9.8386 56.02515",
          "9.83786 56.02628",
          "9.83743 56.02667",
          "9.83702 56.02692",
          "9.83586 56.02755",
          "9.83596 56.02819",
          "9.83669 56.02879",
          "9.83717 56.02933",
          "9.83736 56.02973",
          "9.83796 56.03043",
          "9.8388 56.03111",
          "9.84018 56.03222",
          "9.84148 56.03327",
          "9.84201 56.0338",
          "9.84255 56.03411",
          "9.84398 56.03458",
          "9.8446 56.03487",
          "9.8452 56.03511",
          "9.84645 56.03537",
          "9.84712 56.03556",
          "9.84778 56.03588",
          "9.84934 56.03684",
          "9.85094 56.03769",
          "9.85141 56.03793",
          "9.85197 56.03855",
          "9.85261 56.03908",
          "9.85309 56.03948",
          "9.85378 56.03997",
          "9.85416 56.04071",
          "9.85428 56.04187",
          "9.85443 56.04254",
          "9.85423 56.04316",
          "9.85236 56.04566",
          "9.85193 56.04604",
          "9.85039 56.04717",
          "9.85094 56.04737",
          "9.85094 56.04737",
          "9.85039 56.04717",
          "9.84915 56.04808",
          "9.84774 56.04909",
          "9.84758 56.04977",
          "9.84742 56.05045",
          "9.84753 56.05084",
          "9.84762 56.05149",
          "9.85041 56.05204",
          "9.85288 56.0525",
          "9.85463 56.05311",
          "9.85533 56.05351",
          "9.85635 56.05371",
          "9.85729 56.0539",
          "9.85869 56.05419",
          "9.85929 56.05428",
          "9.86038 56.05437",
          "9.86187 56.05444",
          "9.86295 56.05459",
          "9.86386 56.05488",
          "9.8649 56.05539",
          "9.86539 56.05563",
          "9.8648 56.05604",
          "9.86426 56.0565",
          "9.8684 56.05864",
          "9.86893 56.05891",
          "9.87074 56.05986",
          "9.87149 56.0603",
          "9.87209 56.06041",
          "9.87234 56.06075",
          "9.87272 56.06137",
          "9.87353 56.06114",
          "9.87458 56.06081",
          "9.87515 56.06092",
          "9.87616 56.06181",
          "9.87707 56.06228",
          "9.88206 56.06314",
          "9.88293 56.06356",
          "9.88562 56.0642",
          "9.88811 56.0648",
          "9.88896 56.0649",
          "9.89416 56.06514",
          "9.89548 56.06523",
          "9.89674 56.06517",
          "9.89707 56.06573",
          "9.89804 56.06615",
          "9.8977 56.06653",
          "9.89593 56.06852",
          "9.89568 56.06887",
          "9.89545 56.06926",
          "9.89529 56.06978",
          "9.89524 56.0704",
          "9.89528 56.0708",
          "9.89543 56.07122",
          "9.89693 56.0748",
          "9.89758 56.07637",
          "9.89854 56.07864",
          "9.89884 56.07937",
          "9.89893 56.07975",
          "9.89896 56.08022",
          "9.89894 56.08133",
          "9.89891 56.08347",
          "9.89883 56.08771",
          "9.8988 56.08951",
          "9.89879 56.0912",
          "9.89875 56.09181",
          "9.89864 56.09235",
          "9.89851 56.09286",
          "9.89814 56.09403",
          "9.89751 56.0961",
          "9.89725 56.09679",
          "9.89694 56.09744",
          "9.89658 56.09814",
          "9.89628 56.09877",
          "9.89544 56.10046",
          "9.89509 56.10117",
          "9.8933 56.10478",
          "9.89146 56.10845",
          "9.89105 56.10928",
          "9.89022 56.10992",
          "9.8894 56.11016",
          "9.88857 56.11025",
          "9.88571 56.11012",
          "9.88428 56.11005",
          "9.88283 56.11001",
          "9.88209 56.11008",
          "9.88093 56.11023",
          "9.87982 56.11045",
          "9.8793 56.11063",
          "9.87861 56.11099",
          "9.8781 56.11143",
          "9.87771 56.11183",
          "9.87716 56.11235",
          "9.87658 56.11273",
          "9.87552 56.11314",
          "9.87179 56.11425",
          "9.87096 56.11451",
          "9.87012 56.11488",
          "9.86961 56.11521",
          "9.86926 56.11549",
          "9.86805 56.11666",
          "9.8677 56.11699",
          "9.86661 56.11805",
          "9.86582 56.11879",
          "9.86524 56.11917",
          "9.86449 56.11948",
          "9.86381 56.11969",
          "9.8618 56.12023",
          "9.8618 56.12023",
          "9.86107 56.12042",
          "9.86014 56.12079",
          "9.85794 56.12195",
          "9.85729 56.12228",
          "9.85675 56.1225",
          "9.85593 56.12279",
          "9.85522 56.12297",
          "9.85392 56.12319",
          "9.85205 56.12338",
          "9.85073 56.12351",
          "9.84855 56.12373",
          "9.84761 56.12385",
          "9.84697 56.12396",
          "9.84598 56.12415",
          "9.8454 56.12426",
          "9.84377 56.12461",
          "9.84269 56.12519",
          "9.84145 56.12635",
          "9.84063 56.12712",
          "9.83996 56.12772",
          "9.83942 56.12809",
          "9.83872 56.12833",
          "9.83407 56.12929",
          "9.83311 56.12961",
          "9.83236 56.13009",
          "9.83027 56.1323",
          "9.82984 56.13276",
          "9.82861 56.13406",
          "9.82576 56.13708",
          "9.82537 56.13743",
          "9.82501 56.13773",
          "9.82452 56.1381",
          "9.82359 56.1387",
          "9.82249 56.1394",
          "9.82187 56.1398",
          "9.8213 56.14021",
          "9.82036 56.14104",
          "9.81988 56.14161",
          "9.81954 56.14211",
          "9.81928 56.14256",
          "9.81903 56.14321",
          "9.81892 56.14375",
          "9.81886 56.14422",
          "9.81886 56.14538",
          "9.81909 56.14566",
          "9.81883 56.14579",
          "9.81866 56.14633",
          "9.81862 56.14682",
          "9.81858 56.14719",
          "9.81853 56.14777",
          "9.81849 56.14812",
          "9.81863 56.14845",
          "9.81891 56.14865",
          "9.81858 56.14885",
          "9.81837 56.14932",
          "9.81773 56.15604",
          "9.81773 56.15647",
          "9.81779 56.15698",
          "9.81792 56.15751",
          "9.81814 56.15818",
          "9.81855 56.15832",
          "9.81833 56.15855",
          "9.81873 56.15908",
          "9.819 56.15942",
          "9.81941 56.15986",
          "9.81985 56.16026",
          "9.82038 56.16067",
          "9.82076 56.16094",
          "9.82128 56.16127",
          "9.82237 56.16183",
          "9.82336 56.16224",
          "9.82431 56.16257",
          "9.82513 56.1628",
          "9.82933 56.16385",
          "9.83036 56.16413",
          "9.83174 56.16453",
          "9.83274 56.16486",
          "9.83389 56.16524",
          "9.84051 56.16753",
          "9.84127 56.1678",
          "9.84204 56.16811",
          "9.84287 56.1685",
          "9.84357 56.16889",
          "9.84436 56.16943",
          "9.84501 56.16996",
          "9.84558 56.17056",
          "9.84612 56.17134",
          "9.84651 56.17202",
          "9.84675 56.17267",
          "9.84782 56.17594",
          "9.84867 56.17855",
          "9.84885 56.17932",
          "9.84897 56.18005",
          "9.84905 56.1815",
          "9.84913 56.18439",
          "9.84914 56.1848",
          "9.84926 56.18988",
          "9.84945 56.1913",
          "9.84932 56.19225",
          "9.84932 56.1933",
          "9.84932 56.19602",
          "9.84915 56.19826",
          "9.84904 56.19955",
          "9.84896 56.20056",
          "9.84885 56.20187",
          "9.84856 56.20523",
          "9.84851 56.20692",
          "9.84857 56.20805",
          "9.84891 56.21055",
          "9.84925 56.21273",
          "9.84934 56.21325",
          "9.84946 56.21402",
          "9.84956 56.21479",
          "9.8497 56.21566",
          "9.85032 56.21961",
          "9.85047 56.22034",
          "9.85059 56.22104",
          "9.85066 56.22147",
          "9.85082 56.22213",
          "9.85094 56.22252",
          "9.85112 56.223",
          "9.85146 56.22367",
          "9.85184 56.22427",
          "9.85216 56.22468",
          "9.85287 56.22554",
          "9.85394 56.2265",
          "9.85625 56.22829",
          "9.85942 56.23071",
          "9.86162 56.23239",
          "9.86389 56.23408",
          "9.86438 56.23445",
          "9.86491 56.23481",
          "9.86561 56.23532",
          "9.86679 56.23622",
          "9.86745 56.23679",
          "9.86813 56.2373",
          "9.86923 56.23817",
          "9.87001 56.23883",
          "9.87095 56.23972",
          "9.87152 56.24029",
          "9.87229 56.2411",
          "9.87336 56.24233",
          "9.87404 56.24317",
          "9.87503 56.24465",
          "9.87589 56.24623",
          "9.87643 56.24739",
          "9.87695 56.24856",
          "9.87802 56.2509",
          "9.8789 56.25291",
          "9.87938 56.25406",
          "9.87959 56.25471",
          "9.8797 56.25518",
          "9.8798 56.25564",
          "9.87987 56.25637",
          "9.87989 56.25701",
          "9.87986 56.25747",
          "9.87982 56.25784",
          "9.87974 56.25836",
          "9.87964 56.25886",
          "9.87947 56.25951",
          "9.87933 56.25988",
          "9.87915 56.26029",
          "9.87874 56.26114",
          "9.87792 56.26103",
          "9.87695 56.26077",
          "9.87737 56.26032",
          "9.87813 56.26009",
          "9.87981 56.26009",
          "9.88054 56.2601",
          "9.88152 56.26017",
          "9.88249 56.26028",
          "9.88329 56.26042",
          "9.88558 56.26092",
          "9.88648 56.26108",
          "9.88788 56.26127",
          "9.89077 56.26154",
          "9.89487 56.26189",
          "9.89645 56.26204",
          "9.89814 56.2622",
          "9.91121 56.26338",
          "9.91268 56.26353",
          "9.91425 56.26379",
          "9.91516 56.26411",
          "9.91612 56.26457",
          "9.91671 56.26505",
          "9.9173 56.26565",
          "9.91776 56.2668",
          "9.91816 56.26871",
          "9.91949 56.27543",
          "9.91967 56.27616",
          "9.91984 56.27664",
          "9.92008 56.27714",
          "9.92041 56.27774",
          "9.92081 56.27828",
          "9.92205 56.27975",
          "9.92205 56.27975",
          "9.92238 56.28013",
          "9.92326 56.28118",
          "9.9238 56.28181",
          "9.92427 56.28249",
          "9.92453 56.28303",
          "9.9258 56.2857",
          "9.92788 56.29015",
          "9.92983 56.2943",
          "9.93024 56.29517",
          "9.9309 56.29657",
          "9.93113 56.29692",
          "9.93134 56.29724",
          "9.93215 56.29828",
          "9.93267 56.29886",
          "9.93156 56.2991",
          "9.93042 56.29916",
          "9.92917 56.29934",
          "9.92803 56.29942"
          ];;
        return { ...state, fetching: false, fetched: true, points: points,poly: poly };
      } else {
        return { ...state, fetching: false, error: action.payload };
      }
    case types.TOUR_FULL_DATA_POLYLINE_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        points: action.payload.points,
        poly: action.payload.poly,
      };
  }
  return state;
};

export default tourFullPolyline ;
