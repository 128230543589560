import store from 'app/store';//eslint-disable-line
import * as types from 'app/actions/action_types';
import { isListExists } from '../components/Utility/utility';

const isRouteContainPath = (route, { location = {} }) =>
  route.location && route.location.pathname.indexOf(location.pathname) > -1;
const subdomain = window.location.host.split('.')[1]
  ? window.location.host.split('.')[0]
  : false;
const isExistSub = () => window.location.host.split('.').length > 2;

export const setPrevRoute =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { router, userReducer, locationUrl } = getState();
    const { type, payload } = action;
    if (type === '@@router/LOCATION_CHANGE') {
      if (subdomain && isExistSub() && process.env.NODE_ENV === 'production') {
        // need to remove for using on ng.tourstart.org
        if (payload.location.pathname !== '/') {
          window.location.replace(
            `${process.env.SERVER_URL}${payload.location.pathname}`
          );
        }
      }
      if (
        ((isListExists(payload) || isListExists(router)) &&
          isRouteContainPath(payload, router)) ||
        isRouteContainPath(router, payload)
      ) {
        action.payload.isListSwitched = true;
      }

      if (
        userReducer &&
        userReducer.index === 0 &&
        locationUrl.currentLocation
      ) {
        store.dispatch({
          type: types.USER_CHANGE_INDEX_VALUE,
          payload: 1,
        });
      }
    }

    next(action);
  };
