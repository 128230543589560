import { divIcon, Marker } from 'leaflet';
import cancel from 'static/img/cancel.png';
import { isIE } from 'react-device-detect';
import { pageScope } from 'app/constants/mapConstants';
import store from 'app/store';
import { Point } from '../map/drive/Point';

const getTourIconAnchor = (type, iconType) => {
  let anchor = [13, 34];
  if (!iconType)
    switch (type) {
      case Point.Type.VIA:
        anchor = [8, 20];
        break;
      case Point.Type.DRAG:
        anchor = [6, 8];
        break;
      default:
        anchor = [13, 34];
    }
  if (type === Point.Type.VIA && iconType === 'home_end') {
    anchor = [8, 20];
  }
  return anchor;
};

const getUndoHtml = (point, rightMenusText) => {
  return `<div class="wrap-wrong-marker-icon wrong-route-symbol">
            <div class="via-counter">${point.number}</div>   
            <div class="next-wrong-marker-icon">
                <div class="high-wrong-marker arrow">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="18"
                    height="18"
                    id="undo"
                    viewBox="0 0 18 18"
                >
                    <g
                        transform="translate(0,2)"
                        id="layer1">
                        <g
                            transform="matrix(0.89473435,0,0,-0.89473435,17.240502,13.552633)"
                            id="g222">
                            <path
                                d="m 0,0 c -0.125,0 -0.243,0.057 -0.302,0.146 -3.034,4.638 -6.957,5.331 -9.026,5.331 -0.297,0 -0.556,-0.015 -0.769,-0.034 l -0.456,-0.04 0,-4.379 c 0,-0.1 -0.075,-0.192 -0.192,-0.236 -0.048,-0.018 -0.097,-0.027 -0.146,-0.027 -0.097,0 -0.191,0.034 -0.256,0.094 l -7.539,6.857 c -0.054,0.049 -0.083,0.107 -0.083,0.169 0,0.061 0.029,0.119 0.082,0.168 l 7.541,6.858 c 0.064,0.058 0.158,0.093 0.256,0.093 0.05,0 0.098,-0.009 0.143,-0.026 0.119,-0.044 0.194,-0.137 0.194,-0.236 l 0,-3.532 0.482,-0.018 c 4.649,-0.174 8.634,-3 9.915,-7.033 C 0.399,2.402 0.357,0.969 0.341,0.429 L 0.338,0.271 C 0.338,0.15 0.24,0.047 0.102,0.012 0.068,0.004 0.033,0 0,0"
                                id="path224"
                                fill="#e6e7e7" fill-opacity=1 fill-rule="nonzero", stroke="none" 
                            />
                        </g>
                        <g transform="matrix(0.89473435,0,0,-0.89473435,7.4972033,-0.31574956)">
                            <path d="m 0,0 c -0.219,0 -0.433,-0.078 -0.594,-0.223 l -7.54,-6.858 c -0.327,-0.297 -0.327,-0.779 0,-1.077 l 7.54,-6.857 c 0.16,-0.146 0.374,-0.224 0.593,-0.224 0.107,0 0.217,0.019 0.32,0.059 0.314,0.117 0.518,0.396 0.518,0.704 l 0,3.921 c 0.201,0.018 0.445,0.032 0.725,0.032 1.97,0 5.703,-0.663 8.608,-5.105 0.153,-0.234 0.43,-0.372 0.72,-0.372 0.073,0 0.148,0.009 0.222,0.027 0.364,0.091 0.616,0.392 0.616,0.735 l 0.002,0.148 c 0.017,0.563 0.063,2.057 -0.52,3.896 -1.348,4.241 -5.553,7.201 -10.373,7.381 l 0,3.051 c 0,0.308 -0.204,0.587 -0.518,0.704 C 0.216,-0.019 0.107,0 0,0 m -0.163,-1.184 0,-2.629 0,-0.963 0.962,-0.036 c 4.439,-0.166 8.24,-2.852 9.457,-6.684 0.432,-1.359 0.485,-2.518 0.482,-3.188 -3.124,4.478 -7.067,5.161 -9.176,5.161 -0.277,0 -0.55,-0.012 -0.814,-0.036 l -0.911,-0.08 0,-0.916 0,-3.499 -7.076,6.435 7.076,6.435"/>
                        </g>
                    </g>
                </svg>
                </div>
                <span class="next-wrong-marker-icon-text undo">
                    <span>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        width="18"
                        height="18"
                        id="undo"
                        viewBox="0 0 18 18"
                    >
                        <g
                            transform="translate(0,2)"
                            id="layer1">
                            <g
                                transform="matrix(0.89473435,0,0,-0.89473435,17.240502,13.552633)"
                                id="g222">
                                <path
                                    d="m 0,0 c -0.125,0 -0.243,0.057 -0.302,0.146 -3.034,4.638 -6.957,5.331 -9.026,5.331 -0.297,0 -0.556,-0.015 -0.769,-0.034 l -0.456,-0.04 0,-4.379 c 0,-0.1 -0.075,-0.192 -0.192,-0.236 -0.048,-0.018 -0.097,-0.027 -0.146,-0.027 -0.097,0 -0.191,0.034 -0.256,0.094 l -7.539,6.857 c -0.054,0.049 -0.083,0.107 -0.083,0.169 0,0.061 0.029,0.119 0.082,0.168 l 7.541,6.858 c 0.064,0.058 0.158,0.093 0.256,0.093 0.05,0 0.098,-0.009 0.143,-0.026 0.119,-0.044 0.194,-0.137 0.194,-0.236 l 0,-3.532 0.482,-0.018 c 4.649,-0.174 8.634,-3 9.915,-7.033 C 0.399,2.402 0.357,0.969 0.341,0.429 L 0.338,0.271 C 0.338,0.15 0.24,0.047 0.102,0.012 0.068,0.004 0.033,0 0,0"
                                    id="path224"
                                    fill="#e6e7e7" fill-opacity=1 fill-rule="nonzero", stroke="none" 
                                />
                            </g>
                            <g transform="matrix(0.89473435,0,0,-0.89473435,7.4972033,-0.31574956)">
                                <path d="m 0,0 c -0.219,0 -0.433,-0.078 -0.594,-0.223 l -7.54,-6.858 c -0.327,-0.297 -0.327,-0.779 0,-1.077 l 7.54,-6.857 c 0.16,-0.146 0.374,-0.224 0.593,-0.224 0.107,0 0.217,0.019 0.32,0.059 0.314,0.117 0.518,0.396 0.518,0.704 l 0,3.921 c 0.201,0.018 0.445,0.032 0.725,0.032 1.97,0 5.703,-0.663 8.608,-5.105 0.153,-0.234 0.43,-0.372 0.72,-0.372 0.073,0 0.148,0.009 0.222,0.027 0.364,0.091 0.616,0.392 0.616,0.735 l 0.002,0.148 c 0.017,0.563 0.063,2.057 -0.52,3.896 -1.348,4.241 -5.553,7.201 -10.373,7.381 l 0,3.051 c 0,0.308 -0.204,0.587 -0.518,0.704 C 0.216,-0.019 0.107,0 0,0 m -0.163,-1.184 0,-2.629 0,-0.963 0.962,-0.036 c 4.439,-0.166 8.24,-2.852 9.457,-6.684 0.432,-1.359 0.485,-2.518 0.482,-3.188 -3.124,4.478 -7.067,5.161 -9.176,5.161 -0.277,0 -0.55,-0.012 -0.814,-0.036 l -0.911,-0.08 0,-0.916 0,-3.499 -7.076,6.435 7.076,6.435"/>
                            </g>
                        </g>
                    </svg>
                    </span>
                    ${rightMenusText.Undo}
                    <div class="next-worn-close-btn">
                        <img 
                            src=${cancel} 
                            alt="close"
                            class="wrong-close-btn"
                        />
                    </div>
                </span>
            </div>
            <div class="${point.type} ${point.iconType}"></div>
        </div>`;
};

const getHtml = (point, rightMenusText) => {
  return `<div class="wrap-wrong-marker-icon">
            <div class="via-counter">${point.number}</div>   
            <div class="next-wrong-marker-icon">
                <div class="high-wrong-marker">
                    <svg viewBox="0 0 51.83384 187.39784">
                        <title>TourCorrectIcon</title>
                        <path fill="#942820" d="M341.467,283.98465" transform="translate(-337.0738 -212.85216)"/>
                        <path fill="#942820" stroke="#942820" strokeMiterlimit="10" strokeWidth="4px" d="M341.467,283.66177V226.65686a10,10,0,0,1,6.79349-9.472l0.41089-.1391c7.89026-2.671,20.88678-2.941,28.88116-.59985h0.00006a9.68322,9.68322,0,0,1,6.96179,9.29291V283.6517c0,0.22125-21.10083,51.32443-21.93927,51.32761C361.73725,334.98248,341.467,283.87637,341.467,283.66177Z" transform="translate(-337.0738 -212.85216)"/>
                        <circle fill="#942820" stroke="#942820" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4px" cx="25.91692" cy="161.48093" r="23.91692"/>
                    </svg>
                </div>
                <span class="next-wrong-marker-icon-text">
                    <span>
                        <svg viewBox="0 0 51.83384 187.39784">
                            <title>TourCorrectIcon</title>
                            <path fill="#942820" d="M341.467,283.98465" transform="translate(-337.0738 -212.85216)"/>
                            <path fill="#942820" stroke="#942820" strokeMiterlimit="10" strokeWidth="4px" d="M341.467,283.66177V226.65686a10,10,0,0,1,6.79349-9.472l0.41089-.1391c7.89026-2.671,20.88678-2.941,28.88116-.59985h0.00006a9.68322,9.68322,0,0,1,6.96179,9.29291V283.6517c0,0.22125-21.10083,51.32443-21.93927,51.32761C361.73725,334.98248,341.467,283.87637,341.467,283.66177Z" transform="translate(-337.0738 -212.85216)"/>
                            <circle fill="#942820" stroke="#942820" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4px" cx="25.91692" cy="161.48093" r="23.91692"/>
                        </svg>
                    </span>
                    ${rightMenusText.Optimize}
                    <div class="next-worn-close-btn">
                        <img 
                            src=${cancel} 
                            alt="close"
                            class="wrong-close-btn"
                        />
                    </div>
                </span>
            </div>
            <div class="${point.type} ${point.iconType}"></div>
        </div>`;
};
// const getHtml = (point) => {         //previous red marker version
//     return(
//         `<div class="wrap-wrong-marker-icon">
//             <div class="via-counter">${point.number}</div>
//             <div class="next-wrong-marker-icon">
//                 <div class="high-wrong-marker">
//                     <svg viewBox="0 0 47.04739 167.69978">
//                         <title>TourCorrectIcon</title>
//                         <path fill="#ff0001" d="M681.05441,412.98465" transform="translate(-679.05442 -341.85216)"/>
//                         <path fill="#ff0001" strokeMiterlimit="10" strokeWidth="4px" stroke="#ff0001" d="M681.05442,412.66177V355.65686a10,10,0,0,1,6.79349-9.472l0.41089-.1391c7.89026-2.671,20.88678-2.941,28.88116-.59985H717.14a9.68322,9.68322,0,0,1,6.96179,9.29291V412.6517c0,0.22125-21.10083,51.32443-21.93927,51.32761C701.32465,463.98248,681.05442,412.87637,681.05442,412.66177Z" transform="translate(-679.05442 -341.85216)"/>
//                         <circle fill="#ff0001" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4px" stroke="#ff0001" cx="23.5237" cy="150.48023" r="15.21955"/>
//                     </svg>
//                 </div>
//                 <span class="next-wrong-marker-icon-text">
//                     <span>
//                         <svg viewBox="0 0 47.04739 167.69978">
//                             <title>TourCorrectIcon</title>
//                             <path fill="#ff0001" d="M681.05441,412.98465" transform="translate(-679.05442 -341.85216)"/>
//                             <path fill="#ff0001" strokeMiterlimit="10" strokeWidth="4px" stroke="#ff0001" d="M681.05442,412.66177V355.65686a10,10,0,0,1,6.79349-9.472l0.41089-.1391c7.89026-2.671,20.88678-2.941,28.88116-.59985H717.14a9.68322,9.68322,0,0,1,6.96179,9.29291V412.6517c0,0.22125-21.10083,51.32443-21.93927,51.32761C701.32465,463.98248,681.05442,412.87637,681.05442,412.66177Z" transform="translate(-679.05442 -341.85216)"/>
//                             <circle fill="#ff0001" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="4px" stroke="#ff0001" cx="23.5237" cy="150.48023" r="15.21955"/>
//                         </svg>
//                     </span>
//                     Correct
//                     <div class="next-worn-close-btn">/pro
//                         <img
//                             src=${cancel}
//                             alt="close"
//                             class="wrong-close-btn"
//                         />
//                     </div>
//                 </span>
//             </div>
//             <div class="${point.type} ${point.iconType}"></div>
//         </div>`
//     );
// }

const getClassName = (point, scope) => {
  if (point.iconType) {
    return `${scope}_${point.iconType}_icon`;
  }
  if (point.draggable || point.type !== Point.Type.DRAG) {
    return `${scope}_${point.type}_icon`;
  }
  return 'infinity_icon';
};

export const getMarkerIcon = (
  { point, isLastVia, partId },
  scope,
  rightMenusText = {}
) => {
  let params = {
    className: getClassName(point, scope),
    iconAnchor: getTourIconAnchor(point.type, point.iconType),
  };

  if (isIE) {
    if (
      point.type &&
      (point.type === Point.Type.VIA || point.type === Point.Type.DRAG) &&
      typeof point.number === 'number'
    ) {
      params.html = `<div class="via-counter">${point.number}</div>`;
    }
  }

  if (isLastVia && point.type === Point.Type.VIA) {
    params = {
      className: 'drive-via-last-icon',
      iconAnchor: getTourIconAnchor(point.type, point.iconType),
      html: `<div class="wrap-via-marker-icon">
                    <div class="via-counter">${point.number}</div>   
                    <div class="next-via-marker-icon">
                        <span class="next-via-marker-icon-text">${
                          rightMenusText['Change to End point'] ||
                          'Change to End point'
                        }</span>
                    </div>
                    <div class="via-marker-icon"></div>
                </div>`,
    };
  }

  if (!point.number && point.type === Point.Type.VIA && isLastVia === false) {
    params = {
      className: 'drive-via-last-icon',
      iconAnchor: getTourIconAnchor(point.type, point.iconType),
      html: `<div class="wrap-via-marker-icon">
                    <div class="next-via-marker-icon-start">
                        <span class="next-via-marker-icon-text-start">${
                          rightMenusText['Change to Start point'] ||
                          'Change to Start point'
                        }</span>
                    </div>
                    <div class="via-marker-icon"></div>
                </div>`,
    };
  }

  if (!isIE) {
    if (
      point.type &&
      (point.type === Point.Type.VIA || point.type === Point.Type.DRAG) &&
      typeof point.number === 'number' &&
      !isLastVia &&
      point.number &&
      point.isAutocorrection
    ) {
      const { isEditMode } = store.getState().tourFullData;
      const className =
        isEditMode && point.isWrongCoord()
          ? `${scope}_${point.type}_icon wrong-route-symbol`
          : `${scope}_${point.type}_icon`;
      params = {
        className,
        iconAnchor: getTourIconAnchor(point.type, point.iconType),
        html: getUndoHtml(point, rightMenusText),
        isAutocorrection: 1,
      };
    } else if (
      point.type &&
      (point.type === Point.Type.VIA || point.type === Point.Type.DRAG) &&
      typeof point.number === 'number' &&
      !isLastVia &&
      point.number
    ) {
      const { isEditMode } = store.getState().tourFullData;

      const className =
        isEditMode && point.isWrongCoord()
          ? `${scope}_${point.type}_icon wrong-route-symbol`
          : `${scope}_${point.type}_icon`;

      params = {
        className,
        iconAnchor: getTourIconAnchor(point.type, point.iconType),
        html: getHtml(point, rightMenusText),
      };
    }
  }

  if (point.type === Point.Type.END && point.draggable && !partId) {
    params = {
      className: 'drive-end-last-icon',
      iconAnchor: getTourIconAnchor(point.type, point.iconType),
      html: `<div class="wrap-end-marker-icon">
                    <div class="next-end-marker-icon">
                        <span class="next-end-marker-icon-text">${
                          rightMenusText['Change to Via point'] ||
                          'Change to Via point'
                        }</span>
                    </div>
                    <div class="end-marker-icon ${point.iconType}"></div>
                </div>`,
    };
  }

  if (point.type === Point.Type.START && point.draggable && !partId) {
    params = {
      className: 'drive-start-last-icon',
      iconAnchor: getTourIconAnchor(point.type, point.iconType),
      html: `<div class="wrap-start-marker-icon">
                    <div class="next-start-marker-icon">
                        <span class="next-start-marker-icon-text">${
                          rightMenusText['Change to Via point'] ||
                          'Change to Via point'
                        }</span>
                    </div>
                    <div class="start-marker-icon ${point.iconType}"></div>
                </div>`,
    };
  }

  if (point.scope === pageScope.place) {
    params = {
      className: `${point.scope}_${point.iconType}_icon`,
      iconAnchor: getTourIconAnchor(point.type, point.iconType),
      html: `<div lass="wrap-start-marker-icon">
                    <div class="place-marker-icon">
                        <span class="next-start-marker-icon-text">${
                          rightMenusText['Add as Via'] || 'Add as Via'
                        }</span>
                    </div>
                    <div class="${getClassName(point, scope)}"></div>
                </div>`,
    };
  }

  if (point.getPlaceType && point.getPlaceType()) {
    params = {
      className: `drive_${point.getPlaceType()}_icon`,
      iconAnchor: [13, 34],
    };
  }

  return new divIcon(params);
};

export const prepareExtaProps = ({ latLng, ...props }) => {
  const icon = new divIcon(props);
  return new Marker(latLng, { icon });
};

export const getMarker = (
  { customId, point, isLastVia },
  scope,
  rightMenusText
) => {
  const {
    lat,
    lng,
    draggable = false,
    type,
    partId,
    pointScope,
    iconType,
  } = point;

  const params = {
    icon: getMarkerIcon(
      { customId, point, isLastVia, partId },
      scope,
      rightMenusText || {}
    ),
    draggable,
    customId,
    type,
    scope: pointScope,
    iconType,
  };

  if (partId) {
    params.partId = partId;
  }

  const marker = new Marker([lat, lng], params);

  return marker;
};

const divPopupClass = 'selectpoint';

export const getPopUp = (latLng, divOptions, markerOptions) => {
  const divOpt = { ...divOptions };
  const icon = new divIcon(divOpt);
  let markerOpt = { ...markerOptions };

  if (typeof divOpt.className === 'undefined') {
    divOpt.className = divPopupClass;
  } else {
    divOpt.className += ` ${divPopupClass}`;
  }

  if (typeof markerOptions === 'undefined') {
    markerOpt = {};
    markerOpt.icon = icon;
  }

  return new Marker(latLng, markerOptions);
};
