import { accessType } from 'app/constants/tourAccessibilityTypes.constants';
import { Icon, divIcon, Marker, LatLng } from 'leaflet';

const entityMarker = (options) => {
  options = {
    ...{
      srcBase: require('static/images/icons/start_point.svg'),
      srcHover: require('static/images/icons/start_point_selected.svg'),
      width: 25,
      height: 34,
      map: null,
      lat: false,
      lng: false,
      title: '',
      scope: null,
      type: 'start',
      iconAnchor: [13, 34],
      position: null,
      id: false,
      draggable: false,
    },
    ...options,
  };
  let position;
  if (options.position) position = options.position;
  else if (options.lat && options.lng) {
    position = new LatLng(options.lat, options.lng);
  }
  let __marker = null;
  let __icon = null;
  let __hover_icon = null;
  let isHover = false;
  let _isMouseOver = false;
  let isViewState = false;
  //    var flg_isHide = !options.visible;

  function init() {
    getMarker();
  }

  function getId() {
    return options.id;
  }

  function createMarker() {
    if (typeof position === 'undefined') position = [0, 0];
    const marker = new Marker(position, {
      icon: getIcon(),
      title: options.title,
      draggable: options.draggable,
      customId: options.id,
    });

    marker.on('mouseover', function () {
      if (!_isMouseOver) {
        _interface.mouseover(_interface);
        hover();
      }
      _isMouseOver = true;
    });

    marker.on('mouseout', function () {
      if (_isMouseOver) {
        _interface.mouseout(_interface);
        if (!isViewState) unhover();
      }
      _isMouseOver = false;
    });

    marker.on('click', function () {
      _interface.click(_interface);
    });

    return marker;
  }

  function setHoverState() {
    isHover = true;
    hover();
  }

  function unsetHoverState() {
    isHover = false;
    if (!isViewState) unhover();
  }

  function hover() {
    getMarker().setIcon(getHoverIcon());
  }

  function unhover() {
    // getMarker().setIcon(getIcon()); //fixed bug force unhover icon on the main map
  }

  function getMarker() {
    if (!__marker) __marker = createMarker();
    return __marker;
  }

  function getIcon() {
    if (!__icon) __icon = makeIcon();
    return __icon;
  }

  function getHoverIcon() {
    if (!__hover_icon) __hover_icon = makeIcon(true);
    return __hover_icon;
  }

  function makeIcon(hovered) {
    //    return new Icon({
    const type = options.scope ? options.scope : options.type;
    if (options.tourType === accessType.NAF_TOUR) {
      return new divIcon({
        iconUrl: hovered ? options.srcHover : options.srcBase,
        iconAnchor: options.iconAnchor,
        className: `main_naf_tour_icon`,
      });
    }
    if (options.clubScope) {
      return new divIcon({
        iconUrl: hovered ? options.srcHover : options.srcBase,
        iconAnchor: options.iconAnchor,
        className: `main_${options.clubScope}_icon`,
      });
    }
    if (type === 'place') {
      return new divIcon({
        iconUrl: hovered ? options.srcHover : options.srcBase,
        iconAnchor: options.iconAnchor,
        className: `main_${options.iconType}_icon`,
      });
    }
    if (type === 'place_active') {
      return new divIcon({
        iconUrl: hovered ? options.srcHover : options.srcBase,
        iconAnchor: options.iconAnchor,
        className: `main_${options.iconType}_icon hover`,
      });
    }
    return new divIcon({
      iconUrl: hovered ? options.srcHover : options.srcBase,
      iconAnchor: options.iconAnchor,
      className: `main_${type}_icon`,
    });
  }
  //    function show(){
  //        options.visible = true;
  //        if(flg_isHide)
  //            getMarker().addTo(options.map);
  //        flg_isHide = false;
  //        return _interface;
  //    }
  //    function hide(){
  //        flg_isHide = true;
  //        options.map.removeLayer(getMarker());
  //        return _interface;
  //    }
  function isMouseOver() {
    return _isMouseOver;
  }

  init();

  let _interface = {
    //    show:show,
    //    hide:hide,
    getMarker,
    setHoverState,
    unsetHoverState,
    isMouseOver,
    getId,
    mouseover() {},
    mouseout() {},
    click() {},
    setViewState() {
      isViewState = true;
      _interface.setHoverState();
      return _interface;
    },
    unSetViewState() {
      isViewState = false;
      _interface.unsetHoverState();
      return _interface;
    },
    scope() {
      if (!options.scope) throw new Error('Entity scope is undefined');
      return options.scope;
    },
    remove() {
      options.map.removeLayer(getMarker());
    },
    setPosition(LatLng) {
      getMarker().setLatLng(LatLng);
      return _interface;
    },
    setZIndex(index) {
      getMarker().setZIndexOffset(index);
      return _interface;
    },
    getPosition() {
      return getMarker().getLatLng();
    },
    getInstance() {
      return 'entityMarker';
    },
  };
  return _interface;
};

export default entityMarker;
