import * as types from '../actions/action_types';

const passedPoint = {
  isSet: false,
  pointLoc: { lat: 0, lng: 0 },
  type: 'start',
};

const defaultScope = {
  scope: 'default',
  passedPoint,
};

const mapScopeReducer = (state = defaultScope, action) => {
  switch (action.type) {
    case types.SET_MAP_SCOPE:
      return { ...state, scope: action.payload || defaultScope.scope };
    case types.PASS_POINT_TO_SCOPE:
      return { ...state, passedPoint: action.payload };
    default:
      return state;
  }
};

export default mapScopeReducer;
