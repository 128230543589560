const initialState = {
  previousLocation: null,
  currentLocation: null,
};

export const locationUrl = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        previousLocation: state.currentLocation,
        currentLocation: action.payload.location.pathname,
      };
    default:
      return state;
  }
};
