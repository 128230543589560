import Cookies from 'js-cookie';
import { getCustomId } from 'app/libs/core/Unique';
import { GUEST_FE_USER_HISTORY_IDENTIFIER } from '../../constants/user.constants';

const guestIdentService = () => {
  const getGuestId = () => Cookies.get(GUEST_FE_USER_HISTORY_IDENTIFIER);

  const createNewGuestId = () =>
    parseInt(getCustomId().toString().substring(1, 10));

  const getGuestIdentifier = () => {
    let guestId = getGuestId();
    if (!guestId) {
      guestId = createNewGuestId();
      Cookies.set(GUEST_FE_USER_HISTORY_IDENTIFIER, guestId, {
        expires: 30,
        path: '',
        domain: process.env.COOKIE_HOST,
      });
    }
    return guestId;
  };

  return {
    get: getGuestIdentifier,
  };
};

export default guestIdentService;
