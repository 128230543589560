import { sha256 } from 'js-sha256';

import Cookies from 'js-cookie';
import userEnterStore from '../components/Desctop/UserEnter/userEnter.service';
import $api from './interceptors.api';

const authHeader = () => ({ Authorization: 'admin vinnitSa804' });
const axios = require('axios');

const getURL = () => `${process.env.API_URL}/api`;
const nodeURL = () => `${process.env.NODE_BACKEND}`;
const sold = 'F9qNdYD53bbcBJSnrYCXiZx6EzRswDiJ';

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    const { data } = response;
    if (response.status !== 200) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout(); // eslint-disable-line
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return reject(error);
    }
    return resolve(data);
  });
}

/*  Actual source */
const source = {};

function clearRequests() {
  for (const item in source) {
    if ({}.hasOwnProperty.call(source, item)) {
      source[item].cancel('Loading data is not actual yet');
    }
  }
}

function updateSource(token) {
  if (source[token]) source[token].cancel('Loading data is not actual yet');
  source[token] = axios.CancelToken.source();
  return source[token].token;
}

const apiMethods = {
  loginWeb: 'loginWeb',
};

const getHeaders = (params = {}) => {
  const headers = { 'Content-Type': 'application/json' };
  if (params.login) {
    headers['X-Sympasswd'] = sha256(params.login + sold);
  } else if (params.facebook_id) {
    headers['X-Sympasswd'] = sha256(params.facebook_id + sold);
  } else if (params.apple_id) {
    headers['X-Sympasswd'] = sha256(params.apple_id + sold);
  }
  return headers;
};

function login(params) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(params),
    withCredentials: true,
    params,
  };
  return axios(
    `${getURL()}/login.${params.apiMethod}?scope=web`,
    requestOptions
  ).then(handleResponse);

  // .then(data => {
  //     if( data && data.code === 200){
  //         const user = ( data.response && data.response.userdata ) ? data.response.userdata : data.response;
  //                 // store user details and jwt token in local storage to keep user logged in between page refreshes
  //                 // console.log('user', user, user.id, user.legal_issues);
  //         if(user && user.id && user.legal_issues){
  //                     // localStorage.setItem('user', JSON.stringify(user));
  //             userEnterStore.set( user );
  //         }
  //     }

  //     return data;
  // });
}

function loginJWT(params) {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
    data: { ...params },
  };
  return axios(`${nodeURL()}/login/${params.apiMethod}`, requestOptions).then(
    handleResponse
  );
}

function logout() {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  };
  // remove user from local storage to log user out
  // localStorage.removeItem('user');
  userEnterStore.remove();
  Cookies.remove('access_token');
  return axios(`${getURL()}/login.logout`, requestOptions).then(handleResponse);
}

function logoutJWT() {
  localStorage.removeItem('token');
  return $api.post('/login/logout');
}

function getAll() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return axios(`/users`, requestOptions).then(handleResponse);
}

function getById(id, isAuthUser) {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getById'),
  };

  return axios(
    `${getURL()}/user.getUserDataWeb?id=${id}&is_auth=${isAuthUser}`,
    requestOptions
  ).then(handleResponse);
}

function getTmpUserByCode(code) {
  const requestOptions = {
    method: 'GET',
  };

  return axios(`${getURL()}/user.getTmpUser?code=${code}`, requestOptions).then(
    handleResponse
  );
}

function getUserViaApple(params) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(params),
    withCredentials: true,
    params,
  };

  return axios(`${getURL()}/login.loginViaApple`, requestOptions).then(
    handleResponse
  );
}

function registerUserViaApple(params) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(params),
    withCredentials: true,
    params,
  };

  return axios(`${getURL()}/login.registerViaApple`, requestOptions).then(
    handleResponse
  );
}

function getHistoryById(id, page = 1) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getHistory?id=${id}&page=${page}`,
    requestOptions
  ).then(handleResponse);
}

function getFriendsById(id, text = '') {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
    withCredentials: true,
    cancelToken: updateSource('getFriendsById'),
  };
  return axios(
    `${getURL()}/user.getFriendsById?id=${id}&text=${text}`,
    requestOptions
  ).then(handleResponse);
}
function getClubsById(id) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
    withCredentials: true,
    cancelToken: updateSource('getClubsById'),
  };
  return axios(`${getURL()}/user.getClubsById?id=${id}`, requestOptions).then(
    handleResponse
  );
}
function getMediaById(id) {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getMediaById'),
  };

  return axios(`${getURL()}/user.getMedia?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getBikerBedsById(id) {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getBikerBedsById'),
  };

  return axios(`${getURL()}/user.getBikerBeds?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getCountryList() {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getCountryList'),
  };

  return axios(`${getURL()}/user.getCountryList`, requestOptions).then(
    handleResponse
  );
}

function getBikeBrands() {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getBikeBrands'),
  };

  return axios(`${getURL()}/common.getBikeBrandsList`, requestOptions).then(
    handleResponse
  );
}

function getBikeModels(brandId) {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getBikeModels'),
  };

  return axios(
    `${getURL()}/common.getBikeModelsList?brand_id=${brandId}`,
    requestOptions
  ).then(handleResponse);
}

function getVendorsGPSBrands() {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getVendorsGPSBrands'),
  };

  return axios(`${getURL()}/common.getGPSBrandsList`, requestOptions).then(
    handleResponse
  );
}

function getVendorsGPSModels(brandId) {
  const requestOptions = {
    method: 'GET',
    cancelToken: updateSource('getVendorsGPSModels'),
  };

  return axios(
    `${getURL()}/common.getGPSModelsList?brand_id=${brandId}`,
    requestOptions
  ).then(handleResponse);
}

function checkUserByEmail(username) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params: {
      username,
      check_email: true,
    },
  };

  return axios(`${getURL()}/login.checkUserByEmail`, requestOptions).then(
    handleResponse
  );
}

// function checkUserByEmail(username) {
//   const requestOptions = {
//     method: 'GET',
//     headers: { 'Content-Type': 'application/json' },
//     params: {
//       username,
//       check_email: true,
//     },
//   };

//   return axios(`${nodeURL()}/login/checkUserByEmail`, requestOptions).then(
//     handleResponse
//   );
// }

function renewPassword(params) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params,
  };

  return axios(`${getURL()}/user.renewPassword`, requestOptions).then(
    handleResponse
  );
}

function checkAlias(name) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params: { name },
  };

  return axios(`${getURL()}/login.checkAlias`, requestOptions).then(
    handleResponse
  );
}

function checkEmail(email) {
  const requestOptions = {
    method: 'GET',
    params: { email },
  };

  return axios(`${getURL()}/login.checkEmail`, requestOptions).then(
    handleResponse
  );
}

function sendConfirmLetter(username) {
  const params = { username };
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    params,
  };

  return axios(`${getURL()}/login.sendConfirmLetter`, requestOptions).then(
    handleResponse
  );
}

function sendNewPassword(username) {
  const params = { username };
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    params,
  };

  return axios(`${getURL()}/login.forgotPassword`, requestOptions).then(
    handleResponse
  );
}

function confirmWebRegistration(params) {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(params),
    withCredentials: true,
    params,
  };

  return axios(`${getURL()}/login.confirmWebRegistration`, requestOptions).then(
    handleResponse
  );
}

function getChangeUserPassword(oldPass, newPass) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/profile.changePassword?oldPass=${sha256(
      oldPass
    )}&newPass=${sha256(newPass)}`,
    requestOptions
  ).then(handleResponse);
}

function changeEmail(pass, mail, oldemail) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/profile.changeEmail?pass=${sha256(
      pass
    )}&mail=${mail}&oldemail=${oldemail}`,
    requestOptions
  ).then(handleResponse);
}
function update(user) {
  const requestOptions = {
    method: 'POST',
    // headers: { ...authHeader(), 'Content-Type': 'application/json' },
    withCredentials: true,
    params: {
      data: user,
    },
  };

  return axios(`${getURL()}/profile.setExtInfo`, requestOptions).then(
    handleResponse
  );
}

function deleteUser() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${getURL()}/user.removeUser`, requestOptions).then(
    handleResponse
  );
}

function getExtInfo(fields) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/profile.getExtInfo?fields={${fields}}`,
    requestOptions
  ).then(handleResponse);
}

function getPrivacyInfo(fields) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/profile.getPrivacyInfo?fields={${fields}}`,
    requestOptions
  ).then(handleResponse);
}

function getNotification() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    headers: { 'Cache-Control': 'no-cache' },
  };
  return axios(`${getURL()}/profile.getNotification`, requestOptions).then(
    handleResponse
  );
}
function setPrivacyInfo(data) {
  const requestOptions = {
    method: 'POST',
    // headers: { ...authHeader(), 'Content-Type': 'application/json' },
    withCredentials: true,
    params: {
      data,
    },
  };

  return axios(`${getURL()}/profile.setPrivacyInfo`, requestOptions).then(
    handleResponse
  );
}
function getAuthUserData() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/profile.getBaseInfo`, requestOptions).then(
    handleResponse
  );
}

function updateAvararFromURL(link) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    withCredentials: true,
  };

  return axios(
    `${getURL()}/profile.uploadAvatar?up_type=url&type=image&urls=${encodeURIComponent(
      link
    )}`,
    requestOptions
  ).then(handleResponse);
}

function removeUserPictureById(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${getURL()}/profile.removeImage?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function removeUserPictureByName(fileName) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/profile.removeImage?fileName=${fileName}`,
    requestOptions
  ).then(handleResponse);
}

function getChangeAvararURL() {
  return `${getURL()}/profile.uploadAvatar`;
}

function getChangeCoverURL() {
  return `${getURL()}/profile.uploadCover`;
}
function getUploadMemberPictureURL() {
  return `${getURL()}/profile.uploadMemberPicture`;
}

function getUploadPictureURL() {
  return `${getURL()}/profile.uploadPicture`;
}

function uploadMemberVideo(base) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${getURL()}/profile.uploadMemberVideo?up_type=url&type=video&url=${base}`,
    requestOptions
  ).then(handleResponse);
}

function addCommentForMember(params) {
  const requestOptions = {
    method: 'GET',
    // headers: { 'Content-Type': 'application/json' },
    params,
    withCredentials: true,
  };

  return axios(`${getURL()}/profile.addCommentForMember`, requestOptions).then(
    handleResponse
  );
}

function isProGpsAvailable() {
  const requestOptions = {
    method: 'POST',
    withCredentials: true,
  };
  return axios(`${getURL()}/profile.isProGpsAvailable`, requestOptions).then(
    handleResponse
  );
}

function getProDataById(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/user.getProDataById?id=${id}`, requestOptions).then(
    handleResponse
  );
}
function setGuestHistoryToCurrentUser(guestId, userId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.setGuestHistoryToCurrentUser?guestId=${guestId}&userId=${userId}`,
    requestOptions
  ).then(handleResponse);
}

async function getUserInvoicesList(userId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(
    `${nodeURL()}/user/getUserInvoicesList?userId=${userId}`,
    requestOptions
  ).then(handleResponse);
  // return $api.get('/user/getUserInvoicesList').then(handleResponse);
}

function setHistorySelectedData(userId, scope, data) {
  /*
    scope must be one of:
        'downloadgps';
        'historygps'; 
        'historysearchmain';
        'home_user_address';
        'work_user_address';
        'user_language';
        'home_user_address_status';
        'work_user_address_status';
        'academy_lessons'
    */

  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.setHistorySelectedData?userId=${userId}&scope=${scope}&data=${JSON.stringify(
      data
    )}`,
    requestOptions
  ).then(handleResponse);
}
function getHistorySelectedData(userId, scope) {
  /*
    scope must be one of:
        'downloadgps';
        'historygps'; 
        'historysearchmain';
    */
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getHistorySelectedData?userId=${userId}&scope=${scope}`,
    requestOptions
  ).then(handleResponse);
}
function getHistorySearchData(scope) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getHistorySearchData?scope=${scope}`,
    requestOptions
  ).then(handleResponse);
}

function getHistoryFeUserData(userId, scope) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getHistoryFeUserData?userId=${userId}&scope=${scope}`,
    requestOptions
  ).then(handleResponse);
}

function getUserHistoryData(userId) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getUserHistoryData?userId=${userId}`,
    requestOptions
  ).then(handleResponse);
}

function getUserLocation() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(`${getURL()}/user.getUserLocation`, requestOptions).then(
    handleResponse
  );
}
function getAmounts(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource('getAmounts'),
  };

  return axios(`${getURL()}/user.getAmounts?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getTourAndTrackInfo(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource('getTourAndTrackInfo'),
  };

  return axios(`${getURL()}/user.getTourInfo?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getFriendStatus(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };

  return axios(
    `${getURL()}/user.getFriendStatus?id=${id}`,
    requestOptions
  ).then(handleResponse);
}

function getFriendsList(id, text = '') {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
    withCredentials: true,
    cancelToken: updateSource('getFriendsList'),
  };

  let url = `/user.getFriendsList?id=${id}`;

  if (text) {
    url += `&text=${text}`;
  }

  return axios(`${getURL()}${url}`, requestOptions).then(handleResponse);
}

function sendFeedback(feedbackData) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true,
    data: feedbackData,
  };

  return axios(`${getURL()}/user.sendFeedback`, requestOptions).then(
    handleResponse
  );
}

function getAcademyInfo(id) {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
    cancelToken: updateSource('getAcademyInfo'),
  };

  return axios(`${getURL()}/user.getAcademyInfo?id=${id}`, requestOptions).then(
    handleResponse
  );
}

function getEmailsList() {
  const requestOptions = {
    method: 'GET',
    withCredentials: true,
  };
  return axios(`${getURL()}/list.getEmailsList`, requestOptions).then(
    handleResponse
  );
}

const userAPI = {
  login,
  loginJWT,
  logout,
  logoutJWT,
  getAll,
  getById,
  getTmpUserByCode,
  checkAlias,
  checkEmail,
  checkUserByEmail,
  sendConfirmLetter,
  sendNewPassword,
  apiMethods,
  renewPassword,
  getUserViaApple,
  registerUserViaApple,
  sendFeedback,

  isProGpsAvailable,
  getProDataById,
  getHistoryById,
  getFriendsById,
  getClubsById,
  getMediaById,
  getBikerBedsById,
  getFriendsList,
  getCountryList,
  getBikeBrands,
  getBikeModels,
  getVendorsGPSBrands,
  getVendorsGPSModels,
  getAmounts,
  getTourAndTrackInfo,
  getFriendStatus,
  update,
  getAuthUserData,
  getExtInfo,
  getPrivacyInfo,
  setPrivacyInfo,
  setHistorySelectedData,
  getHistorySelectedData,
  getHistorySearchData,
  getHistoryFeUserData,
  getUserHistoryData,
  setGuestHistoryToCurrentUser,
  getChangeUserPassword,
  changeEmail,
  getChangeAvararURL,
  getChangeCoverURL,
  getUploadMemberPictureURL,
  uploadMemberVideo,
  updateAvararFromURL,
  getUploadPictureURL,
  removeUserPictureById,
  removeUserPictureByName,
  addCommentForMember,
  getNotification,
  getUserLocation,
  getAcademyInfo,
  clearRequests,
  register: confirmWebRegistration,
  delete: deleteUser,
  getUserInvoicesList,
  getEmailsList,
};

export default userAPI;
