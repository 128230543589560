import { userConstants } from '../constants/user.constants';
import userEnterStore from '../components/Desctop/UserEnter/userEnter.service';

const data = userEnterStore.get('user');
const withoutUser = { isLoggedIn: false, user: {}, loginLoad: true };
const initialState =
  data && data.userdata
    ? { isLoggedIn: true, user: data.userdata, loginLoad: false }
    : withoutUser;

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        isLoggedIn: true,
        user: action.user,
        loginLoad: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        isLoggedIn: true,
        user: action.user,
        loginLoad: false,
      };
    case userConstants.LOGIN_FAILURE:
      return { ...withoutUser, loginLoad: false };
    case userConstants.LOGOUT:
      return { ...withoutUser, loginLoad: false };
    case userConstants.UPDATE_USER_DATA:
      return {
        ...state,
        isLoggedIn: action.payload && !!action.payload.id,
        user: action.payload,
      };
    default:
      return state;
  }
}
