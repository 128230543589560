import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const ProgressBar = (props) => {
  useEffect(() => {
    props.showLoading();
    return () => props.hideLoading();
  }, []);

  return false;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      showLoading,
      hideLoading,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ProgressBar);
