import React, { Component } from 'react';
import { connect } from 'react-redux';
import Map from '../map';

let dataScope = {
  editable: false,
};

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    dataScope = {
      editable: this.props.isEditTourMode,
      prevTour: this.props.prevTour,
    };
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.mouseCursorType !== nextProps.mouseCursorType) {
      if (nextProps.scope === 'place') {
        dataScope = {
          editable: nextProps.isEditPlaceMode,
          prevTour: nextProps.prevTour,
          homeVisible: nextProps.userData.showHome,
          workVisible: nextProps.userData.showWork,
        };
      }
      return true;
    }
    if (this.props.zoom !== nextProps.zoom) {
      return true;
    }
    if (this.props.prevData !== nextProps.prevData) {
      return true;
    }
    if (this.props.filter !== nextProps.filter) {
      return true;
    }
    if (
      this.props.scope !== nextProps.scope ||
      nextProps.isEditClubMode !== this.props.isEditClubMode ||
      nextProps.isEditTourMode !== this.props.isEditTourMode ||
      nextProps.isEditPlaceMode !== this.props.isEditPlaceMode ||
      nextProps.mapData !== this.props.mapData ||
      nextProps.prevTour !== this.props.prevTour ||
      nextProps.userData.showHome !== this.props.userData.showHome
    ) {
      if (
        nextProps.scope === 'club_view' ||
        nextProps.scope === 'bikerbed_view'
      ) {
        dataScope = {
          editable: nextProps.isEditClubMode,
          prevTour: nextProps.prevTour,
          homeVisible: nextProps.userData.showHome,
          workVisible: nextProps.userData.showWork,
        };
        return true;
      }
      if (nextProps.scope === 'place') {
        dataScope = {
          editable: nextProps.isEditPlaceMode,
          prevTour: nextProps.prevTour,
          homeVisible: nextProps.userData.showHome,
          workVisible: nextProps.userData.showWork,
        };
        return true;
      }
      dataScope = {
        editable: nextProps.isEditTourMode,
        prevTour: nextProps.prevTour,
        homeVisible: nextProps.userData.showHome,
        workVisible: nextProps.userData.showWork,
      };
      return true;
    }
    return false;
  }

  render() {
    const cursorType = this.props.mouseCursorType;

    const zoomLevel = `zoom_${this.props.zoom}`;
    return (
      <div className={cursorType}>
        <div className={zoomLevel}>
          <Map
            mapContainer="map"
            zoom={this.props.zoom}
            loc={this.props.loc}
            mapData={this.props.mapData}
            prevData={this.props.prevData}
            prevTour={this.props.prevTour}
            dataScope={dataScope}
            scope={this.props.scope}
            homeVisible={this.props.userData.showHome}
            workVisible={this.props.userData.showWork}
            isCorrectionEnabled={this.props.isCorrectionEnabled}
            filter={this.props.filter}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    zoom: store.mapPostition.zoom,
    loc: store.mapPostition.latLng,
    filter: store.mapPostition.filter,
    mapData: store.mapDataReducer.data,
    prevData: store.fetchPreviewData.data,
    prevTour: store.fetchPreviewPoly,
    isEditTourMode: store.tourFullData.isEditMode,
    isEditClubMode: store.clubFullDataReducer.isEditMode,
    isEditPlaceMode: store.placesData.isEditMode,
    scope: store.mapScopeReducer.scope,
    mouseCursorType: store.cssEffect.mouseCursorIconType,
    userData: store.userReducer,
    isCorrectionEnabled: store.tourFullData.isCorrectionEnabled,
  };
};
export default connect(mapStateToProps)(MapComponent);
