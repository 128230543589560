import * as types from '../actions/action_types';

const initialState = {
  fetched: false,
  fetching: true,
  data: {},
  dubData: {},
  isEditMode: false,
  admin: false,
};

const clubFullDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_CLUB_FULL_DATA_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_CLUB_FULL_DATA_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: action.payload,
      };
    case types.FETCH_CLUB_FULL_DATA_FULFILLED:
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
      };

    case types.CLUB_FULL_IS_EDIT_MODE:
      return { ...state, isEditMode: action.payload };

    case types.CLUB_CREATE_SET_ADDRESS: {
      const address = { ...action.payload };
      return {
        ...state,
        data: {
          ...state.data,
          address: address.value,
          lat: address.lat,
          lng: address.lng,
          country: address.country,
        },
      };
    }

    case types.CLUB_CREATE_SET_DATA:
      return { ...state, data: action.payload };

    case types.CLUB_SET_LOGO:
      return { ...state, data: { ...state.data, logo: action.payload } };

    case types.CLUB_SET_COVER:
      return { ...state, data: { ...state.data, background: action.payload } };

    case types.CLUB_ADD_VIDEO:
      return { ...state, data: { ...state.data, videos: action.payload } };

    case types.CLUB_ADD_PICTURES:
      return { ...state, data: { ...state.data, images: action.payload } };

    case types.CLUB_ADD_IMAGE_IDS:
      return { ...state, data: { ...state.data, imgids: action.payload } };

    case types.CLUB_SET_DUBDATA: {
      return { ...state, dubData: action.payload };
    }
    case types.IS_ADMIN:
      return { ...state, admin: action.payload };

    case types.CLUB_ADD_OPTIONS:
      return { ...state, data: { ...state.data, options: action.payload } };

    case types.CLUB_ADD_ADDITIONALY:
      return { ...state, data: { ...state.data, additionaly: action.payload } };

    case types.CLUB_ADD_SERVICES:
      return { ...state, data: { ...state.data, services: action.payload } };

    default:
      return { ...state };
  }
};

export default clubFullDataReducer;
