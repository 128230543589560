import * as types from "../actions/action_types";
import userAPI from "../API/user.service.api";
import { userHistory } from "../constants/user.constants";
import mapUtils from "../libs/Utils/mapUtils";
import store from '../store';
import { changeUserMainData } from 'actions/Users/users.action';

const exist = obj => {
    return typeof obj != "undefined" && obj;
}

const copyHomeAddresIntoProfile = (lat, lng, data) => {
    mapUtils.getTourstartGeocoder( 
        { lat, lng }, 
        e => {
            if(!e || !e.data){
                return;
            }
        const location = e.data[0]['location'];
        const street = e.data[0]['street'];
        if(typeof location != "undefined"){
            const address = [];
            if(exist(location['name']))
                address.push(location['name']);
            if(exist(street)) {
                if(exist(street['name']))
                    address.push(street['name']);
                if(exist(street['ref']))
                    address.push(street['ref']);
            }
            if(exist(e.data[0]['housenumber']))
                address.push(e.data[0]['housenumber']);
            
            const {country, region, place:city, zip} = location;
            userAPI.update({
                country,
                region,
                city,
                zip,
                address: address.join(','),
            });
            }
        }
    );
}

export const setUserAddressMiddleware = store => next => action => {
    const {type} = action;
    if(type === types.SET_HOME_ADDRESS_USER || type === types.SET_WORK_ADDRESS_USER){
        const { authentication=null, userReducer } = store.getState();
        if(authentication){
            const { user={} } = authentication;
            if(user.id){
                const { homeAddress={}, workAddress={} } = userReducer;
                const data = action.payload;
                
                if(type === types.SET_HOME_ADDRESS_USER){
                    const { lat, lng } = homeAddress;
                    if((!lat && !lng) || data.lat !== lat || data.lng !== lng){
                        userAPI.setHistorySelectedData(user.id, userHistory.HOME_USER_ADDRESS, action.payload);
                        userAPI.update({
                            address: data.value || data.address,
                            country: data.country
                        });
                    }
                }
                if(type === types.SET_WORK_ADDRESS_USER){
                    const { lat, lng } = workAddress;
                    if((!lat && !lng) || data.lat !== lat || data.lng !== lng){
                        userAPI.setHistorySelectedData(user.id, userHistory.WORK_USER_ADDRESS, action.payload);
                    }
                }
            }
        }
    }
    next(action);
}

export const hideUserAddressMiddleware = store => next => action => {
    const {type} = action;

    if(type === types.USER_CHANGE_SHOW_HOME || type === types.USER_CHANGE_SHOW_WORK){
        const { authentication=null, userReducer=null } = store.getState();
        const { showHome, showWork } = userReducer;
        if(authentication){
            const { user={} } = authentication;
            // console.log(authentication, user);
            if(user.id){
                if(type === types.USER_CHANGE_SHOW_HOME && action.payload !== showHome){
                    userAPI.setHistorySelectedData(user.id, userHistory.HOME_USER_ADDRESS_STATUS, {status :+action.payload});
                }
                if(type === types.USER_CHANGE_SHOW_WORK && action.payload !== showWork){
                    userAPI.setHistorySelectedData(user.id, userHistory.WORK_USER_ADDRESS_STATUS, {status: +action.payload});
                }
            }
        }
    }
    next(action);
}