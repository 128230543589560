import * as types from '../action_types';

export const init = () => {
  return {
    type: types.MAP_DATA_INITIAL_STATE,
  };
};

export const scope = (newScope) => ({
  type: types.SET_MAP_SCOPE,
  payload: newScope,
});

export const setPassedPoint = (pointLoc, type) => ({
  type: types.PASS_POINT_TO_SCOPE,
  payload: { isSet: true, pointLoc, type },
});

export const removePassedPoint = () => ({
  type: types.PASS_POINT_TO_SCOPE,
  payload: { isSet: false, pointLoc: { lat: 0, lng: 0 }, type: 'start' },
});
