import { progressQueueService } from './progressQueue.service';

export const useTimestamp = () => {
  const { getTimestamp } = progressQueueService;
  let currentTimestamp = 0;
  let countProgressRequests = 0;

  const addToProgress = () => {
    if (!currentTimestamp) currentTimestamp = getTimestamp();
    countProgressRequests++;
  };

  const isProgressCompleted = () => countProgressRequests === 0;

  const removeFromProgress = () => {
    let timestamp = 0;
    countProgressRequests--;
    if (isProgressCompleted()) {
      timestamp = currentTimestamp;
      currentTimestamp = 0;
    }

    return timestamp;
  };

  return [addToProgress, removeFromProgress];
};
