export const twisterType = {
  ToPoint: 0,
  RoundTrip: 1,
  FreeHand: 2,
  RidePlan: 3,
};

export const TwisterTypeName = {
  ToPoint: 'topoint',
  RoundTrip: 'roundtrip',
};
