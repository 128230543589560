import Cookies from 'js-cookie';
import * as types from '../actions/action_types';
import { USER_MAP_POSITION } from '../constants/user.constants';

const initLatLng = {
  lat: 55.4038,
  lng: 10.4024,
};

const initMapPosition = {
  latLng: Cookies.getJSON(USER_MAP_POSITION) || initLatLng,
  countMapClick: 0,
  boundsUrl: '',
  zoom: 7,
  coord: {},
  filter: null,
};

const mapPositionReducer = (state = initMapPosition, action) => {
  switch (action.type) {
    case types.MAP_POSITION:
      return {
        ...state,
        latLng: action.payload.latLng,
        zoom: action.payload.zoom,
      };

    case types.MAP_BOUNDS_STR_ZOOM: {
      const { boundsUrl, zoom } = action.payload;
      return { ...state, boundsUrl, zoom };
    }

    case types.MAP_DETECT_CLICK_EVENT:
      return { ...state, countMapClick: state.countMapClick + 1 };

    case types.MAP_DETECT_COORD:
      return { ...state, coord: action.payload };

    case types.MAP_SET_FILTER:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
};

export default mapPositionReducer;
