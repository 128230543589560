export const DRIVE_MAP_POINT_REMOVE = 'drive.map.point.remove';
export const DRIVE_MAP_POINT_MOUSEOVER = 'drive.map.point.mouseover';
export const DRIVE_MAP_POINT_MOUSEOUT = 'drive.map.point.mouseout';
export const DRIVE_MAP_POINT_DRAGEND = 'drive.map.point.dragend';
export const DRIVE_MAP_STATIC_POINT_SELECTED =
  'drive.map.static.point.selected';
export const DRIVE_MAP_STATIC_POINT_MOUSEENTER =
  'drive.map.static.point.mouseenter';
export const DRIVE_MAP_STATIC_ACTIVE_POINT_ON_MAP =
  'drive.map.static.active.point.on.map';
export const DRIVE_MAP_DRAGED_POINT_MOVED = 'drive.map.draged.point.moved';
export const DRIVE_MAP_TOGGLE_TIME_DIST_OF_POINTS =
  'drive.map.toggle.time.dist.of.points';
export const DRIVE_MAP_STATIC_ACTIVE_LAST_POINT_ON_MAP =
  'drive.map.static.active.last.point.on.map';

export const DRIVE_BUILDER_PLACE_CREATE = 'drive.builder.place.create';
export const DRIVE_BUILDER_PLACE_CLEAR = 'drive.builder.place.clear';

export const DRIVE_MAP_CLICK = 'drive.map.click';
export const DRIVE_MAP_CHANGE_POLY = 'drive.map.change.poly';
export const DRIVE_MAP_CHANGE_POINT_POLY = 'drive.map.change.point.poly';
export const DRIVE_MAP_MOVE_POINT_POLY = 'drive.map.move.point.poly';
export const DRIVE_MAP_UP_POLY_HANDLERS = 'drive.map.update.poly.handlers';
export const DRIVE_MAP_BREAK_POLY_HERE = 'drive.map.break.poly.here';
export const DRIVE_MAP_STATIC_PART_POLY_SELECT =
  'drive.map.static.part.poly.select';
export const DRIVE_MAP_UPLOAD_GPS_DATA = 'drive.map.upload.gps.data';

export const DRIVE_MAP_CENTERING = 'drive.map.centering';
export const DRIVE_MAP_POINT_SET_TYPE = 'drive.map.point.set.type';
export const DRIVE_MAP_POLYLINES_SET_LITE = 'drive.map.polyline.set.lite';
export const DRIVE_MAP_POLYLINES_SET_FULL = 'drive.map.polyline.set.full';
// export const DRIVE_MAP_POINT_ADD_BEFORE = 'drive.map.point.add.before';
// export const DRIVE_MAP_SHOW_POPUP = 'drive.map.show.popup';

export const DRIVE_LIST_POINT_MOUSE_OVER = 'drive.list.point.mouse.over';
export const DRIVE_GET_CURRENT_PART = 'drive.get.current.part';

export const SHOW_USER_POSITION_FIRST_LOADING =
  'show.user.position.first.loading';

export const SHOW_POLYLINE_FIT_BOUNDS = 'show.polyline.fit.bounds';

export const DRIVE_BUILDER_PART_ACTION = 'drive.builder.part.action';
export const DRIVE_BUILDER_TOUR_REVERSE = 'drive.builder.tour.reverse';
export const DRIVE_BUILDER_POINT_CREATE = 'drive.builder.point.create';
export const DRIVE_BUILDER_PART_POINT_CREATE =
  'drive.builder.part.point.create';
export const DRIVE_BUILDER_POINT_UPDATE = 'drive.builder.point.update';
export const DRIVE_BUILDER_PART_POINT_DATA_UPDATE =
  'drive.builder.part.point.data.update';
export const DRIVE_MAP_POINT_REMOVE_ONLY_ON_MAP =
  'drive.builder.point.remove.only.on.map';
export const DRIVE_BUILDER_FILTER_POI_CREATE =
  'drive.builder.filter.poi.create';
export const BUILDER_MAP_REMOVE_PART = 'builder.map.remove.part';
export const SAVE_TB_UPDATE_ALL_POLYS = 'save.tourbilder.update.polys';
export const SAVE_TB_SWITCH_SHAPING_UNPAVED_ROUTES =
  'save.tourbilder.update.polys.shaping.unpaved';
export const DRIVE_BUILDER_PART_DEVIDE_BY_POINT =
  'drive.builder.part.devide.by.point';

// export const DRIVE_BUILDER_POLY_STR_CREATE = 'drive.builder.poly.string.create';

export const DRIVE_BUILDER_DATA_CLEAN = 'drive.builder.data.clean';
export const DRIVE_BUILDER_CLEAN_POLYLINES = 'drive.builder.clean.polylines';

export const DRIVE_BUILDER_MOVE_MAP = 'drive.builder.move.map';
export const DRIVE_BUILDER_MAP_FIT_BOUNDS = 'drive.builder.map.fit.bonds';
export const DRIVE_BUILDER_CHANGE_MAP_ZOOM = 'drive.builder.change.map.zoom';

export const DRIVE_LIST_POINT_UPDATE_INDEX = 'drive.list.point.update.index';
export const DRIVE_LIST_ADD_EMPTY_VIA_POINT = 'drive.list.add.empty.via.point';
export const DRIVE_LIST_REMOVE_EMPTY_VIA_POINT =
  'drive.list.remove.empty.via.point';
export const DRIVE_LIST_SHOW_POINT_POS = 'drive.list.show.point.position';
export const DRIVE_MAP_LAUNCH_TOUR_CORRECTION_POINT_LIST =
  'drive.map.launch.tour.correction.pont.list';
export const DRIVE_MAP_UPDATE_WRONG_ROUTE_MARKER_VALUE =
  'drive.map.update.wrong.route.marker.value';
export const DRIVE_SHOW_RT_POLY = 'drive.show.rt.poly';
// export const DRIVE_LIST_SWITCH_ROUTE_OPTIMIZATION = 'drive.list.switch.route.optimisation';

export const DRIVE_ADD_POINT_BY_MODE = 'drive.add.point.by.mode';

export const DRIVE_MAP_SHOW_FREE_HAND_LAYER = 'drive.map.show.free.hand.layer';
export const DRIVE_MAP_HIDE_FREE_HAND_LAYER = 'drive.map.hide.free.hand.layer';
export const DRIVE_ADD_POINT_BY_FINGER_DRAW = 'drive.add.point.by.finger.draw';
export const DRIVE_MAP_FREE_HAND_MOVE_MAP_ON =
  'drive.map.free.hand.move.map.on';
export const DRIVE_MAP_FREE_HAND_MOVE_MAP_OFF =
  'drive.map.free.hand.move.map.off';
export const DRIVE_FIXED_POINTS = 'drive.fixed.points';
export const DRIVE_CLEAR_DATA = 'drive.clear.data';

export const DRIVE_PREVIEW_POINT_UPDATE = 'drive.preview.point.update';

export const AXIOS_BUILDER_CANCELED_POLY_ID = 'axios.builder.canceled.poly.id';

export const AREACURSOR_MAP_SHOW = 'areacursor.map.show';

export const DRIVE_CHANGE_CURSOR_ICON = 'drive.change.cursor.icon';

export const SAVE_TB_PART_DATA_IN_REDUX = 'save.tourbilder.part.data.to.redux';

export const GET_MAP_BOUNDS = 'get.map.bounds';
export const GET_MAP_ZOOM_CENTER = 'get.map.zoom.and.center';

export const GET_MAP_CONTEXT_MENU = 'get.map.context.menu';

export const SHOW_FILTER_POLYLINE = 'show.filter.polyline';
export const HIDE_FILTER_MAIN_POLYLINE = 'hide.filter.main.polyline';
export const HIDE_FILTER_POLYLINE = 'hide.filter.polyline';
export const SHOW_FILTER_ALTERNATIVE_POLYLINES =
  'show.filter.alternative.polylines';
export const ADD_FILTER_POINT = 'add.filter.point';
export const REMOVE_FILTER_POINT = 'remove.filter.point';
export const CLEAR_FILTER_POINTS = 'clear.filter.points';

export const SEARCH_SET_POINT_ADDRESS = 'search.set.point.address';

// CLUB
export const CLUB_POINT_CREATE = 'club.point.create';
export const CLUB_MAP_POINT_REMOVE = 'club.map.point.remove';
export const CLUB_MAP_CLEAN = 'club.map.clean';
export const CLUB_MAP_STATIC_POINT_MOUSEENTER =
  'club.map.static.point.mouseenter';
export const CLUB_EDIT_GET_ADDRESS_FROM_MAP_ON =
  'club.edit.get.address.from.map.on';
export const CLUB_EDIT_GET_ADDRESS_FROM_MAP_OFF =
  'club.edit.get.address.from.map.off';
// BIKERBED
export const BIKERBED_POINT_CREATE = 'bikerbed.point.create';
export const BIKERBED_MAP_POINT_REMOVE = 'bikerbed.map.point.remove';
export const BIKERBED_MAP_CLEAN = 'bikerbed.map.clean';
export const BIKERBED_EDIT_GET_ADDRESS_FROM_MAP_ON =
  'bikerbed.edit.get.address.from.map.on';
export const BIKERBED_EDIT_GET_ADDRESS_FROM_MAP_OFF =
  'bikerbed.edit.get.address.from.map.off';
// PROFILE
export const PROFILE_POINT_CREATE = 'profile.point.create';
export const PROFILE_MAP_POINT_REMOVE = 'profile.map.point.remove';
export const PROFILE_MAP_CLEAN = 'profile.map.clean';

// PLACE
export const PLACE_POINT_CREATE = 'place.point.create';
export const PLACE_MAP_POINT_REMOVE = 'place.map.point.remove';
export const PLACE_MAP_CLEAN = 'place.map.clean';
export const PLACE_EDIT_GET_ADDRESS_FROM_MAP_ON =
  'place.edit.get.address.from.map.on';
export const PLACE_EDIT_GET_ADDRESS_FROM_MAP_OFF =
  'place.edit.get.address.from.map.off';

export const TOOL_MAP_TOGGLE_FULL_SCREEN_MODE =
  'tool.map.toggre.full.screen.mode';
export const TOOL_MAP_SHOW_HOME_STATIC_ICON = 'tool.map.show.home.static.icon';
export const TOOL_MAP_SHOW_WORK_STATIC_ICON = 'tool.map.show.work.static.icon';

export const PROFILE_MAP_ADD_LAYER = 'profile.map.add.layer';
export const PROFILE_MAP_REMOVE_LAYER = 'profile.map.remove.layer';

// WLA
export const CREATE_WLA_POI = 'create.wla.poi';

// MAP
export const MAP_SET_CORREC_POINT_COORD = 'map.set.correct.point.coord';

export const DRIVE_MAPBUILDER_HIDE_WRONG_ROUTE_POPUP =
  'drive.mapbuilder.hide.wrong.route.popup';
export const DRIVE_BUILDER_MOVE_POINT_CORRECT_PLACE =
  'drive.builder.move.point.to.correct.place';
export const BUILDER_MAP_FLY_TO_LOCATION =
  'builder.map.fly.to.the.next.wrong.route.location';
export const MAP_AUTOCORRECT_POINT_COORD = 'map.autocorrect.point.coord';
export const MAP_DRIVE_GO_TO_PREVIOUS_TOUR_STATE =
  'map.drive.go.to.previous.tour.state';
export const MAP_DRIVE_CLEAR_AUTOCORRECTION_PUPUPS =
  'map.drive.clear.autocorrection.popups';

// MULTIDAY

export const SAVE_FIRST_PART_MULTIDAY = 'save.first.part.multiday';
export const SAVE_MULTI_DAY_COPY_TO_CREATE = 'save.multi.day.copy.to.create'; // MAP
export const MOVE_POINTS_SHORT_WRONG_DIST_TO_ROUTE =
  'move.point.with.shortest.distance.to.correct.place'; // 150 metters
export const CHANGE_CORRECTION_WRONG_ROUTE_STATUS =
  'change.correction.wrong.route.status';
export const CORRECT_ALL_WRONG_ROUTES_POSITION =
  'correct.all.wrong.routes.positin';
export const CORRECT_ALL_WRONG_ROUTES_ON_ALL_PAGES =
  'correct.all.wrong.routes.positin.on.all.pages';
export const MAP_EXCHANGE_WITH_NEXT_POINT = 'map.exchange.with.prev.point';
