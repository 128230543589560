import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as mapStatus from './rxBehaviorSubject';

let isDispatchBlocked = false;//eslint-disable-line
let subscription = null;

export const withDispatch = (Component) => (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    subscription = mapStatus.subscribe(({ status }) => {
      isDispatchBlocked = status;
    });
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  const ownDispatch = (data) => {
    // if(!isDispatchBlocked) // we should remember about each action!!!
    dispatch(data);
  };

  return <Component ownDispatch={ownDispatch} {...props} />;
};
