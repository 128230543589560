// import React from 'react';

const topics = {};
let subUid = -1;

export const publish = (topic, args) => {
  if (!topics[topic]) return false;

  const subsctibers = topics[topic];
  let len = subsctibers ? subsctibers.length : 0;

  while (len--) subsctibers[len].func(topic, args);

  return true;
};

export const subscribe = (topic, func) => {
  if (!topics[topic]) topics[topic] = [];

  const token = (subUid++).toString();
  topics[topic].push({
    token,
    func,
  });

  return token;
};

export const unsubscribe = (token) => {
  for (const m in topics) {
    if (topics[m]) {
      for (let i = 0, j = topics[m].length; i < j; i++) {
        if (topics[m][i].token === token) {
          topics[m].splice(i, 1);
          return token;
        }
      }
    }
  }
  return false;
};
