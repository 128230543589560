import * as types from '../actions/action_types';
import { SEARCH_BAR_ALL, SEARCH_DEFAULT_ROAD_TYPE } from '../constants/mainSearch.constants';

const initialState = {
    // Tour: false,
    // Places: false,
    // BikeClub: false,
    // BikerBed: false,
    // Member: false,
    // All: true,
    scope: SEARCH_BAR_ALL,
    roadTypes: SEARCH_DEFAULT_ROAD_TYPE,
}

const searchBarData = (state=initialState,action) => {
    switch (action.type){
        // case types.SEARCH_BAR_TOUR:
        //     return { ...state, Tour: action.payload};
        // case types.SEARCH_BAR_PLACES:
        //     return { ...state, Places: action.payload};
        // case types.SEARCH_BAR_BIKE_CLUB:
        //     return { ...state, BikeClub: action.payload};
        // case types.SEARCH_BAR_BIKER_BED:
        //     return { ...state, BikerBed: action.payload};
        // case types.SEARCH_BAR_MEMBER:
        //     return { ...state, Member: action.payload};
        // case types.SEARCH_BAR_ALL:
        //     return { ...state, All: action.payload};
        case types.SEARCH_BAR_SCOPE:
            return { ...state, scope: action.payload};
        case types.SEARCH_BAR_ROAD_TYPES:
            return { ...state, roadTypes: action.payload};
        default:
            return state
    }
}
export default searchBarData;