import * as types from '../actions/action_types';

const initialState = {
  open: false,
  openBasket: false,
  data: [],
  addedItem: {},
  coord: [0, 0],
};

const tourDownload = (state = initialState, action) => {
  switch (action.type) {
    case types.TOUR_DOWNLOAD_GPS_MODAL_OPEN:
      return { ...state, open: action.payload };
    case types.TOUR_DOWNLOAD_GPS_BASKET_OPEN:
      return { ...state, openBasket: action.payload };
    case types.TOUR_DOWNLOAD_GPS_DATA:
      return {
        ...state,
        data: [...state.data, action.payload],
        addedItem: action.payload,
      };
    case types.TOUR_DOWNLOAD_GPS_DATA_DELETE_FROM_BASKET: {
      let data = [...state.data];
      state.data.forEach((tour) => {
        if (tour && tour.parts && tour.parts.length > 0) {
          const newParts = tour.parts.filter(
            (val) => !action.payload || action.payload.indexOf(+val.id) === -1
          );
          if (newParts.length < tour.parts.length) {
            data = data.filter((val) => tour.id != val.id);
          }
        }
      });
      return {
        ...state,
        data: data.filter(
          (val) => !action.payload || action.payload.indexOf(+val.id) === -1
        ),
      };
    }
    case types.TOUR_DOWNLOAD_GPS_DATA_COORD:
      return { ...state, coord: action.payload };
    case types.TOUR_DOWNLOAD_GPS_DATA_CLEAR:
      return { ...state, data: [] };
    case types.TOUR_DOWNLOAD_GPS_DATA_FROM_DB:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};
export default tourDownload;
