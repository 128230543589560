import * as types from '../actions/action_types';
import { useTimestamp } from '../components/Utility/Service/keepTimestamp.service';

const [addToProgress, removeFromProgress] = useTimestamp();
export const updateTourDataMiddleware = () => (next) => (action) => {
  const { type, payload } = action;
  if (type === types.TOUR_FULL_INFO_UPDATE_DAY) {
    // const { data, activeTourTab } = getState().tourFullData;
    addToProgress();
    payload.timestamp = removeFromProgress();

    // const newPart = { ...payload.newDayData };
    // let mainPart;

    // if (data.multiDay && activeTourTab > 0) {
    //   mainPart = { ...data };
    //   mainPart.parts[activeTourTab - 1] = newPart;
    // } else {
    //   mainPart = { ...newPart };
    // }

    // payload.newDayData = mainPart;
    // payload.activeDayData = newPart;
    // console.log('Middleware triggered:', action);
  }
  next(action);
};
