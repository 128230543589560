import * as types from '../actions/action_types';

const initialState = {
    tags: {}, 
    fetching: false,
    fetched: false,
    error: null
}

const metaTagsData = (state=initialState,action) => {
    switch (action.type){
        case "@@router/LOCATION_CHANGE":
            return initialState;
        case types.GET_META_TAGS_FOR_SEO_PENDING:
            return { ...state, fetching: true};
        case types.GET_META_TAGS_FOR_SEO_REJECTED:
            return { ...state, fetching: false, error:action.payload }
        case types.GET_META_TAGS_FOR_SEO_FULFILLED:
            return { ...state, fetching: false, fetched: true, tags: action.payload };
        default:
            return state
    }
}
export default metaTagsData;