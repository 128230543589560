import * as types from '../actions/action_types';

const initialState = {
  price: {},
  priceWithVoucher: {},
  currency: [],
  selectedCurrency: '',
  voucher: {},
  period: 12,
  gps: {
    checked: false,
    active: 1,
  },
  apple: {
    checked: false,
    active: 1,
  },
  android: {
    checked: false,
    active: 1,
  },
};

const tourstartProData = (state = initialState, action) => {
  switch (action.type) {
    case types.PRO_DATA_INIT:
      return {
        ...state,
        gps: { checked: false, active: 1 },
        apple: { checked: false, active: 1 },
        android: { checked: false, active: 1 },
        period: 12,
        voucher: {},
      };
    case types.PRO_DATA_PRICE:
      return { ...state, price: action.payload };
    case types.PRO_DATA_GPS:
      return { ...state, gps: action.payload };
    case types.PRO_DATA_APPLE:
      return { ...state, apple: action.payload };
    case types.PRO_DATA_ANDROID:
      return { ...state, android: action.payload };
    case types.PRO_DATA_COST:
      return { ...state, cost: action.payload };
    case types.PRO_DATA_DISCOUNT:
      return { ...state, discont: action.payload };
    case types.PRO_DATA_CURRENCY:
      return { ...state, currency: action.payload };
    case types.PRO_DATA_CHANGE_SELECTED_CURRENCY:
      return { ...state, selectedCurrency: action.payload };
    case types.PRO_DATA_CHANGE_VOUCHER_DATA:
      return { ...state, voucher: action.payload };
    case types.PRO_DATA_SET_PRICE_WITH_VOUCHER:
      return { ...state, priceWithVoucher: action.payload };
    default:
      return state;
  }
};
export default tourstartProData;
