import axios from 'axios';
import { axiosWithAbort } from 'app/API/axiosStack';
import L from 'leaflet';
import 'polyline-encoded';

import { MemoMapUtils } from './MemoMapUtils';

const ourRouting = 'https://osrm.tourstart.org';

const versionAPIofOSRM = 'v1';
const cache = new MemoMapUtils();
const axiosWA = new axiosWithAbort();

const mapUtils = (() => {
  const exist = (obj) => {
    return typeof obj !== 'undefined' && obj;
  };

  function cancelRespByTopic(topic) {
    axiosWA.destroySources(topic);
  }

  const stickToRoad = (options, callbackfunction) => {
    if (typeof options.url === 'undefined') options.url = ourRouting;
    let url;
    if (versionAPIofOSRM && versionAPIofOSRM === 'v1') {
      url = `${options.url}/nearest/v1/driving/`;
      url += `${options.nearest.lng},${options.nearest.lat}`;
      url += '?number=1';
    } else {
      url = `${options.url}/nearest?output=json&instructions=false&alt=false`;
      url += `&loc=${options.nearest.lat},${options.nearest.lng}`;
    }

    axios.get(url).then((resp) => {
      let newData;
      if (versionAPIofOSRM === 'v1') {
        newData = {
          mapped_coordinate: {
            0: resp.data.waypoints[0].location[1],
            1: resp.data.waypoints[0].location[0],
          },
          roadName: resp.data.waypoints[0].name,
          versionAPIofOSRM,
        };
        const key = `${options.nearest.lat}_${options.nearest.lng}`;
        cache.useStableCoord(
          key,
          `${newData.mapped_coordinate[0]}_${newData.mapped_coordinate[1]}`
        );
      } else {
        newData = resp.data;
      }
      callbackfunction(newData);
    });
  };

  function twisterServiceOSRM(options, callbackfunction, userData) {
    if (typeof options.type === 'undefined') return;
    if (typeof options.url === 'undefined') options.url = ourRouting;
    let url = `${options.url}/twister/v1/driving/`;

    if (typeof options.coorditanes !== 'undefined')
      url += `${options.coorditanes}`;

    if (typeof options.overview === 'undefined') options.overview = 'full';

    url += `?overview=${options.overview}&type=${options.type}`;

    if (typeof options.correction !== 'undefined') {
      url += `&correction=${options.correction}`;
    }
    if (typeof options.coefficient !== 'undefined') {
      url += `&coefficient=${options.coefficient}`;
    }
    if (typeof options.israndom !== 'undefined') {
      url += `&israndom=${options.israndom}`;
    }
    if (typeof options.direction !== 'undefined') {
      url += `&direction=${options.direction}`;
    }

    const axiosKey = userData?.pointId ? `route_${userData.pointId}` : null;

    const { highway, ferry } = userData.avoid || {};

    let exclude = '';
    if (highway) {
      if (exclude) {
        exclude += `${exclude},motorway`;
      } else {
        exclude = '&exclude=motorway';
      }
    }
    if (ferry) {
      if (exclude) {
        exclude += `${exclude},ferry`;
      } else {
        exclude = '&exclude=ferry';
      }
    }

    axiosWA.get(axiosKey, url + exclude, ({ data }) => {
      if (data) {
        let newData;
        if (
          versionAPIofOSRM &&
          versionAPIofOSRM === 'v1' &&
          data !== 'cancel'
        ) {
          const { legs, geometry, distance, duration } = data.routes[0];
          const routeSteps = legs && legs[0] ? legs[0].steps : [];
          newData = {
            routeGeometry: geometry,
            routeSteps,
            routeSummary: {
              totalDistance: distance,
              totalTime: duration,
            },
            waypoints: data.waypoints,
            versionAPIofOSRM,
          };
          callbackfunction(newData, userData);
        } else {
          newData = data;
          callbackfunction(newData, userData);
        }
      } else {
        callbackfunction(null, userData);
      }
    });
  }

  function getOSMDirectionsService(options, callbackfunction, userData) {
    if (typeof options.url === 'undefined') options.url = ourRouting;
    let url;
    if (versionAPIofOSRM && versionAPIofOSRM === 'v1') {
      url = `${options.url}/route/v1/driving/`;
      if (typeof options.path === 'string') {
        url += options.path;
      } else {
        url += `${options.origin.lng},${options.origin.lat};`;
        if (typeof options.waypoints !== 'undefined') {
          if (
            typeof options.waypoints.lat !== 'undefined' &&
            typeof options.waypoints.lng !== 'undefined'
          )
            url += `${options.waypoints.lng},${options.waypoints.lat};`;
          else if (
            typeof options.waypoints.length !== 'undefined' &&
            options.waypoints.length > 0
          ) {
            options.waypoints.each((item) => {
              url += `${item.lng},${item.lat};`;
            });
          }
        }
        url += `${options.destination.lng},${options.destination.lat}`;
      }

      if (typeof options.overview === 'undefined') options.overview = 'full';

      url += `?overview=${options.overview}&geometries=polyline&steps=true`; // &alternatives=true";
    } else {
      url = `${options.url}/viaroute?output=json&instructions=true&alt=false`;
      url += `&loc=${options.origin.lat},${options.origin.lng}`;

      if (typeof options.waypoints !== 'undefined') {
        if (
          typeof options.waypoints.lat !== 'undefined' &&
          typeof options.waypoints.lng !== 'undefined'
        )
          url += `&loc=${options.waypoints.lat},${options.waypoints.lng}`;
        else if (
          typeof options.waypoints.length !== 'undefined' &&
          options.waypoints.length > 0
        ) {
          options.waypoints.each((item) => {
            url += `&loc=${item.lat},${item.lng}`;
          });
        }
      }

      url += `&loc=${options.destination.lat},${options.destination.lng}`;
      if (options.zoom) {
        if (parseInt(options.zoom) < 14) options.zoom = 14;
        url += `&z=${options.zoom}`;
      }
    }

    const axiosKey = userData.pointId ? `route_${userData.pointId}` : null;

    const { highway, ferry } = userData.avoid || {};
    let exclude = '';
    if (highway) {
      if (exclude) {
        exclude += `${exclude},motorway`;
      } else {
        exclude = '&exclude=motorway';
      }
    }
    if (ferry) {
      if (exclude) {
        exclude += `${exclude},ferry`;
      } else {
        exclude = '&exclude=ferry';
      }
    }
    axiosWA.get(axiosKey, url + exclude, ({ data }) => {
      if (data) {
        let newData;
        if (
          versionAPIofOSRM &&
          versionAPIofOSRM === 'v1' &&
          data !== 'cancel'
        ) {
          const { legs, geometry, distance, duration } = data.routes[0];
          const routeSteps = legs && legs[0] ? legs[0].steps : [];
          newData = {
            routeGeometry: geometry, // trips
            routeSteps,
            routeSummary: {
              totalDistance: distance,
              totalTime: duration,
            },
            versionAPIofOSRM,
            alternatives: data.routes.slice(1, data.routes.length),
          };
          if (distance && userData.altPath && userData.getAlt) {
            if (!userData.fromFilter) {
              callbackfunction(newData, userData);
            }
            const gets = `${process.env.NODE_BACKEND}/route?locs=${userData.altPath}&fastdist=${distance}&limit=10&page=1`;
            axiosWA.get(`${axiosKey}alternative`, gets, (obj) => {
              if (!obj || !obj.data) {
                callbackfunction(newData, userData);
                return;
              }
              const { data: date } = obj;
              newData.detectedPoints = date.detectedPoints;
              newData.alternatives = date.routes;
              callbackfunction(newData, userData);
            });
          } else {
            callbackfunction(newData, userData);
          }
        } else {
          newData = data;
          callbackfunction(newData, userData);
        }
      } else {
        callbackfunction(null, userData);
      }
    });
  }

  function formateTourstartAddress(obj) {
    if (!obj || typeof obj !== 'object') return '';

    if (!obj.features && !obj[0].location) return '';

    if (obj[0] && obj[0].location) {
      const countriesShort = {
        'Соединённые Штаты Америки': 'США',
        'Vereinigte Staaten von Amerika': 'USA',
        'Amerikas Forenede Stater': 'USA',
        'United States of America': 'USA',
        'United Kingdom': 'UK',
        'Vereinigtes Königreich Großbritannien und Nordirland': 'UK',
      };

      if (obj.length > 0 && typeof obj[0].location !== 'undefined') {
        const { location } = obj[0];
        const address = [];
        if (exist(location.name)) address.push(location.name);
        if (exist(obj[0].street)) {
          if (exist(obj[0].street.name)) address.push(obj[0].street.name);
          if (exist(obj[0].street.ref)) address.push(obj[0].street.ref);
        }
        if (exist(obj[0].housenumber)) address.push(obj[0].housenumber);
        if (exist(obj[0].postalCode)) address.push(obj[0].postalCode);
        if (exist(location.place)) address.push(location.place);
        if (
          exist(location.region) &&
          exist(location.country_code) &&
          ['US', 'AU', 'CA', 'MX', 'GB', 'IN', 'UA'].indexOf(
            location.country_code
          ) !== -1
        )
          address.push(location.region);
        if (exist(countriesShort[location.country]))
          address.push(countriesShort[location.country]);
        else if (exist(location.country)) address.push(location.country);

        return address.join(', ');
      }
      return '';
    }

    obj = obj.features;

    const countriesShort = {
      'Соединённые Штаты Америки': 'США',
      'Vereinigte Staaten von Amerika': 'USA',
      'Amerikas Forenede Stater': 'USA',
      'United States of America': 'USA',
      'United Kingdom': 'UK',
      'Vereinigtes Königreich Großbritannien und Nordirland': 'UK',
    };

    if (obj.length > 0 && typeof obj[0].properties !== 'undefined') {
      const { properties } = obj[0];
      const address = [];

      if (exist(properties.name)) {
        address.push(properties.name);
      }

      if (exist(properties.street)) {
        address.push(properties.street);
      }

      if (exist(properties.housenumber)) {
        address.push(properties.housenumber);
      }

      if (exist(properties.city)) {
        address.push(
          properties.city.replace(' Municipality', '').replace(' Kommune')
        );
      }

      if (
        exist(properties.state) &&
        exist(properties.countrycode) &&
        ['US', 'AU', 'CA', 'MX', 'GB', 'IN'].includes(properties.countrycode)
      ) {
        address.push(properties.state.replace('Region '));
      }

      if (exist(countriesShort[properties.country]))
        address.push(countriesShort[properties.country]);
      else if (exist(properties.country)) {
        address.push(properties.country);
      }

      return address.join(', ');
    }
    return '';
  }

  function formateAddressFromGeocoder(data) {
    const suggestions = [];

    const countriesShort = {
      'Соединённые Штаты Америки': 'США',
      'Vereinigte Staaten von Amerika': 'USA',
      'Amerikas Forenede Stater': 'USA',
      'United States of America': 'USA',
      'United Kingdom': 'UK',
      'Vereinigtes Königreich Großbritannien und Nordirland': 'UK',
    };

    data.features.forEach((item) => {
      const { properties } = item;

      const address = [];

      if (exist(properties.name)) address.push(properties.name);
      if (exist(properties.street)) address.push(properties.street);
      if (exist(properties.housenumber)) address.push(properties.housenumber);
      if (exist(properties.postcode)) address.push(properties.postcode);
      if (exist(properties.city)) address.push(properties.city);
      if (
        exist(properties.state) &&
        exist(properties.countrycode) &&
        ['US', 'AU', 'CA', 'MX', 'GB', 'IN'].includes(properties.countrycode)
      ) {
        address.push(properties.state);
      }
      if (exist(countriesShort[properties.country])) {
        address.push(countriesShort[properties.country]);
      } else if (exist(properties.country)) address.push(properties.country);

      suggestions.push({
        value: address.join(', '),
        data: {
          lat: item.geometry.coordinates['1'],
          lng: item.geometry.coordinates['0'],
        },
        tag: properties.osm_value ? properties.osm_value : '',
        key: properties.osm_key ? properties.osm_key : '',
        type: properties.type ? properties.type : '',
      });
    });

    return suggestions;
  }

  function getTourstartGeocoder(options, callbackfunction, urlN = null) {
    let url = 'https://geocoding.tourstart.org/';
    const olKey = urlN ? 'old' : '';
    if (
      typeof options.lat !== 'undefined' &&
      typeof options.lng !== 'undefined'
    ) {
      url += 'reverse';
      url += `?lat=${options.lat}&lon=${options.lng}&radius=3`;
      if (typeof options.language !== 'undefined')
        url += `&lang=${options.location.language}`;
      const axiosKey = `address_${olKey}${options.id}`;
      axiosWA.get(axiosKey, url, (e) => {
        if (e.data && e.data.features && e.data.features.length === 0) {
          getTourstartGeocoder(options, callbackfunction);
        }
        callbackfunction(e);
        axiosWA.cleanAbortList(axiosKey); // it works not correct
      });
    } else if (typeof options.address !== 'undefined') {
      url += 'address';
      url += `?query=${options.address}`;
      if (typeof options.language !== 'undefined')
        url += `&lang=${options.location.language}`;

      axios
        .get(url)
        .then(callbackfunction)
        .catch(() => callbackfunction({ data: null }));
    } else {
      callbackfunction({ data: null });
    }
  }

  function getTourstartCountryCode(object) {
    if (!object || typeof object !== 'object') return '';
    if (object.length > 0) {
      if (typeof object[0].location.country_code !== 'undefined')
        return object[0].location.country_code;
    }
    return '';
  }

  function getDistanceBetweenPoints(lat1, lon1, lat2, lon2, unit) {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    }

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === 'km') {
      dist *= 1.609344;
    }
    if (unit === 'N') {
      dist *= 0.8684;
    }
    return dist;
  }

  function strToLanLngArray(string) {
    const tourPathArr = string.split('|');
    const GlatLngArr = [];
    tourPathArr.forEach((item) => {
      const latLng = item.split(',');
      if (latLng.lenght < 2 || !latLng[0] || !latLng[1]) return;
      GlatLngArr.push(new L.latLng(latLng[0], latLng[1]));
    });
    return GlatLngArr;
  }

  function polyArrToStr(arr) {
    let str = '';
    arr.forEach((item) => {
      let coma = '|';
      if (!str) coma = '';
      str += `${coma}${item[0]},${item[1]}`;
    });
    return str;
  }

  return {
    encodePath(latLngArr) {
      return L.PolylineUtil.encode(latLngArr);
    },
    decodePath(encodeStr) {
      return L.PolylineUtil.decode(encodeStr);
    },
    polyStrToArr(str) {
      return strToLanLngArray(str);
    },
    polyArrToStr(arr) {
      return polyArrToStr(arr);
    },
    stickToRoad(options, callback, userData) {
      return stickToRoad(options, callback, userData);
    },
    getOSMDirectionsService(options, callback, userData) {
      return getOSMDirectionsService(options, callback, userData);
    },
    twisterServiceOSRM(options, callback, userData) {
      return twisterServiceOSRM(options, callback, userData);
    },
    getDistance(lat1, lon1, lat2, lon2, unit) {
      return getDistanceBetweenPoints(lat1, lon1, lat2, lon2, unit);
    },
    formateTourstartAddress(obj) {
      return formateTourstartAddress(obj);
    },
    getTourstartGeocoder(options, callbackfunction) {
      return getTourstartGeocoder(options, callbackfunction);
    },
    getTourstartCountryCode(obj) {
      return getTourstartCountryCode(obj);
    },
    formateAddressFromGeocoder(obj) {
      return formateAddressFromGeocoder(obj);
    },
    cancelRespByTopic(topic) {
      return cancelRespByTopic(topic);
    },
  };
})();

export default mapUtils;
