import * as types from '../actions/action_types';

const collection = {};
collection.push = Array.prototype.push;
const initialState = {
  tourDays: [
    {
      title: '',
      type: 5,
      active: true,
      points: collection,
    },
  ],
};

const driveDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_POINT_TO_ACTIVE_TOUR_DAY:
      return { ...state, tourDays: action.payload };
    default:
      return state;
  }
};

export default driveDataReducer;
