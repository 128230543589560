import * as types from '../action_types';

export const loginIsLogin = (data) => {
  return {
    type: 'LOGIN_DATA_IS_LOGIN',
    payload: data,
  };
};
export const loginModalOpen = (data) => {
  return {
    type: 'LOGIN_DATA_MODAL_OPEN',
    payload: data,
  };
};
export const loginProModalOpen = (data) => {
  return {
    type: 'LOGIN_DATA_PRO_MODAL_OPEN',
    payload: data,
  };
};
export const loginLegalIssuesModalOpen = (data) => {
  return {
    type: 'LOGIN_DATA_LEGAL_ISSUES_OPEN',
    payload: data,
  };
};
export const setIsUnregisterUser = (data) => {
  return {
    type: 'SET_IS_UNREGISTER_USER_DATA',
    payload: data,
  };
};
export const setIsUserConfirmLegalIssues = (data) => {
  return {
    type: 'SET_IS_USER_CONFIRM_LEGAL_ISSUES',
    payload: data,
  };
};
export const setIsModalOpenFAQ = (data) => {
  return {
    type: types.IS_FAQ_MODAL_OPEN,
    payload: data,
  };
};

export const setFaqParentId = (data) => {
  return {
    type: types.SET_FAQ_PARENT_ID,
    payload: data,
  };
};
