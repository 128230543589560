import React, { lazy, Suspense } from 'react';
import { ErrorBoundary } from '../../Utility/errorBoundary';
import { retry } from '../../Utility/loadingChunkErrorHandler';

import ShowUserLocation from './showUserLocation';
// import MetaTags from './headMetaTags';
import SmartBannerApp from './smartBanerApp';

const ShowSpecIconsOnTheMap = lazy(() =>
  retry(() => import('./showSpecIconsOnTheMap'))
);
const KeepCreatedToursForGuests = lazy(() =>
  retry(() => import('./keepCreatedToursForGuests'))
);
const KeepFeUserHistoryForGuests = lazy(() =>
  retry(() => import('./keepFeUserHistoryForGuests'))
);
const ExtraTranslationsDefinition = lazy(() =>
  retry(() => import('./extraTranslationsDefinition'))
);
const TestLauncher = lazy(() => retry(() => import('./test.launcher')));

const DefaultFunctionality = () => (
  <ErrorBoundary>
    <Suspense fallback={<div />}>
      <ShowSpecIconsOnTheMap />
      <KeepCreatedToursForGuests />
      <KeepFeUserHistoryForGuests />
      <ExtraTranslationsDefinition />
      <ShowUserLocation />
      {/* <MetaTags /> */}
      <SmartBannerApp />
      <TestLauncher />
    </Suspense>
  </ErrorBoundary>
);

export default DefaultFunctionality;
