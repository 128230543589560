import Cookies from 'js-cookie';
import * as types from '../actions/action_types';
import { USER_LANGUAGE_BROWSER_COOKIE_NAME } from '../constants/user.constants';

const initialState = {
  active: Cookies.get(USER_LANGUAGE_BROWSER_COOKIE_NAME) || 'en',
};

const languageData = (state = initialState, action) => {
  switch (action.type) {
    case types.LANGUAGE_DATA_CHANGE_ACTIVE:
      return { ...state, active: action.payload };
    default:
      return state;
  }
};
export default languageData;
