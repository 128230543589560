import axios from 'axios';

export const API_URL = `${process.env.NODE_BACKEND}`;

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((response) => {
  if (response.headers)
    response.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;

  return response;
});

$api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.get(`${API_URL}/login/refresh`, {
          withCredentials: true,
        });
        localStorage.setItem('token', response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        console.log('Not loggin');
      }
    }
    return Promise.reject(error);
  }
);

export default $api;
