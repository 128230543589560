import React from 'react';
import { useSelector } from 'react-redux';
import { isMobileOnly } from 'react-device-detect';
import BoosterPlugImg from 'static/img/header/BoosterPlug_logo_v1.png';
import naf from 'static/img/header/NAF_MC.svg';
import { accessType } from 'app/constants/tourAccessibilityTypes.constants';
import { useRouteMatch } from 'react-router';

export const PartnersLogo = () => {
  const tourType = useSelector((store) => store.tourFullData.data.tourType);
  const match = useRouteMatch('/dreamtours/:page');
  const matchClub = useRouteMatch('/club/:page');

  return isMobileOnly || window.innerWidth < 768 ? null : (
    <div className="partnerLogoLayout">
      {tourType === accessType.NAF_TOUR ||
      match?.url?.includes('naf') ||
      matchClub?.url?.includes('naf') ? (
        <a href="https://nye.naf.no/mc" target="_blank" rel="noreferrer">
          <img
            src={naf}
            alt="naf"
            style={{ cursor: 'pointer', maxWidth: 115 }}
          />
        </a>
      ) : (
        <a
          href="https://www.boosterplug.com/shop/frontpage.html"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={BoosterPlugImg}
            alt="boosterplug"
            style={{ cursor: 'pointer', width: 90 }}
          />
        </a>
      )}
    </div>
  );
};
