import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Cookies from 'js-cookie';
import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';
import { usePosition } from '../../Utility/usePosition';

import { USER_MAP_POSITION } from '../../../constants/user.constants';

import { isEmptyObject } from '../../Utility/utility';

import userAPI from '../../../API/user.service.api';

const ShowUserLocation = () => {
  const { homeAddress } = useSelector((store) => store.userReducer);
  const { user } = useSelector((store) => store.authentication);
  const [isGetPosition, setIsGetPosition] = useState(false);
  const position = usePosition();

  const setUserPosition = ({ lat, lng }) => {
    const data = { lat, lng };
    Cookies.set(USER_MAP_POSITION, data, { domain: process.env.COOKIE_HOST });
    setIsGetPosition(true);
  };

  useEffect(() => {
    if (Cookies.get(USER_MAP_POSITION)) {
      setIsGetPosition(true);
    } else {
      userAPI.getUserLocation().then((res) => {
        const { code, lat = null, lng = null } = res;
        if (code === 200 && lat && lng) {
          setUserPosition(res);
          publish(way.SHOW_USER_POSITION_FIRST_LOADING, { lat, lng });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (position.lat && position.lng && !isGetPosition) {
      const { lat, lng } = position;
      setUserPosition(position);
      publish(way.SHOW_USER_POSITION_FIRST_LOADING, { lat, lng });
    }
  }, [position]);

  useEffect(() => {
    if (homeAddress && !isEmptyObject(homeAddress)) {
      setUserPosition(homeAddress);
    }
  }, [homeAddress]);

  useEffect(() => {
    if (
      user &&
      !isEmptyObject(user) &&
      !isGetPosition &&
      !homeAddress &&
      !position.lat &&
      !position.lng
    ) {
      setUserPosition(user);
    }
  }, [user]);

  return null;
};

export default ShowUserLocation;
