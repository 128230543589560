import { applyMiddleware, createStore } from 'redux';
// import { createLogger } from 'redux-logger';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
// Redux DevTools
import { composeWithDevTools } from 'redux-devtools-extension';
// Initialize google analytics page view tracking
// import ReactGA from 'react-ga';
import GA4React from 'ga-4-react';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import reducers from './reducers';
import { addTimestampMiddleware } from './middleware/addTimestamp.middleware';
import {
  //eslint-disable-line
  setUserAddressMiddleware, //eslint-disable-line
  hideUserAddressMiddleware, //eslint-disable-line
} from './middleware/setUserAddress.middleware'; //eslint-disable-line
import { setUserLanguage } from './middleware/setUserLanguage.middleware';
import { optimizeSaveTour } from './middleware/optimiseSaveTour.middleware';
import { setPrevRoute } from './middleware/addPrevRoute.middleware'; //eslint-disable-line
import { optimizeSettingsMiddleware } from './middleware/optimizeSettings.middleware';
import { addViaTourDay } from './middleware/addViaTourDay.middleware';
import { updateTourDataMiddleware } from './middleware/updateTourData.middleware';
// import * as types from './actions/action_types';

// import createHistory from 'history/createBrowserHistory';
const createHistory = require('history').createBrowserHistory;

export const history = createHistory();

const env = process.env.NODE_ENV;
let middleware;
if (env !== 'production') {
  // const logger = createLogger();
  middleware = applyMiddleware(
    promise(),
    thunk,
    // logger,
    loadingBarMiddleware(),
    addTimestampMiddleware,
    setUserAddressMiddleware,
    hideUserAddressMiddleware,
    setUserLanguage,
    setPrevRoute,
    optimizeSaveTour,
    optimizeSettingsMiddleware,
    addViaTourDay,
    updateTourDataMiddleware
  );
} else {
  middleware = applyMiddleware(
    promise(),
    thunk,
    loadingBarMiddleware(),
    addTimestampMiddleware,
    setUserAddressMiddleware,
    hideUserAddressMiddleware,
    setUserLanguage,
    setPrevRoute,
    optimizeSaveTour,
    optimizeSettingsMiddleware,
    addViaTourDay,
    updateTourDataMiddleware
  );
}

const composeEnhancers = composeWithDevTools({
  trace: true,
});

const store = createStore(reducers(history), composeEnhancers(middleware));

if (process.env.BACKEND_URL.includes('.org')) {
  // Replace with your Google Analytics tracking ID

  const ga4react = new GA4React('G-WFT847YTNT');

  ga4react.initialize().then(
    (ga4) => {
      history.listen((location) => {
        ga4.pageview(location.pathname, location); // Record a pageview for the given page
      });
    },
    (err) => {
      console.error(err);
    }
  );

  // ReactGA.initialize('UA-8908260-1');

  // history.listen((location) => {
  //   ReactGA.set({ page: location.pathname }); // Update the user's current page
  //   ReactGA.pageview(location.pathname); // Record a pageview for the given page
  // });
  // //
}

export default store;
