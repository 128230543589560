import * as types from "../actions/action_types";


const initialState = {
    fetching: false,
    fetched: false,
    data: {},
    error: null
};

const userHistoryReducer = function(state = initialState, action) {
    switch (action.type) {
        case types.USER_HISTORY_STARTED:
            return { ...state, fetching: true};
        case types.USER_HISTORY_FAILURE:
            return { ...state, fetching: false, error:action.payload }
        case types.USER_HISTORY_SUCCESS:
            return {...state, fetching: false, fetched: true, data: action.payload };
        case types.USER_HISTORY_CHANGE_SCOPE:
            const { scope, value} = action.payload;
            return {...state, data: {...state.data, [scope]: value}}
    }
    return state;
};

export default userHistoryReducer;