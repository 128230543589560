import { map, tileLayer, DomUtil } from 'leaflet';

import { publish } from 'app/libs/core/PubSub';
import * as way from 'app/libs/core/PubSubWays';
import store from 'app/store';
import { Point } from 'app/libs/map/drive/Point';
import * as mapStatus from '../../components/HOGs/rxBehaviorSubject';

import {
  getDriveStartPointCM,
  getDriveEndPointCM,
  getDriveViaPointCM,
  getDriveDragPointCM,
  getDriveShapPointCM,
  getDriveHomeWorkPointCM,
  getDriveStaticPointCM,
  partStartPointCM,
  partEndPointCM,
  driveCurrentLocationContextMenu,
  drivePoiContexMenu,
  setStartPoint,
  setViaPoint,
  setEndPoint,
  tourStartHere,
  setHomePosition,
  setWorkPosition,
} from '../../components/Desctop/Map/map_contextmenu_utils';
import {
  setMapBoundsStrAndZoom,
  setMapNextClick,
  setSelectPointId,
  setMapCoord,
} from '../../actions/FullTour/full_tour_view.action';

export const initMap = (params) => {
  const {
    loc: { lat, lng },
    zoom,
  } = params;
  const mapContainer = params.mapContainer ? params.mapContainer : 'map';
  return map(mapContainer, {
    zoomControl: false,
    center: [lat, lng],
    minZoom: 2,
    zoom,
    layers: [
      tileLayer('https://tiles.tourstart.org/{z}/{x}/{y}.png', {
        attribution: 'Tourstart',
      }),
    ],
  });
};

export const purge = (d) => {
  let a = d.attributes;
  let i;
  let l;
  let n;
  if (a) {
    for (i = a.length - 1; i >= 0; i -= 1) {
      n = a[i].name;
      if (typeof d[n] === 'function') {
        d[n] = null;
      }
    }
  }
  a = d.childNodes;
  if (a) {
    l = a.length;
    for (i = 0; i < l; i += 1) {
      purge(d.childNodes[i]);
    }
  }
};

const getContexMenuStaticItems = (type, rightMenusText = {}, point = null) => {
  let menuItems = {};
  switch (type) {
    default:
      menuItems = getDriveStaticPointCM(rightMenusText, point);
  }

  return menuItems;
};

const getContexMenuItems = (
  type,
  rightMenusText = {},
  point = null,
  newPointId = null
) => {
  let menuItems = {};
  switch (type) {
    case Point.Type.START:
      menuItems = getDriveStartPointCM(rightMenusText, point);
      break;
    case Point.Type.END:
      menuItems = getDriveEndPointCM(rightMenusText, point);
      break;
    case Point.Type.DRAG:
      menuItems = getDriveDragPointCM(rightMenusText, point);
      break;
    case Point.Type.CURRENT_POSITION:
      menuItems = driveCurrentLocationContextMenu(rightMenusText, point);
      break;
    case Point.Type.POI:
      menuItems = drivePoiContexMenu(rightMenusText, point);
      break;
    case Point.Type.SHAPING:
      menuItems = getDriveShapPointCM(rightMenusText, point, newPointId);
      break;
    default:
      menuItems = getDriveViaPointCM(rightMenusText, point);
  }

  return menuItems;
};

const getMapBounds = (e) => e.target.getBounds();
const getMapZoom = (e) => e.target.getZoom();

const getBoundLoc = ({ lat, lng }) => encodeURIComponent(`${lng}|${lat}`);

let changeMapBoundsStrAndZoomDelay = null;
const boundsChanged = (e) => {
  const bounds = getMapBounds(e);
  const url = [
    `northWest=${getBoundLoc(bounds.getNorthWest())}`,
    `southEast=${getBoundLoc(bounds.getSouthEast())}`,
  ];
  const position = {
    boundsUrl: url.join('&'),
    zoom: getMapZoom(e),
  };
  if (changeMapBoundsStrAndZoomDelay)
    clearTimeout(changeMapBoundsStrAndZoomDelay);
  changeMapBoundsStrAndZoomDelay = setTimeout(() => {
    store.dispatch(setMapBoundsStrAndZoom(position));
  }, 400);
};
const updatePolylines = (e) => {
  if (getMapZoom(e) > 10) {
    publish(way.DRIVE_MAP_POLYLINES_SET_LITE, {});
  } else {
    publish(way.DRIVE_MAP_POLYLINES_SET_FULL, {});
  }
};
const handleZoom = (e) => {
  boundsChanged(e);
  updatePolylines(e);
};
const handleMapClick = (event) => {
  store.dispatch(setMapNextClick());
  if (event.originalEvent.target.id) {
    const { lat, lng } = event.latlng;
    store.dispatch(setMapCoord({ lat, lng }));
  }
};

let downOnMap = [];
export const dinamycMapListeners = {
  mousedown: (event) => {
    downOnMap = event.latlng;
  },
  click: (event) => {
    if (event.originalEvent.target.id) {
      const { lat, lng } = event.latlng;
      if (downOnMap.lat === lat)
        publish(way.DRIVE_MAP_CLICK, { lat, lng, draggable: true, map: true });
      store.dispatch(setMapNextClick());
    }
  },
  zoom: handleZoom,
  dragend: boundsChanged,
};

export const commonMapListeners = {
  click: handleMapClick,
  zoom: handleZoom,
  dragend: boundsChanged,
};

export const addStaticMarkerListeners = (
  en,
  rightMenusText = {},
  ownDispatch
) => {
  en.off();

  let markerDown = [];
  en.on('mousedown', (e) => {
    markerDown = e.latlng;
  });
  en.on('click', (e) => {
    if (markerDown.lat === e.latlng.lat) {
      console.log('click on ', en.options.customId);
      publish(way.DRIVE_MAP_STATIC_POINT_SELECTED, {
        customId: en.options.customId,
      });
    }
  });
  setTimeout(() => {
    en.on('mouseover', (e) => {
      const { customId } = e.target.options;
      ownDispatch(setSelectPointId(customId));
    });

    en.on('mouseout', () => {
      ownDispatch(setSelectPointId(0));
    });
  }, 1000);
  en.bindContextMenu(
    getContexMenuStaticItems(en.options.type, rightMenusText, en)
  );
  return en;
};

const setStartPointType = (customId) =>
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId,
    type: Point.Type.START,
    isLastBehind: true,
  });
const setEndPointType = (customId) =>
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId,
    type: Point.Type.END,
    isLastBehind: true,
  });
const setViaPointType = (customId) =>
  publish(way.DRIVE_MAP_POINT_SET_TYPE, {
    customId,
    type: Point.Type.VIA,
    isLastBehind: true,
  });
const moveMapToThisPoint = (latLng, zoom) =>
  publish(way.DRIVE_BUILDER_MOVE_MAP, { latLng, zoom });
const hideWrongRoutePopup = (customId) =>
  publish(way.DRIVE_MAPBUILDER_HIDE_WRONG_ROUTE_POPUP, {
    customId,
    keepCurrentCoord: 1,
  });
const correctPointPosition = (id) =>
  publish(way.DRIVE_BUILDER_MOVE_POINT_CORRECT_PLACE, { id });
const goToPreviousTourState = () =>
  publish(way.MAP_DRIVE_GO_TO_PREVIOUS_TOUR_STATE, {});
const cleanAutocorrectedPopUp = (id) =>
  publish(way.MAP_DRIVE_CLEAR_AUTOCORRECTION_PUPUPS, { id });
const isCharacterEvent = (className, type) =>
  className.toString() === '[object SVGAnimatedString]' &&
  (type === Point.Type.VIA || type === Point.Type.DRAG);
const isCloseEvent = (className, type) =>
  (className === 'wrong-close-btn' || className === 'next-worn-close-btn') &&
  (type === Point.Type.VIA || type === Point.Type.DRAG);
const isCorrectEvent = (className, type) =>
  typeof className === 'string' &&
  className.indexOf('next-wrong-marker-icon-text') > -1 &&
  (type === Point.Type.VIA || type === Point.Type.DRAG);

export const addEditableMarkerListeners = (en, rightMenusText, ownDispatch) => {
  en.off();

  let markerDown = {};
  en.on('mousedown', (e) => {
    markerDown = e.latlng;
  });
  en.on('click', (e) => {
    if (markerDown.lat === e.latlng.lat) {
      const { customId, type, icon = {} } = e.target.options;
      const { className } = e.originalEvent.target;
      const {
        options: { isAutocorrection },
      } = icon;
      if (isAutocorrection && isCharacterEvent(className, type)) {
        goToPreviousTourState();
      } else if (isAutocorrection && isCorrectEvent(className, type)) {
        goToPreviousTourState();
      } else if (isAutocorrection && isCloseEvent(className, type)) {
        cleanAutocorrectedPopUp(customId);
      } else if (
        e.target.options.type === Point.Type.VIA &&
        e.originalEvent.target.className === 'next-via-marker-icon'
      ) {
        setEndPointType(e.target.options.customId);
      } else if (isCloseEvent(className, type)) {
        hideWrongRoutePopup(customId);
      } else if (isCharacterEvent(className, type)) {
        moveMapToThisPoint(e.target.getLatLng(), 15);
      } else if (isCorrectEvent(className, type)) {
        correctPointPosition(customId);
      } else if (
        e.target.options.type === Point.Type.VIA &&
        e.originalEvent.target.className === 'next-via-marker-icon-start'
      ) {
        setStartPointType(e.target.options.customId);
      } else if (
        e.target.options.type === Point.Type.END &&
        e.originalEvent.target.className === 'next-end-marker-icon'
      ) {
        setViaPointType(e.target.options.customId);
      } else if (
        e.target.options.type === Point.Type.START &&
        e.originalEvent.target.className === 'next-start-marker-icon'
      ) {
        setViaPointType(e.target.options.customId);
      } else {
        publish(way.DRIVE_MAP_STATIC_POINT_SELECTED, {
          customId: en.options.customId,
        });
      }
    }
  });

  en.on('drag', (e) => {
    mapStatus.changeTo(true);
    const point = {
      id: e.target.options.customId,
      latLng: e.target.getLatLng(),
    };
    publish(way.DRIVE_MAP_DRAGED_POINT_MOVED, point);
  });
  en.on('dragend', (e) => {
    mapStatus.changeTo(false);
    const { lat, lng } = e.target.getLatLng();
    const point = { id: e.target.options.customId, lat, lng, draggable: true };
    publish(way.DRIVE_MAP_POINT_DRAGEND, point);
  });

  setTimeout(() => {
    en.on('mouseover', (e) => {
      const { customId } = e.target.options;
      ownDispatch(setSelectPointId(customId));
    });

    en.on('mouseout', () => {
      ownDispatch(setSelectPointId(0));
    });
  }, 1000);

  en.bindContextMenu(getContexMenuItems(en.options.type, rightMenusText, en));

  return en;
};

export const addShapingMarkerListeners = (en, rightMenusText, pointId) => {
  en.bindContextMenu(
    getContexMenuItems(en.options.title, rightMenusText, en, pointId)
  );
  return en;
};

export const updateContextMenuItems = (marker) => {
  // marker.unbindContextMenu();
  // marker.bindContextMenu( getContexMenuItems( type, rightMenusText ) );
  return marker;
};

const getContexMenuParts = (type, rightMenusText, point) => {
  let menuItems = {};
  switch (type) {
    case Point.Type.START:
      menuItems = partStartPointCM(rightMenusText, point);
      break;
    case Point.Type.END:
      menuItems = partEndPointCM(rightMenusText, point);
      break;
    default:
      menuItems = partEndPointCM(rightMenusText, point);
  }

  return menuItems;
};

export const addStaticPartMarkerListeners = (en) => {
  en.off();

  let markerDown = [];
  en.on('mousedown', (e) => {
    markerDown = e.latlng;
  });
  en.on('click', (e) => {
    if (markerDown.lat === e.latlng.lat) {
      console.log('click on ', en.options.customId);
      publish(way.DRIVE_MAP_STATIC_POINT_SELECTED, {
        customId: en.options.customId,
      });
    }
  });
  en.on('mouseover', () => {
    const { customId, partId } = en.options;
    publish(way.DRIVE_MAP_STATIC_POINT_MOUSEENTER, { customId, partId });
  });
  en.on('mouseout', () =>
    publish(way.DRIVE_MAP_STATIC_POINT_MOUSEENTER, { customId: 0, partId: 0 })
  );

  return en;
};

export const addEditablePartMarkerListeners = (en, rightMenusText = {}) => {
  en.off();

  let markerDown = [];
  en.on('mousedown', (e) => {
    markerDown = e.latlng;
  });
  en.on('click', (e) => {
    if (markerDown.lat === e.latlng.lat)
      publish(way.DRIVE_MAP_STATIC_POINT_SELECTED, {
        customId: en.options.customId,
      });
  });
  en.on('mouseover', () => {
    const { customId, partId } = en.options;
    publish(way.DRIVE_MAP_STATIC_POINT_MOUSEENTER, { customId, partId });
  });
  en.on('mouseout', () =>
    publish(way.DRIVE_MAP_STATIC_POINT_MOUSEENTER, { customId: 0, partId: 0 })
  );
  // en.on("mouseover", (e)=>{
  //     publish(way.DRIVE_MAP_POINT_MOUSEOVER, {customId: en.options.customId});
  // });
  // en.on("mouseout", (e)=>{
  //     publish(way.DRIVE_MAP_POINT_MOUSEOUT, {customId: en.options.customId});
  // });
  // en.on("dragend", (e)=>{
  //     const {lat, lng} = e.target._latlng;
  //     const { customId: id, partId } = e.target.options;
  //     const point = {id, lat, lng, draggable: true, partId }
  //     // const point = {id: e.target.options.customId, lat, lng, draggable: true}
  //     publish(way.DRIVE_MAP_POINT_DRAGEND, point);
  // });

  en.bindContextMenu(getContexMenuParts(en.options.type, rightMenusText, en));

  return en;
};

const getContent = ({ scope }, titles) => {
  if (scope === 'drive') {
    return `
            <div class="leaflet-contextmenu" style="display: block; width:160px; height: 98px; box-shadow: none; background: none;">
                <a rel="start" class="leaflet-contextmenu-item" href="javascript:void(0);" style="">
                    <span rel="start" class="leaflet-contextmenu-icon map_drive_point_mark_start"></span>${titles.setStartPoint}
                </a>
                <a rel="via" class="leaflet-contextmenu-item" href="javascript:void(0);" style="">
                    <span rel="via" class="leaflet-contextmenu-icon map_drive_point_mark_via"></span>${titles.setPoi}
                </a>
                <a rel="end" class="leaflet-contextmenu-item" href="javascript:void(0);" style="">
                    <span rel="end" class="leaflet-contextmenu-icon map_drive_point_mark_end"></span>${titles.setEndPoint}
                </a>
                <a rel="remove" class="leaflet-contextmenu-item" href="javascript:void(0);" style="cursor: pointer;">
                    ${titles.removeMarker}
                </a>
            </div>
        `;
  }
  return `
            <div class="leaflet-contextmenu" style="display: block; width:160px; height: 98px; box-shadow: none; background: none;">
                <a rel="tour" class="leaflet-contextmenu-item" href="javascript:void(0);" style="cursor: pointer;" >
                    <span rel="tour" class="leaflet-contextmenu-icon map_main_cm_tour_point"></span>${titles.tourStartHere}
                </a>
                <a rel="tourend" class="leaflet-contextmenu-item" href="javascript:void(0);" style="cursor: pointer;" >
                    <span rel="tourend" class="leaflet-contextmenu-icon map_main_cm_tour_end_point"></span>${titles.tourEndHere}
                </a>
                <a rel="place" class="leaflet-contextmenu-item" href="javascript:void(0);" style="cursor: pointer;">
                    <span rel="place" class="leaflet-contextmenu-icon map_main_cm_my_place"></span>${titles.addMyPlace}
                </a>
                <a rel="remove" class="leaflet-contextmenu-item" href="javascript:void(0);" style="cursor: pointer;">
                    ${titles.removeMarker}
                </a>
            </div>
        `;
};

export const addSearchAddressListeners = (en, titles) => {
  en.off();

  const removePoint = () =>
    publish(way.DRIVE_MAP_POINT_REMOVE_ONLY_ON_MAP, {
      customId: en.options.customId,
    });

  const handleClick = (e) => {
    const { rel } = e.target.attributes;
    if (rel) {
      switch (rel.value) {
        case 'tour':
          tourStartHere({ ...en }, 'start');
          break;
        case 'tourend':
          tourStartHere({ ...en }, 'end');
          break;
        case 'place':
          console.log('Function for place will be abailable soon');
          break;
        case 'start':
          setStartPoint({ ...en });
          removePoint({ ...e });
          break;
        case 'via':
          setViaPoint({ ...en });
          removePoint({ ...e });
          break;
        case 'end':
          setEndPoint({ ...en });
          removePoint({ ...e });
          break;
        case 'remove':
          removePoint({ ...e });
          break;

        default:
          console.log(`There is not catecory like this - ${rel.value}`);
          break;
      }
    }
  };

  const popUp = DomUtil.create('div', 'searchPopUp');
  popUp.innerHTML = getContent(store.getState().mapScopeReducer, titles);
  popUp.addEventListener('click', handleClick);

  en.on('mouseover', () => {
    en.bindPopup(popUp, { offset: [0, -27] }).openPopup();
  });

  return en;
};

const getContexMenuHomeWorkStaticItems = (
  type,
  rightMenusText = {},
  point = null
) => {
  let menuItems = {};
  switch (type) {
    default:
      menuItems = getDriveHomeWorkPointCM(rightMenusText, point);
  }

  return menuItems;
};

export const addEditableHomeWorkMarkerListeners = (
  en,
  type,
  rightMenusText = {}
) => {
  en.on('dragend', (e) => {
    const point = {
      id: e.target.options.customId,
      latlng: e.target.getLatLng(),
    };
    if (type === 'home') {
      setHomePosition(point);
    } else {
      setWorkPosition(point);
    }
  });
  en.bindContextMenu(
    getContexMenuHomeWorkStaticItems(en.options.type, rightMenusText, en)
  );
  return en;
};

export const addStaticFilterMarkListener = (en) => {
  en.on('dragend', (e) => {
    const { lat, lng } = e.target.getLatLng();
    const { type } = en.options;
    store.dispatch(setMapCoord({ lat, lng, type }));
  });

  return en;
};
