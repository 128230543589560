import Cookies from 'js-cookie';
import * as types from '../actions/action_types';

const initialState = {
  users: [],
  userHistoryData: {},
  currentLocation: {},
  homeAddress: {},
  workAddress: {},
  lastOpenedObject: [],
  pictures: [],
  filterOpenState: true,
  showHome: true,
  showWork: true,

  data: {},
  discardData: {},

  notificationOptions: {
    tournews_subscribed: 1,
  },
  discardNotificationOptions: {},

  privacySettings: {
    display_email: 1,
    display_dob: 1,
    display_address: 1,
  },
  discardPrivacySettings: {},

  member: [],
  media: [],
  club: [],
  bikerbed: [],

  index: +Cookies.get('index') === 1 ? 1 : 0,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_USER_PICTURES_DATA:
      return { ...state, pictures: action.payload };

    case types.SET_USER_HISTORY_DATA:
      return { ...state, userHistoryData: action.payload };

    case types.SET_LAST_OPENED_OBJECT:
      if (state.lastOpenedObject.length < 3) {
        return {
          ...state,
          lastOpenedObject: [...state.lastOpenedObject, action.payload],
        };
      }
      state.lastOpenedObject.splice(0, 1);
      return {
        ...state,
        lastOpenedObject: [...state.lastOpenedObject, action.payload],
      };

    case types.GET_CURRENT_LOCATION_USER:
      return { ...state, currentLocation: action.payload };

    case types.SET_HOME_ADDRESS_USER:
      return { ...state, homeAddress: action.payload };

    case types.SET_WORK_ADDRESS_USER:
      return { ...state, workAddress: action.payload };

    case types.USER_FILTER_OPEN_STATE:
      return { ...state, filterOpenState: action.payload };

    case types.USER_CHANGE_SHOW_HOME:
      return { ...state, showHome: action.payload };

    case types.USER_CHANGE_SHOW_WORK:
      return { ...state, showWork: action.payload };

    case types.USER_SET_MAIN_DATA:
      return {
        ...state,
        data: action.payload,
        discardData: action.payload,
        member: action.payload.member,
        media: action.payload.media,
        club: action.payload.club,
        bikerbed: action.payload.bikerbed,
      };

    case types.USER_CHANGE_MAIN_DATA:
      return { ...state, data: action.payload };

    case types.USER_SET_MEDIA_DATA: {
      const { scope, data } = action.payload;
      return { ...state, [scope]: data };
    }
    case types.USER_SET_PRIVACY_SETTINGS:
      if (action.payload.first) {
        return {
          ...state,
          privacySettings: action.payload.data,
          discardPrivacySettings: action.payload.data,
        };
      }
      return { ...state, privacySettings: action.payload.data };

    case types.USER_SET_NOTIFICATION_OPTIONS:
      if (action.payload.first) {
        return {
          ...state,
          notificationOptions: action.payload.data,
          discardNotificationOptions: action.payload.data,
        };
      }
      return { ...state, notificationOptions: action.payload.data };

    case types.USER_CHANGE_INDEX_VALUE:
      return { ...state, index: action.payload };

    default:
      return state;
  }
};

export default userReducer;
