import * as types from '../actions/action_types';
import tourPrevAPI from '../API/tour.prev.api';

let sendData = [];

const isDefaultTitle = (title, keyWord) => {
  const arrTitle = title.split(' ');
  return (
    arrTitle &&
    arrTitle.length === 2 &&
    arrTitle[0] === keyWord &&
    arrTitle[1] > 0
  );
};

const updateParts = (parts, keyWord) => {
  return parts.map((el, i) => {
    el.part_id = i + 1;
    if (isDefaultTitle(el.title, keyWord)) {
      el.title = `${keyWord} ${i + 1}`;
    }
    if (el.id) {
      sendData.push({
        id: el.id,
        title: el.title,
        part_id: el.part_id,
      });
    }
    return el;
  });
};

export const addViaTourDay =
  ({ getState }) =>
  (next) =>
  (action) => {
    const { type, payload = {} } = action;
    if (
      type === types.TOUR_FULL_DATA_ADD_VIA_DAY ||
      type === types.TOUR_FULL_CHANGE_PART_ID
    ) {
      let { parts } = payload;
      const { key, part, part_id } = payload;
      const { tourFullData = {} } = getState();
      const dataParts = tourFullData.data.parts;
      sendData = [];

      if (type === types.TOUR_FULL_DATA_ADD_VIA_DAY && dataParts.length > 1) {
        const keyWord = part.title.split(' ')[0];
        dataParts.splice(key, 0, part);
        parts = updateParts(dataParts, keyWord);
      }

      if (type === types.TOUR_FULL_DATA_ADD_VIA_DAY && dataParts.length === 1) {
        dataParts.push(part);
        parts = [...dataParts];
      }

      if (type === types.TOUR_FULL_CHANGE_PART_ID && dataParts.length > 1) {
        const keyWord = parts[part_id - 1].title.split(' ')[0];
        parts = updateParts(parts, keyWord);
      }

      tourPrevAPI.saveTourAfterTabDnd({ parts: [...sendData] });

      action.payload.parts = parts;
    }

    next(action);
  };
