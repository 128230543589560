import * as types from '../actions/action_types';

const initialState = {
    collapse: true,
}

const mainMenuData = ( state = initialState , action) => {
switch (action.type) {
    case types.MAIN_MENU_COLLAPSE:
        return{ ...state, collapse: action.payload}; 
    default:
    return state;
}
}
export default mainMenuData;