import * as types from '../actions/action_types';

const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: null,
};

const mapDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TOURS_PUBLIC_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_TOURS_PUBLIC_REJECTED:
      return { ...state, fetching: false, error: action.payload };
    case types.FETCH_TOURS_PUBLIC_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: action.payload };

    // FETCH_CLUB
    case types.FETCH_CLUBS_ALL_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_CLUBS_ALL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    case types.FETCH_CLUBS_ALL_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: action.payload };

    // FETCH_BIKERBEDS
    case types.FETCH_BIKERBEDS_ALL_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_BIKERBEDS_ALL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    case types.FETCH_BIKERBEDS_ALL_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: action.payload };

    // FETCH_PLACES
    case types.FETCH_PLACE_ALL_PENDING:
      return { ...state, fetching: true };
    case types.FETCH_PLACE_ALL_REJECTED:
      return {
        ...state,
        fetching: false,
        fetched: false,
        error: action.payload,
      };
    case types.FETCH_PLACE_ALL_FULFILLED:
      return { ...state, fetching: false, fetched: true, data: action.payload };

    //
    case types.UPDATE_MAP_ITEM: {
      const item = action.payload;
      const index = state.data.indexOf(item);
      if (index !== -1) {
        return { ...state, data: state.data.splice(index, 1, item) };
      }
      return state;
    }

    case types.DELETE_MAP_ITEM_BY_ID: {
      const data = [...state.data];
      const item = data.find((el) => +el.id === +action.payload);
      const index_item = state.data.indexOf(item);
      if (index_item !== -1) {
        data.splice(index_item, 1);
        return { ...state, data };
      }
      return state;
    }

    case types.DELETE_MAP_ITEM: {
      const data = [...state.data];
      const index_item = state.data.indexOf(action.payload);
      if (index_item !== -1) {
        data.splice(index_item, 1);
        return { ...state, data };
      }
      return state;
    }
    // INIT DATA
    case types.MAP_DATA_INITIAL_STATE:
      return initialState;

    default:
      return state;
  }
};

export default mapDataReducer;
