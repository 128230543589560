const engine = require('store/src/store-engine');
const sessionStorage = require('store/storages/sessionStorage');
const memoryStorage = require('store/storages/memoryStorage');

const storages = [sessionStorage, memoryStorage];

const store = engine.createStore(storages);

const set = (user) => {
  store.set('user', user);
};

const get = () => store.get('user');

const remove = () => {
  store.remove('user');
};

const userEnterStore = {
  set,
  get,
  remove,
};
export default userEnterStore;
