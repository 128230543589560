export const pageScope = Object.freeze({
  default: 'tour',
  drive: 'drive',
  tour: 'tour',
  tourfull: 'tourfull',
  track: 'track',
  club: 'club',
  club_view: 'club_view',
  bikerbed: 'bikerbed',
  bikerbed_view: 'bikerbed_view',
  profile: 'profile',
  index: 'home',
  place: 'place',
  member: 'member',
  media: 'media',
});

/** const links to server */
export const URL = Object.freeze({
  CONTENT: Object.freeze({
    BEST: ['bestContent', 'getBestTourList'],
  }),
  TOUR: Object.freeze({
    PUBLIC: ['getListPublicTours', 'getListPublicTours'],
    NAF: ['getListNafTours', 'getListNafTours'],
    MARGUERITRUTEN: [
      'getListMargueritrutenTours',
      'getListMargueritrutenTours',
    ],
    // MY      : ['myTours', 'tour/mine_all?limit=10000&mine_all=1&page=0'],
    MY: ['myTours', 'getMineAll'],
    FRIENDS: ['myFriendTours', 'tour?limit=10000&friends_all=1&page=0'],
  }),
  CLUB: Object.freeze({
    ALL: ['club', 'club'],
    MY: ['myClubs', 'myClubList'],
    NAF: ['nafClubs', 'nafClubs'],
  }),
  BIKERBED: Object.freeze({
    ALL: ['bikerbed', 'bikerbed'],
    MY: ['myBikerbed', 'myBikerBedsList'],
  }),
  // BIKERBED: Object.freeze({
  //     ALL  : ['bikerbed', 'bikerbed'],
  //     MY   : ['myTracks', 'track.getListTrack'],
  // }),
});
/** -- */
