import { pageScope } from 'app/constants/mapConstants';
import * as types from '../actions/action_types';

const initialState = {
  fetching: false,
  fetched: false,
  parts: [
    {
      points: [],
      poly: '',
    },
  ],
  id: 0,
  error: null,
  placeData: null,
};

const fetchPreviewPoly = (state = initialState, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return action.payload.isListSwitched ? state : initialState;
    case types.FETCH_PREVIEW_POLYLINE_CLEANE:
      return initialState;
    case types.FETCH_PREVIEW_POLYLINE_PENDING:
      return { ...initialState, fetching: true };
    case types.FETCH_PREVIEW_POLYLINE_REJECTED:
      return { ...state, fetching: false, error: action.payload };
    case types.FETCH_PREVIEW_POLYLINE_FULFILLED: {
      const { scope } = action.payload;
      if (scope && scope === pageScope.place) {
        return {
          ...state,
          fetching: false,
          fetched: true,
          parts: [],
          id: action.payload ? action.payload.id : 0,
          placeData: action.payload,
        };
      }
      return {
        ...state,
        fetching: false,
        fetched: true,
        parts: action.payload ? action.payload.parts : [],
        id: action.payload ? action.payload.id : 0,
        placeData: null,
      };
    }
    default:
      return state;
  }
};

export default fetchPreviewPoly;
